<template>
  <DataTable
    v-if=" $checkPermissions('summer_center.add_generalizations')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_generalizations', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_generalizations', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      $checkPermissions('summer_center.add_generalizations', ['sys_admin', 'gen_admin'])
    "
  >
    <template v-slot:item.show_to="{ item }">
      {{ getShowToName(item.raw.show_to) }}
    </template>
    <template v-slot:item.is_active="{item}">
      <v-icon v-if="item.raw.is_active" color="green-darken-2">mdi-check-circle</v-icon>
      <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextarea
                v-model="circular.title"
                :label="`${$t('circular.title')} *`"
                clearable
                color="indigo"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                @input="v$.circular.title.$touch"
                :error-messages="
                  v$.circular.title.$errors.map((e) => e.$message)
                "
                prepend-inner-icon="mdi-bullhorn-outline"
              ></VTextarea>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VAutocomplete
                v-model="circular.show_to"
                :multiple="is_update?false:true"
                :label="`${$t('circular.show-to')} *`"
                prepend-inner-icon="mdi-eye"
                :items="role_list"
                item-title="name"
                item-value="id"
                @input="v$.circular.show_to.$touch"
                :error-messages="
                  v$.circular.show_to.$errors.map((e) => e.$message)
                "
              >
              </VAutocomplete>
            </VCol>

            <VCol cols="4" md="3" sm="4">
              <VCheckbox
                v-model="circular.is_active"
                :label="$t('globals.active')"
                density="compact"
              >
              </VCheckbox>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="circular.note"
                clearable
                color="indigo"
                :label="$t('summer.initiative.note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                @input="v$.circular.note.$touch"
                :error-messages="
                  v$.circular.note.$errors.map((e) => e.$message)
                "
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { required, helpers, maxLength, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import DataTable from "@/components/Globals/DataTable.vue";


export default {
  components: {
    DatetimePicker,
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading: false,
      role_list: [],
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      circular: {
        title: null,
        is_active: false,
        show_to: null,
        date: null,
        note: null,
      },
      v$: useValidate(),
      is_update: false,
      headers: [
        { title: this.$t("circular.title"), key: "title" },
        { title: this.$t("circular.date"), key: "date" },
        { title: this.$t("globals.active"), key: "is_active" },
        { title: this.$t("circular.show-to"), key: "show_to" },
        { title: this.$t("globals.note"), key: "note", sortable: false,show:false, },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        }
      ],
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.axios
        .get(`${this.base_url}api/summer/choices/show-to`)
        .then((response) => {
          this.role_list = response.data;
        });
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    getShowToName(id) {
      return this.role_list.find((item) => item.id == id)?.name;
    },
    resetForm() {
      this.$refs.form.reset();
      this.circular.is_active = false;
      this.v$.$reset();
      this.is_update = false;
    },
    editItem(circular) {
      this.circular = Object.assign({}, circular);
      this.is_update = true;
      this.addDialog = true;
    },
    async saveItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.circular.data_entry = this.user;
        this.saveDataLoading = true;
        let result = await this.axios
          .post(this.base_url + "api/summer/generalization/", this.circular)
          .then((response) => {
            this.saveDataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.getItems();
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.circular.updated_by = this.user;
        this.saveDataLoading = true;
        let result = await this.axios
          .put(
            `${this.base_url}api/summer/generalization/${this.circular.id}/`,this.circular )
          .then((response) => {
            this.resetForm();
            this.saveDataLoading = false;
            this.getItems();
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((error) => {
            this.saveDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(
            `${this.base_url}/api/summer/generalization/${delete_id}/`)
          .then(() =>status=true)
          .catch((error) => status = error);
        return status;
      }
    },
    async getItems(page = 1,per_page=10,ordering='id',search=null) {
      await this.axios(`${this.base_url}/api/summer/generalization/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
  },
  validations() {
    return {
      circular: {
        title: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(200)
          ),
          minLength: helpers.withMessage(
            `${this.$t("errors.min-entry")} 10`,
            minLength(10)
          ),
        },
        show_to: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
    };
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
