<template>
  <VCard
    v-if="
      $checkPermissions('opensummercenter.add_schoolsummeropen', [
        'gen_admin',
        'sys_admin',
        'gov_admin',
        'dir_admin',
      ]) ||
      $checkPermissions('opensummercenter.change_schoolsummeropen', [
        'gen_admin',
        'sys_admin',
        'gov_admin',
        'dir_admin',
      ])
    "
    @keydown.ctrl.alt.left="
      $i18n.locale == 'ar' ? navigateByTabInput() : navigateBackByTabInput()
    "
    @keydown.ctrl.alt.right="
      $i18n.locale == 'ar' ? navigateBackByTabInput() : navigateByTabInput()
    "
  >
    <VForm ref="form">
      <v-locale-provider :locale="$i18n.locale">
        <v-card class="pa-1" elevation="0">
          <VTabs
            v-model="tab"
            center-active
            show-arrows
            color="indigo"
            density="compact"
            slider-color="indigo"
            fixed-tabs
          >
            <VTab v-for="(item, index) in tabs" :key="index" :value="index">
              {{ item.title }}
            </VTab>
          </VTabs>
        </v-card>
        <v-divider class="mx-3"></v-divider>
      </v-locale-provider>
      <v-window v-model="tab">

        <VForm ref="form1" v-model="validForms.form1">
        <v-window-item :value="0">
          <!-- <v-form ref="tab_zero"> -->

          <v-card-text class="mb-2">
            <VRow>
              <VCol cols="12" md="6" class="mt-3">
                <v-card elevation="1">
                  <v-card-title>
                    {{ $t("school.main_data") }}
                  </v-card-title>
                  <VRow class="ma-1">
                    <VCol cols="12">
                      <VTextField
                        v-model="summerSchool.name_ar"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="`${$t('summer.open-school-name-ar')} *`"
                        :placeholder="$t('summer.open-school-name-ar-placeholder')"
                        density="compact"
                        counter="50"
                        autofocus
                        :rules="school.school_name_rule"
                      >
                      </VTextField>
                    </VCol>
                    <VCol cols="6" md="6">
                      <AutoSelect
                        :items="shift_times_list"
                        v-model="summerSchool.shift_time"
                        item-title="name"
                        item-value="id"
                        :label="`${$t('summer.shift-time')} *`"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-calendar-clock-outline"
                        :rules="school.shift_time_rule"
                      ></AutoSelect>
                    </VCol>
                    <VCol cols="6" md="6">
                      <AutoSelect
                        v-model="summerSchool.students_type"
                        :label="$t('school.students_type')"
                        prepend-inner-icon="mdi-gender-male-female"
                        :items="student_type"
                        item-title="name"
                        item-value="id"
                        @update:modelValue="
                          summerSchool.students_type != 3
                            ? resetLevelsObjects()
                            : null
                        "
                      >
                      </AutoSelect>
                    </VCol>
                    <VCol cols="6" md="6">
                      <VTextField
                        v-model="summerSchool.class_count"
                        prepend-inner-icon="mdi-numeric"
                        :label="`${$t('summer.class-count')} *`"
                        :placeholder="$t('summer.add-class-count')"
                        density="compact"
                        type="number"
                        hide-details="auto"
                        :rules="school.class_count_rule"
                      >
                      </VTextField>
                    </VCol>
                    <VCol cols="6" md="6">
                      <VTextField
                        v-model="summerSchool.shortage_of_worker"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('summer.shortage-of-worker')"
                        :placeholder="$t('summer.shortage-of-worker')"
                        density="compact"
                        type="number"
                        hide-details="auto"
                        :rules="school.shortage_of_worker_rule"
                      >
                      </VTextField>
                    </VCol>
                     <fieldset
                      class="
                        d-lg-flex
                        flex-sm-fill
                        w-100
                        mx-3
                        mb-2
                        py-1
                        border
                        rounded
                        mt-4
                      "
                    >
                      <legend class="mx-2 px-2">اعتماد المدرسة</legend>
                      <v-checkbox
                        v-model="summerSchool.active"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.is-active')"
                      >
                      </v-checkbox>
                    </fieldset>
                    <fieldset
                      class="
                        d-lg-flex
                        flex-sm-fill
                        w-100
                        mx-3
                        mb-2
                        py-1
                        border
                        rounded
                        mt-4
                      "
                    >
                      <legend class="mx-2 px-2">الامكانيات المتوفرة</legend>
                      <v-checkbox
                        v-model="summerSchool.bedroom_is_available"
                        class="ma-0 pa-0"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.bedroom-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.yard_is_available"
                        class="ma-0 pa-0"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.yard-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.lecture_room_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.lecture-room-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.accoustics_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.accoustics-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.electricity_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.electricity-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.activity_tools_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.activity-tools-is-available')"
                      ></v-checkbox>


                      <v-checkbox
                        v-model="summerSchool.clinic_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.clinic-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.store_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.store-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.kitchen_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.kitchen-is-available')"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="summerSchool.tools_is_available"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.tools-is-available')"
                      ></v-checkbox>
                  </fieldset>
                    <v-col cols="12" class="mt-2" v-if="isReview">
                      <v-autocomplete
                        :items="errorFieldsOfSchoolSummer"
                        v-model="summerSchool.errors_of_school_data"
                        item-title="name"
                        item-value="id"
                        multiple
                        :label="$t('globals.review-data')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-text-box-search"
                      ></v-autocomplete>
                    </v-col>
                    <VCol cols="12">
                      <VTextarea
                        v-model="summerSchool.note"
                        clearable
                        :label="$t('globals.note')"
                        color="indigo"
                        density="compact"
                        hide-details="auto"
                        rows="1"
                        prepend-inner-icon="mdi-note-outline"
                        :rules="school.note_rule"
                      ></VTextarea>
                    </VCol>
                  </VRow>
                </v-card>
              </VCol>
              <VCol cols="12" md="6" class="mt-3">
                <v-card elevation="1">
                  <v-card-title>
                    {{ $t("school.location_data") }}
                  </v-card-title>
                  <VRow class="ma-1">
                    <VCol cols="6" v-if="role == 0 || role == 1">
                      <AutoSelect
                        :items="filterGovernorates(1)"
                        v-model="summerSchool.governorate_id"
                        item-title="name_ar"
                        item-value="id"
                        :label="`${$t('globals.governorate')} *`"
                        persistent-hint
                        density="compact"
                        :disabled="role > 1"
                        prepend-inner-icon="mdi-city"
                        @update:modelValue="summerSchool.fk_directorate = null"
                        :rules="school.governorate_rule"
                      ></AutoSelect>
                    </VCol>
                    <VCol
                      :cols="role == 2 ? 12 : 6"
                      v-if="role >= 0 && role <= 2"
                    >
                      <AutoSelect
                        :items="filterDirectorates(summerSchool.governorate_id)"
                        :no-data-text="$t('globals.governorate-first')"
                        v-model="summerSchool.fk_directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="`${$t('globals.directorate')} *`"
                        persistent-hint
                        density="compact"
                        :disabled="role > 2"
                        prepend-inner-icon="mdi-map-outline"
                        :rules="school.directorate_rule"
                      ></AutoSelect>
                    </VCol>

                    <!-- village -->
                    <VCol cols="6">

                    <VAutocomplete
                      :items="filterVillages(summerSchool.fk_directorate)"
                      prepend-inner-icon="mdi-city"
                      v-model="summerSchool.fk_village"
                      item-title="name_ar"
                      :no-data-text="$t('globals.directorate-first')"
                      item-value="id"
                      persistent-hint
                      :label="`${$t('globals.sub-district')}`"

                      density="compact"

                      @update:modelValue="
                            (summerSchool.place_name = null)
                        "
                    ></VAutocomplete>


                      <!-- <v-text-field
                        counter="50"
                        type="input"
                        v-model="summerSchool.address2"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-map-marker"
                        :label="`${$t('globals.sub-district')} *`"
                        :rules="school.address"
                      /> -->

                    </VCol>


                    <VCol cols="6">
                      <v-text-field
                        counter="50"
                        type="input"
                        v-model="summerSchool.address"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-map-marker"
                        :label="`${$t('globals.village')} *`"
                        :rules="school.address"
                      />
                    </VCol>


                    <!-- place type -->
                    <VCol cols="6">
                      <AutoSelect
                        :items="placeTypesList"
                        v-model="summerSchool.place_type"
                        item-title="name"
                        item-value="id"
                        :label="`${$t('summer.place-type')} *`"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-format-list-bulleted-type"
                        :rules="school.place_type_rule"
                      ></AutoSelect>
                    </VCol>

                    <VCol cols="6"  v-if="summerSchool.place_type == 2">
                        <VAutocomplete
                        v-if="filterSchools(summerSchool.fk_village)?.length>0"
                        :items="filterSchools(summerSchool.fk_village)"
                        clearable
                        prepend-inner-icon="mdi-abjad-arabic"
                        v-model="summerSchool.place_name"
                        item-title="name_ar"
                        item-value="name_ar"
                        :no-data-text="$t('globals.village-first')"
                        :label="`${$t('summer.place-name')} *`"
                        persistent-hint
                        density="compact"
                      ></VAutocomplete>

                      <VTextField
                        v-else
                        v-model="summerSchool.place_name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="`${$t('summer.place-name')} *`"
                        :placeholder="$t('summer.place-name-placeholder')"
                        density="compact"
                        :rules="school.place_name_rule"
                      >
                      </VTextField>
                    </VCol>


                    <!-- schools -->
                    <VCol cols="6"  v-if="summerSchool.place_type != 2">
                      <VTextField
                        v-model="summerSchool.place_name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="`${$t('summer.place-name')} *`"
                        :placeholder="$t('summer.place-name-placeholder')"
                        density="compact"
                        :rules="school.place_name_rule"
                      >
                      </VTextField>
                    </VCol>



                    <VCol cols="12" class="px-3">
                      <VRow
                        v-for="(contact, index) in summerSchool.contacts"
                        :key="index"
                        :class="index > 0 ? 'mt-4' : 'mt-1'"
                      >
                        <VCol cols="6">
                          <AutoSelect
                            :items="contact_type_list"
                            v-model="contact.contact_type"
                            item-title="name"
                            item-value="id"
                            :label="$t('school.contact_type')"
                            persistent-hint
                            hide-details="auto"
                            density="compact"
                            @update:modelValue="contact.contact = null"
                            :prepend-inner-icon="
                              contact.contact_type == 1
                                ? 'mdi mdi-phone'
                                : contact.contact_type == 2
                                ? 'mdi mdi-email'
                                : contact.contact_type == 3
                                ? 'mdi mdi-cellphone'
                                : contact.contact_type == 4
                                ? 'mdi mdi-facebook'
                                : contact.contact_type == 5
                                ? 'mdi mdi-whatsapp'
                                : contact.contact_type == 6
                                ? 'mdi mdi-telegram'
                                : ''
                            "
                          ></AutoSelect>
                        </VCol>
                        <VCol cols="6">
                          <VTextField
                            v-model="contact.contact"
                            :label="$t('school.contact')"
                            density="compact"
                            hide-details="auto"
                            :rules="[
                              (value) =>
                                checkContactType(value, contact.contact_type),
                            ]"
                            :prepend-inner-icon="
                              contact.contact_type == 1
                                ? 'mdi mdi-phone'
                                : contact.contact_type == 2
                                ? 'mdi mdi-email'
                                : contact.contact_type == 3
                                ? 'mdi mdi-cellphone'
                                : contact.contact_type == 4
                                ? 'mdi mdi-facebook'
                                : contact.contact_type == 5
                                ? 'mdi mdi-whatsapp'
                                : contact.contact_type == 6
                                ? 'mdi mdi-telegram'
                                : ''
                            "
                          >
                          </VTextField>
                        </VCol>
                      </VRow>
                      <VRow elevation="4" class="mx-1 mb-2 mt-5">
                        <VBtn
                          variant="tonal"
                          icon
                          density="compact"
                          @click.prevent="addContact()"
                          @keydown.tab.exact="navigateByTabInput()"
                        >
                          <VIcon
                            icon="mdi-plus-thick"
                            color="green-darken-2"
                          ></VIcon>
                        </VBtn>
                        <VBtn
                          v-if="
                            summerSchool.contacts &&
                            summerSchool.contacts.length > 1
                          "
                          variant="tonal"
                          icon
                          density="compact"
                          class="mx-2"
                          @click.prevent="removeContact()"
                        >
                          <VIcon
                            icon="mdi-minus-thick"
                            color="red-darken-2"
                          ></VIcon>
                        </VBtn>
                      </VRow>
                    </VCol>

                    
                  </VRow>
                </v-card>
              </VCol>
            </VRow>
          </v-card-text>
          <!-- </v-form> -->
        </v-window-item>
        </VForm>

        <VForm ref="form2" v-model="validForms.form2">
        <v-window-item :value="1">
          <v-card-text>
            <v-card class="mt-1" elevation="0">
              <table class="table-open-summer" border="1">
                <thead>
                  <tr>
                    <th rowspan="4">{{ $t("globals.#") }}</th>
                    <th rowspan="4">#</th>
                    <th rowspan="4">{{ $t("summer.level.level") }}</th>
                    <th colspan="6">{{ $t("summer.student_count") }}</th>
                    <th colspan="4">{{ $t("summer.curricula") }}</th>
                    <th rowspan="4">{{ $t("summer.lectures-count") }}</th>
                  </tr>
                  <tr>
                    <th colspan="4">
                      <small> {{ $t("globals.registered") }} </small>
                    </th>

                    <th colspan="2" rowspan="2">
                      <small> {{ $t("globals.regular") }} </small>
                    </th>
                    <th rowspan="3">
                      <small> {{ $t("summer.received-count") }} </small>
                    </th>
                    <th rowspan="3">
                      <small> {{ $t("summer.issued-for-students") }} </small>
                    </th>
                    <th rowspan="3">
                      <small> {{ $t("summer.issued-for-employees") }} </small>
                    </th>
                    <th rowspan="3">
                      <small> {{ $t("summer.overbooks") }} </small>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2">
                      <small>{{ $t("globals.studied-last-year") }}</small>
                    </th>
                    <th colspan="2">
                      <small>{{ $t("globals.new-students") }}</small>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <small> {{ $t("globals.male") }}</small>
                    </th>
                    <th>
                      <small> {{ $t("globals.female") }}</small>
                    </th>
                    <th>
                      <small> {{ $t("globals.male") }}</small>
                    </th>
                    <th>
                      <small> {{ $t("globals.female") }}</small>
                    </th>
                    <th>
                      <small> {{ $t("globals.male") }}</small>
                    </th>
                    <th>
                      <small> {{ $t("globals.female") }}</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(statistic, index) in summerSchool.statistics"
                    :key="statistic"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="px-1">
                      <v-checkbox-btn
                        v-model="statistic.enable"
                        @keydown.shift.tab="
                          index == 0 ? navigateBackByTabInput() : true
                        "
                        @keydown.tab.exact="
                          index == summerSchool?.statistics?.length - 1 &&
                          !statistic.enable
                            ? navigateByTabInput()
                            : true
                        "
                        @change="
                          !statistic.enable ? resetLevelObject(statistic) : null
                        "
                      >
                      </v-checkbox-btn>
                    </td>
                    <td class="px-2">
                      {{ statistic.level_name }}
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          :disabled="
                            summerSchool.students_type == 2 || !statistic.enable
                          "
                          v-model="statistic.pervious_registars_male"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          :disabled="
                            summerSchool.students_type == 1 || !statistic.enable
                          "
                          v-model="statistic.pervious_registars_female"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          :disabled="
                            summerSchool.students_type == 2 || !statistic.enable
                          "
                          v-model="statistic.male_students_count"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          :disabled="
                            summerSchool.students_type == 1 || !statistic.enable
                          "
                          v-model="statistic.female_students_count"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          :disabled="
                            summerSchool.students_type == 2 || !statistic.enable
                          "
                          v-model="statistic.regular_registars_male"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="[
                            maxStatisticsValue,
                            checkMaxValue(
                              statistic.male_students_count,
                              statistic.pervious_registars_male
                            ),
                          ]"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          :disabled="
                            summerSchool.students_type == 1 || !statistic.enable
                          "
                          v-model="statistic.regular_registars_female"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="[
                            maxStatisticsValue,
                            checkMaxValue(
                              statistic.female_students_count,
                              statistic.pervious_registars_female
                            ),
                          ]"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          v-model="statistic.received_books"
                          :disabled="!statistic.enable"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          v-model="statistic.expendable_for_students_books"
                          :disabled="!statistic.enable"
                          hide-details="auto"
                          variant="underlined"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                          type="number"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          v-model="statistic.expendable_for_teachers_books"
                          :disabled="!statistic.enable"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          v-model="statistic.overbooks"
                          :disabled="!statistic.enable"
                          hide-details="auto"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                    <td class="px-1 py-1">
                      <v-lazy>
                        <v-text-field
                          density="compact"
                          v-model="statistic.lecturelog_number"
                          hide-details="auto"
                          :disabled="!statistic.enable"
                          variant="underlined"
                          type="number"
                          placeholder="0"
                          :rules="maxStatisticsValue"
                          @keydown.tab.exact="
                            index == summerSchool?.statistics?.length - 1
                              ? navigateByTabInput()
                              : true
                          "
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-card-text>
        </v-window-item>
        </VForm>

        <VForm ref="form3" v-model="validForms.form3">
        <v-window-item :value="2">
          <v-card-text>
            <v-card class="mt-1" elevation="1">
              <v-table class="table-open-summer" density="compact">
                <thead>
                  <th class="py-2">{{ $t("globals.#") }}</th>
                  <th class="py-2">{{ $t("globals.task-name") }}</th>
                  <th class="py-2">{{ $t("globals.executed") }}</th>
                </thead>
                <tbody>
                  <tr v-for="(task, index) in summerSchool.tasks" :key="task">
                    <td>
                      <v-lazy>
                        {{ index + 1 }}
                      </v-lazy>
                    </td>
                    <td>
                      <v-lazy>
                        {{ task.name }}
                      </v-lazy>
                    </td>
                    <td class="d-flex justify-center">
                      <v-checkbox
                        style="max-width: 30px"
                        density="compact"
                        hide-details="auto"
                        v-model="task.status"
                        focused
                        @keydown.shift.tab="
                          index == 0 ? navigateBackByTabInput() : true
                        "
                        @keydown.tab.exact="
                          index == summerSchool?.tasks?.length - 1
                            ? navigateByTabInput()
                            : true
                        "
                      >
                      </v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-card-text>
        </v-window-item>
        </VForm>

        <VForm ref="form4" v-model="validForms.form4">
        <v-window-item :value="3">
          <v-card-text>
            <v-card class="mt-2" elevation="1">
              <v-table
                class="table-open-summer"
                density="compact"
                height="400"
                fixed-header
              >
                <thead>
                  <tr>
                    <th class="py-2 text-center">{{ $t("globals.#") }}</th>
                    <th class="py-2 text-center">
                      {{ $t("summer.activity.name") }}
                    </th>
                    <th class="py-2 text-center">
                      {{ $t("globals.executed-count") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(activity, index) in summerSchool.activities"
                    :key="activity"
                  >
                    <td>
                      <v-lazy>
                        {{ index + 1 }}
                      </v-lazy>
                    </td>
                    <td>
                      <v-lazy>
                        {{ activity.activity_name }}
                      </v-lazy>
                    </td>
                    <td class="d-flex justify-center">
                      <!-- <v-lazy class="mb-2"> -->
                      <v-text-field
                        style="max-width: 100px"
                        density="compact"
                        hide-details="auto"
                        type="number"
                        placeholder="0"
                        variant="underlined"
                        :rules="maxStatisticsValue"
                        v-model="activity.activity_count"
                        @keydown.shift.tab="
                          index == 0 ? navigateBackByTabInput() : true
                        "
                        @keydown.tab.exact="
                          index == summerSchool?.activities?.length - 1
                            ? navigateByTabInput()
                            : true
                        "
                      >
                      </v-text-field>
                      <!-- </v-lazy> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-card-text>
        </v-window-item>
        </VForm>

        <VForm ref="form5" v-model="validForms.form5">
        <v-window-item :value="4">
          <v-card-text>
            <v-card class="mt-1" elevation="1">
              <v-table class="table-open-summer" density="compact">
                <thead>
                  <tr>
                    <th class="py-2 text-center">{{ $t("globals.#") }}</th>
                    <th class="py-2 text-center">
                      {{ $t("globals.visiting-entity") }}
                    </th>
                    <th class="py-2 text-center">
                      {{ $t("globals.visiting-count") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(entity, index) in summerSchool.visits"
                    :key="entity"
                  >
                    <td>
                      <v-lazy>
                        {{ index + 1 }}
                      </v-lazy>
                    </td>
                    <td>
                      <v-lazy>
                        {{ entity.entity_name }}
                      </v-lazy>
                    </td>
                    <td class="d-flex justify-center">
                      <v-lazy class="mb-2">
                        <v-text-field
                          style="max-width: 100px"
                          density="compact"
                          hide-details="auto"
                          type="number"
                          placeholder="0"
                          :autofocus="index == 0"
                          :rules="maxStatisticsValue"
                          variant="underlined"
                          v-model="entity.visit_count"
                          @keydown.shift.tab="
                            index == 0 ? navigateBackByTabInput() : true
                          "
                          @keydown.tab.exact="
                            index == summerSchool?.visits?.length - 1
                              ? navigateByTabInput()
                              : true
                          "
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-card-text>
        </v-window-item>
        </VForm>

        <VForm ref="form6" v-model="validForms.form6">
        <v-window-item :value="5">
          <v-card-text>
            <v-card class="mt-2" elevation="1">
              <v-table class="table-open-summer" density="compact">
                <thead>
                  <tr>
                    <th class="py-2 text-center">{{ $t("globals.#") }}</th>
                    <th class="py-2 text-center">
                      {{ $t("summer.dropout-reason") }}
                    </th>
                    <th class="py-2 text-center">
                      {{ $t("summer.student_count") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(reason, index) in summerSchool.students_dropout"
                    :key="reason"
                  >
                    <td>
                      <v-lazy>
                        {{ index + 1 }}
                      </v-lazy>
                    </td>
                    <td>
                      <v-lazy>
                        {{ reason.name }}
                      </v-lazy>
                    </td>
                    <td class="d-flex justify-center">
                      <v-lazy class="mb-2">
                        <v-text-field
                          style="max-width: 100px"
                          density="compact"
                          hide-details="auto"
                          type="number"
                          placeholder="0"
                          :autofocus="index == 0 ? true : false"
                          :rules="maxStatisticsValue"
                          variant="underlined"
                          v-model="reason.count"
                          @keydown.shift.tab="
                            index == 0 ? navigateBackByTabInput() : true
                          "
                          @keydown.tab.exact="
                            index == summerSchool?.students_dropout?.length - 1
                              ? navigateByTabInput()
                              : true
                          "
                        >
                        </v-text-field>
                      </v-lazy>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-card-text>
        </v-window-item>
        </VForm>

        <VForm ref="form7" v-model="validForms.form7">
        <v-window-item :value="6">
          <v-card-text>
            <v-card flat elevation="0" class="text-start">
              <v-btn-group>
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      @keydown.shift.tab="navigateBackByTabInput()"
                    >
                      <v-icon color="icon-color" class="me-2"
                        >mdi-eye-outline</v-icon
                      >
                      <v-icon color="icon-color">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-checkbox
                        v-for="header in employee_headers"
                        :key="header.key"
                        :label="header.title"
                        :value="header.key"
                        color="indigo"
                        v-model="selectedHead"
                        density="compact"
                        hide-details="auto"
                      >
                      </v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-btn size="small" @click="dialog = true">
                  <span>{{ $t("employee.add-employee") }}</span>
                  <v-icon color="success" class="ms-2">mdi-plus-thick</v-icon>
                </v-btn>
              </v-btn-group>
            </v-card>

            <v-card class="mt-2">
              <v-data-table
                density="compact"
                :headers="selectedHeaders"
                :items="tableList"
                items-per-page="-1"
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td v-for="(val, key) in item.columns" :key="key">
                      <span v-if="key == 'index'">{{ index + 1 }}</span>
                      <span v-else-if="key == 'fk_directorate'">{{
                        getDirectorateName(val)
                      }}</span>
                      <span v-else-if="key == 'shared_before'">{{
                        val ? $t("globals.yes") : $t("globals.no")
                      }}</span>
                      <span v-else-if="key == 'skill'"
                        >( {{ getSkillsNames(val) }} )</span
                      >
                      <span v-else-if="key == 'qualifications'">{{
                        getQualificationName(val)
                      }}</span>
                      <span v-else-if="key == 'gender'">{{
                        getGenderName(val)
                      }}</span>
                      <span v-else-if="key == 'role'">{{
                        getRoleName(val)
                      }}</span>

                      <span
                        v-else-if="key === 'actions'"
                        style="min-width: 100px !important"
                      >
                        <dir
                          :title="$t('globals.review')"
                          class="d-inline"
                          v-if="isReview"
                        >
                          <v-btn
                            variant="text"
                            class="me-1 v-btn-icon"
                            size="large"
                            @click="
                              reviewSchoolSummerOpenEmployee(item.raw, index)
                            "
                          >
                            <v-icon color="grey">mdi-text-box-search</v-icon>
                          </v-btn>
                        </dir>
                        <v-btn
                          variant="text"
                          class="me-1 v-btn-icon"
                          size="large"
                          @click="editEmployee(item.raw)"
                        >
                          <v-icon color="warning">mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn
                          variant="text"
                          size="small"
                          class="v-btn-icon"
                          @click="deleteEmployee(index)"
                        >
                          <v-icon color="error">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </span>
                      <span v-else>{{ val }}</span>
                    </td>
                  </tr>
                </template>
                <template #bottom> </template>
              </v-data-table>
            </v-card>
            <v-card
              v-if="summerSchool.employees?.length == 0"
              class="py-3"
              rounded="0"
              flat
            >
              <h4 class="text-center">{{ $t("employee.no-employees") }}</h4>
            </v-card>
          </v-card-text>
        </v-window-item>
        </VForm>
      </v-window>
      <VCardActions class="px-3 py-0">
        <VBtn
          class="bg-indigo"
          v-if="
            !is_update &&
            $checkPermissions('opensummercenter.add_schoolsummeropen', [
              'gen_admin',
              'sys_admin',
              'gov_admin',
              'dir_admin',
            ])
          "
          @click.prevent="saveSummer()"
          size="small"
          :loading="saveDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="
            is_update &&
            $checkPermissions('opensummercenter.change_schoolsummeropen')
          "
          class="bg-success"
          @click.prevent="updateSchoolSummerOpenData()"
          size="small"
          :loading="editDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>

        <VBtn class="mx-3" size="small" @click="resetWindow(tab)">
          {{ $t("globals.page_clear") }}
          <VIcon icon="mdi-broom" color="red" end></VIcon>
        </VBtn>

        <VBtn
          class="ma-3"
          size="small"
          @click="resetForm()"
          v-if="summerInfo == 'null'"
        >
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </VForm>
  </VCard>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-form
      ref="dialog_form"
      @keydown.enter="selected_employee ? saveEmployee() : addEmployee()"
    >
      <v-card
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        class="text-main-color overflow-auto"
        max-height="700 "
      >
        <v-card-title class="py-2 bg-indigo mb-4 d-flex justify-space-between align-center">
          <span>
            <v-icon size="30" class="me-2">mdi-account-tie</v-icon>
            <span class="text-h6">{{ $t("employee.data") }}</span>
          </span>
          <span class="text-h5">[ {{ tableList?.length }} / {{ selected_employee_index + 1 }} ]</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="8">
              <VTextField
                v-model="employee.name_ar"
                autofocus
                counter="100"
                :label="`${$t('globals.employee-name')} *`"
                persistent-hint
                ref="employee_name"
                clearable
                density="compact"
                prepend-inner-icon="mdi-account-tie"
                @input="v$.employee.name_ar.$touch"
                :error-messages="
                  v$.employee.name_ar.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <DatetimePicker
                v-model="employee.birthdate"
                :calendar="'gregorg'"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('school.student_birthdate')"
                @input="v$.employee.birthdate.$touch"
                :error-messages="
                  v$.employee.birthdate.$errors.map((e) => e.$message)
                "
                 @change="employee.changed=true"
              ></DatetimePicker>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="genders"
                v-model="employee.gender"
                item-title="name"
                item-value="id"
                :label="`${$t('globals.gender')} *`"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-gender-male-female"
                @input="v$.employee.gender.$touch"
                :error-messages="
                  v$.employee.gender.$errors.map((e) => e.$message)
                "
                @update:modelValue="employee.changed=true"
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="qualifications"
                v-model="employee.qualifications"
                item-title="name"
                item-value="id"
                :label="$t('globals.qualification')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-certificate-outline"
                clearable
                @input="v$.employee.qualifications.$touch"
                :error-messages="
                  v$.employee.qualifications.$errors.map((e) => e.$message)
                "
                @update:modelValue="employee.changed=true"
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="roles"
                v-model="employee.role"
                item-title="name"
                item-value="id"
                :label="`${$t('summer.work-type')} *`"
                persistent-hint
                prepend-inner-icon="mdi-list-status"
                density="compact"
                @input="v$.employee.role.$touch"
                :error-messages="
                  v$.employee.role.$errors.map((e) => e.$message)
                "
                @update:modelValue="employee.changed=true"
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.specialization"
                :label="$t('globals.specialization')"
                persistent-hint
                clearable
                counter="50"
                density="compact"
                prepend-inner-icon="mdi-clipboard-account"
                @input="v$.employee.specialization.$touch"
                :error-messages="
                  v$.employee.specialization.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.phone_number"
                :label="$t('summer.employee.mobile-number')"
                persistent-hint
                clearable
                density="compact"
                type="number"
                prepend-inner-icon="mdi-cellphone-text"
                @input="v$.employee.phone_number.$touch"
                :error-messages="
                  v$.employee.phone_number.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              ></VTextField>
            </v-col>

            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.identity_id"
                :label="$t('globals.identity-id')"
                persistent-hint
                clearable
                counter="15"
                density="compact"
                type="number"
                prepend-inner-icon="mdi-numeric"
                @input="v$.employee.identity_id.$touch"
                :error-messages="
                  v$.employee.identity_id.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              ></VTextField>
            </v-col>

            <v-col cols="6" md="4">
              <AutoSelect
                :items="filterGovernorates(1)"
                v-model="employee.governorate"
                item-title="name_ar"
                item-value="id"
                :label="`${$t('globals.governorate')} *`"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-city"
                @update:modelValue="employee.fk_directorate = null , employee.changed=true"
                @input="v$.employee.governorate.$touch"
                :error-messages="
                  v$.employee.governorate.$errors.map((e) => e.$message)
                "
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :no-data-text="$t('globals.governorate-first')"
                :items="filterDirectorates(employee.governorate)"
                v-model="employee.fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="`${$t('globals.directorate')} *`"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-map-outline"
                @input="v$.employee.fk_directorate.$touch"
                :error-messages="
                  v$.employee.fk_directorate.$errors.map((e) => e.$message)
                "
                @update:modelValue="employee.changed=true"
              ></AutoSelect>
            </v-col>

            <VAutocomplete
              :items="filterVillages(employee.fk_directorate)"
              prepend-inner-icon="mdi-city"
              v-model="employee.address"
              item-title="name_ar"
              :no-data-text="$t('globals.directorate-first')"
              item-value="name_ar"
              persistent-hint
              :label="`${$t('globals.sub-district')}`"
              clearable
              density="compact"

              @change="employee.changed=true"
              
            ></VAutocomplete>



            <!-- <v-col cols="6" md="4">
              <v-text-field
                counter="50"
                type="input"
                v-model="employee.address"
                density="compact"
                clearable
                prepend-inner-icon="mdi-map-marker"
                :label="$t('summer.solitude')"
                @input="v$.employee.address.$touch"
                :error-messages="
                  v$.employee.address.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              />
            </v-col> -->


            <v-col cols="6" md="4">
              <AutoSelect
                :items="skills"
                multiple
                v-model="employee.skill"
                item-title="title"
                item-value="id"
                :label="$t('globals.skills')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-account-star"
                clearable
                @update:modelValue="employee.changed=true"
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.entity_type"
                :label="$t('summer.employee.place-of-work')"
                persistent-hint
                clearable
                counter="100"
                density="compact"
                prepend-inner-icon="mdi-account-hard-hat"
                @input="v$.employee.entity_type.$touch"
                :error-messages="
                  v$.employee.entity_type.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              ></VTextField>
            </v-col>

            <v-col cols="6" md="4">
              <v-checkbox
                class="mt-2"
                v-model="employee.shared_before"
                density="compact"
                :label="$t('employee.shared-in-summer')"
                @update:modelValue="employee.changed=true"
              />
            </v-col>

            <VCol cols="6" md="4"  v-if="employee.shared_before">
              <VTextField
                v-model="employee.years_of_shared_before"
                prepend-inner-icon="mdi-numeric"
                :label="$t('employee.years_of_shared_before')"
                density="compact"
                type="number"
                
              >

              </VTextField>
            </VCol>
            
            <v-col cols="12" sm="8" md="8" v-if="isReview">
              <AutoSelect
                :items="errors_of_fields"
                multiple
                v-model="employee.fk_errorofiled"
                item-title="name"
                item-value="id"
                :label="$t('globals.errors-of-fields')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-account-star"
                clearable
                @update:modelValue="employee.changed=true"
              ></AutoSelect>
            </v-col>



            <v-col cols="12" sm="4" md="4" v-if="isReview">
              <v-checkbox
                class="mt-2"
                hide-details="auto"
                v-model="employee.is_review"
                density="compact"
                :label="$t('globals.is-reported')"
                @update:modelValue="employee.changed=true"
              />
            </v-col>
            <VCol cols="6" md="4">
              <v-checkbox
                v-model="employee.active"
                density="compact"
                hide-details
                :label="$t('summer.is-emmployee-active')"
              >
              </v-checkbox>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="employee.note"
                clearable
                :label="$t('globals.note')"
                color="indigo"
                density="compact"
                hide-details="auto"
                rows="3"
                prepend-inner-icon="mdi-note-outline"
                @input="v$.employee.note.$touch"
                :error-messages="
                  v$.employee.note.$errors.map((e) => e.$message)
                "
                @change="employee.changed=true"
              ></VTextarea>
            </VCol>
          </v-row>
        </v-card-text>
        <v-card-actions class="sticky-footer px-2 py-0 ">
          <v-btn
            v-if="selected_employee == null"
            class="bg-indigo"
            size="small"
            @click="addEmployee"
          >
            <span class="text-white">
              {{ $t("globals.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </v-btn>
          <v-btn
            v-if="selected_employee"
            class="bg-success"
            size="small"
            @click="saveEmployee"
            :loading="editDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.save") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </v-btn>
          <v-btn
            border
            size="small"
            :disabled="!hasNext"
            @click="getNextEmployee()"
            >{{ $t("globals.next") }}</v-btn
          >
          <v-btn
            border
            size="small"
            :disabled="!hasPrev"
            @click="getPrevEmployee()"
            >{{ $t("globals.prev") }}</v-btn
          >
          <v-btn size="small" @click="dialog = false">{{
            $t("globals.cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import AutoSelect from "@/components/Globals/AutoSelect";
import { required, helpers, maxLength, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default {
  props: {
    id: {
      type: Number,
    },
    is_review: {
      type: Boolean,
    },
  },
  data() {
    return {
      v$: useValidate(),
      selected_employee_index: 0,
      isReview: false,
      dialog: false,
      errors_of_fields: [],
      errorFieldsOfSchoolSummer: [],
      maxStatisticsValue: [
        (value) => {
          return (
            !value ||
            value?.length <= 9 ||
            value <= 999999999 ||
            `${this.$t("globals.max_numbers_value")}: 999999999`
          );
        },
      ],
      selected_employee: null,
      selectedHead: [
        "index",
        "name_ar",
        "birthdate",
        "gender",
        "role",
        "phone_number",
        "card_identity",
        "address",
        "actions",
      ],

      summerInfo: "null",
      saveDataLoading: false,
      editDataLoading: false,

      image: require("@/assets/avatar.jpg"),
      shift_times_list: [],
      evaluation_list: [],
      groups_list: [],
      management_tasks: [],
      activity_list: [],
      // place_type: [],
      have_license: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],
      is_garden: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],
      summerSchool: {
        statistics: [],
        contacts: [{}],
        shortage_of_worker: null,
        errors_of_school_data: [],
        username: null,
        password: null,
        groups: null,
        tasks: [],
        activities: [],
        visits: [],
        employees: [],
        students_dropout: [],
      },
      is_update: false,
      image_reset: 0,
      image_reset_logo: 0,
      disable_add: false,
      updated_id: undefined,
      tab: undefined,

      validForms: {
        form1: false,
        form2: false,
        form3: false,
        form4: false,
      },

      school: {
        school_name_rule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        address: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        place_type_rule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        place_name_rule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            value.length <= 100 ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            value.length >= 3 ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        class_count_rule: [
          (value) =>
            !value ||
            /^[0-9]+$/.test(value) ||
            this.$t("globals.must_be_numbers"),
          (value) => {
            if (value == null || value == undefined)
              return this.$t("globals.required_field");
            if (value.length > 2) {
              return `${this.$t("globals.biggest_number_of_character")} 99`;
            }
            return true;
          },
        ],
        shortage_of_worker_rule: [
          (value) =>
            !value ||
            /^[0-9]+$/.test(value) ||
            this.$t("globals.must_be_numbers"),
          (value) => {
            if (value&&value.length > 2) {
              return `${this.$t("globals.biggest_number_of_character")} 99`;
            }
            return true;
          },
        ],
        governorate_rule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        directorate_rule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        shift_time_rule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        note_rule: [
          (value) =>
            value == undefined ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      employee_headers: [
        { title: this.$t("globals.number"), key: "index" },
        { title: this.$t("summer.name-employees"), key: "name_ar" },
        { title: this.$t("globals.birth_date"), key: "birthdate" },
        { title: this.$t("globals.gender"), key: "gender" },
        {
          title: this.$t("summer.employee.qualifications"),
          key: "qualifications",
        },
        { title: this.$t("user.role"), key: "role" },
        {
          title: this.$t("summer.employee.mobile-number"),
          key: "phone_number",
        },
        { title: this.$t("globals.specialization"), key: "specialization" },
        { title: this.$t("globals.skills"), key: "skill" },
        { title: this.$t("employee.shared-in-summer"), key: "shared_before" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity_type" },
        { title: this.$t("summer.card-number"), key: "identity_id" },
        { title: this.$t("globals.directorate"), key: "fk_directorate" },
        { title: this.$t("globals.address"), key: "address" },
        { title: this.$t("globals.actions"), key: "actions" },
      ],
      employee: {
        name_ar: null,
        birthdate: null,
        gender: null,
        qualifications: null,
        role: null,
        phone_number: null,
        entity_type: null,
        identity_id: null,
        fk_directorate: null,
        governorate: null,
        address: null,
        skill: [],
        specialization: null,
        shared_before: false,
        note: null,
      },
    };
  },
  components: {
    ImageInput,
    DatetimePicker,
    AutoSelect,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.id) {
        this.is_update = true;
        await this.getSchoolSummerOpenData(this.id);
      }
      this.checkIsReview();
      await this.getShiftTimesList();
      await this.getErrorFieldsOfSchoolSummer();
      await this.getStudentType();
      await this.getContactTypes();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getVillages();
      await this.getSchools();

      await this.getPlaceTypesList();
      await this.getShoolTypes();
      await this.getGenders();
      await this.getLevels();
      await this.getManagementTasks();
      await this.getActivityList();
      await this.getDirVisitorsr();
      await this.getRoles();
      await this.getQualifications();
      await this.getSkills();
      await this.getLevels();
      await this.getDropOutReason();
      await this.getEmployeesErrorsOfFields();
      if (this.role == 2) {
        this.summerSchool.governorate_id = this.user_governorate;
      }
      if (this.role == 3) {
        this.summerSchool.fk_directorate = this.user_directorate;
      }

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    hasPrev() {
      return this.selected_employee_index > 0;
    },
    hasNext() {
      return this.selected_employee_index < this.tableList?.length - 1;
    },
    ...mapState({
      contact_type_list: (state) => state.summer_globals.contact_type,
      student_type: (state) => state.summer_globals.student_type,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      contact: (state) => state.school.contact,
      user: (state) => state.User,
      roles: (state) => state.summer.roles,
      qualifications: (state) => state.summer.qualifications,
      summerSchoolData: (state) => state.summer.summerSchoolData,
      placeTypesList: (state) => state.summer.placeTypesList,
      shoolTypes: (state) => state.summer.shoolTypes,
      genders: (state) => state.summer.genders,
      levels: (state) => state.summer.levels,
      skills: (state) => state.summer.skills,
      levels: (state) => state.summer.levels,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
      drop_out_reasons: (state) => state.summer_globals.drop_out_reason,
    }),
    tabs() {
      return [
        { title: this.$t("school.main_data") },
        { title: this.$t("globals.statistic") },
        { title: this.$t("summer.report.administrative-tasks") },
        {
          title: this.$t("summer-report.actiivty-excution-statistics"),
          error: false,
        },
        { title: this.$t("globals.num-of-visits") },
        { title: this.$t("summer.students-drop-out") },
        { title: this.$t("globals.employees") },
      ];
    },
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterVillages: "school/getVillageByDirectorates",
      filterSchools: "school/getSchoolByVillages",

    }),

    selectedHeaders() {
      return this.employee_headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      return this.summerSchool.employees;
    },
    
  },
  methods: {

    resetWindow(windowNumber) {
      if (windowNumber === 0) {
        this.$refs.form1.reset();
      } else if (windowNumber === 1) {
        this.$refs.form2.reset();
      } else if (windowNumber === 2) {
        this.$refs.form3.reset();
      }
       else if (windowNumber === 3) {
        this.$refs.form4.reset();
      }
       else if (windowNumber === 4) {
        this.$refs.form5.reset();
      }
       else if (windowNumber === 5) {
        this.$refs.form6.reset();
      }
       else if (windowNumber === 6) {
        this.$refs.form7.reset();
      }
    },
    
    getNextEmployee() {
      if (this.hasNext) {
        this.selected_employee_index++;
        if (this.selected_employee_index < this.tableList?.length) {
          this.employee = Object.assign(
            {},
            this.tableList[this.selected_employee_index]
          );
          this.selected_employee = Object.assign({},this.employee)
        }
      }
    },
    getPrevEmployee() {
      if (this.hasPrev) {
        this.selected_employee_index--;
        if (this.selected_employee_index>=0) {
          this.employee = Object.assign(
            {},
            this.tableList[this.selected_employee_index]
          );
          this.selected_employee = Object.assign({},this.employee)
        }
      }
    },
    reviewSchoolSummerOpenEmployee(employee, index) {
      this.employee = Object.assign({}, employee);
      this.dialog = true;
      this.selected_employee_index = index;
      this.selected_employee = employee;
    },
    checkIsReview() {
      const is_review =
        this.$router.currentRoute.value.name == "summer_school_open_review";
      this.isReview = is_review;
      if (!this.isReview) {
        this.summerSchool.errors_of_school_data = [];
      }
    },
    async getErrorFieldsOfSchoolSummer() {
      await this.axios(
        `${this.base_url}/api/summer/error-fields-school-summer`
      ).then((res) => {
        this.errorFieldsOfSchoolSummer = res.data;
      });
    },
    async getEmployeesErrorsOfFields() {
      await this.axios(
        `${this.base_url}/api/summer/school-open-employees-errors`
      ).then((res) => {
        this.errors_of_fields = res.data;
      });
    },
    navigateByTabInput() {
      this.tab = Math.min(this.tabs?.length - 1, this.tab + 1);
    },
    navigateBackByTabInput() {
      this.tab = Math.min(this.tabs?.length - 1, this.tab - 1);
    },
    checkMaxValue(previous, new_students) {
      previous = previous ? previous : 0;
      new_students = new_students ? new_students : 0;
      const last_year_students = parseInt(previous) + parseInt(new_students);
      return (value) => {
        if (!value) return true;
        if (value && value <= last_year_students) return true;
        return this.$t("alert.attention.more-regulars-than-registrants");
      };
    },
    resetLevelObject(obj) {
      const keys = ["level_name", "fk_level", "enable", "note", "updated_by"];
      Object.keys(obj)?.forEach((key) => {
        if (!keys.includes(key)) {
          obj[key] = 0;
        }
      });
    },
    resetLevelsObjects() {
      var keys = [];
      if (this.summerSchool.students_type == 1) {
        keys = [
          "pervious_registars_female",
          "female_students_count",
          "regular_registars_female",
        ];
      } else if (this.summerSchool.students_type == 2) {
        keys = [
          "pervious_registars_male",
          "male_students_count",
          "regular_registars_male",
        ];
      }
      if (this.summerSchool?.statistics) {
        this.summerSchool.statistics
          ?.filter((level) => level.enable)
          ?.forEach((level) => {
            Object.keys(level)?.forEach((key) => {
              if (keys.includes(key)) {
                level[key] = null;
              }
            });
          });
      }
    },
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getVillages: "school/getVillages",
      getSchools:"school/getSchools",

      getContact: "school/getContact",
      getContactTypes: "summer_globals/getContactType",
      getStudentType: "summer_globals/getStudentType",
      getSupervisors: "summer/getSupervisors",
      getPlaceTypesList: "summer/getPlaceTypesList",
      getGenders: "summer/getGenders",
      getShoolTypes: "summer/getShoolTypes",
      getEmployees: "summer/getEmployees",
      getLevels: "summer/getLevels",
      getRoles: "summer/getRoles",
      getQualifications: "summer/getQualifications",
      getSkills: "summer/getSkills",
      getLevels: "summer/getLevels",
      getDropOutReason: "summer_globals/getDropOutReason",
    }),
    async getSchoolSummerOpenData(id) {
      await this.axios(`${this.base_url}/api/summer/school-open/${id}`).then(
        (response) => {
          this.summerSchool = response.data;
          this.summerSchool?.statistics.forEach((item) => {
            item.enable = item?.id ? true : false;
          });
        }
      );
    },
    async updateSchoolSummerOpenData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        var summerSchool = Object.assign({}, this.summerSchool);
        summerSchool.statistics = summerSchool.statistics.filter(
          (statistic) => statistic.enable
        );
        this.editDataLoading = true;
        if (!this.isReview) {
          summerSchool["errors_of_school_data"] = null;
        }
        await this.axios
          .put(
            `${this.base_url}/api/summer/school-open/${this.id}`,
            summerSchool
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.editDataLoading = false;
            setTimeout(() => {
              this.$router.push({ name: "summer_school_open_list" });
            }, 500);
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              error?.response?.data ?? this.$t("alert.failure.update")
            );
            this.editDataLoading = false;
          });
      } else {
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      }
    },
    editEmployee(employee) {
      this.employee = Object.assign({}, employee);
      this.dialog = true;
      this.selected_employee = employee;
    },
    saveEmployee() {
      this.v$.employee.$validate();
      if (!this.v$.employee.$error) {
        const index = this.summerSchool.employees.findIndex(
          (emp) => emp.name_ar == this.selected_employee.name_ar
        );
        if (index != -1) {
          this.summerSchool.employees[index] = Object.assign({}, this.employee);
        }
        this.dialog = false;
      }
    },
    deleteEmployee(index) {
      this.summerSchool.employees.splice(index, 1);
    },
    addEmployee() {
      this.v$.employee.$validate();
      if (!this.v$.employee.$error) {
        this.summerSchool.employees.push(Object.assign({}, this.employee));
        this.resetDialogForm();
        this.$refs.employee_name.focus();
      }
    },
    resetDialogForm() {
      this.$refs.dialog_form.reset();
      this.employee.shared_before = false;
      this.v$.employee.$reset();
      this.selected_employee = null;
    },
    getGenderName(id) {
      return this.genders.find((item) => item.id == id)?.name;
    },
    getSkillsNames(skills) {
      return this.skills
        .filter((item) => skills.includes(item.id))
        ?.map((item) => item.title)
        ?.join(" - ");
    },
    getQualificationName(id) {
      return this.qualifications.find((item) => item.id == id)?.name;
    },
    getDirectorateName(id) {
      return this.directorates.find((item) => item.id == id)?.name_ar;
    },
    getRoleName(id) {
      return this.roles.find((item) => item.id == id)?.name;
    },
    async getDirVisitorsr() {
      await this.axios(
        `${this.base_url}/api/summer/support-committees-choices`
      ).then((response) => {
        let support_committees = response.data;
        if (support_committees?.length > 0 && this.is_update == false) {
          support_committees.forEach((sup_comm) => {
            this.summerSchool.visits.push({
              entity: sup_comm.id,
              entity_name: sup_comm.name,
            });
          });
        }
      });
    },
    async getActivityList() {
      await this.axios
        .get(`${this.base_url}api/summer/school-open-activities`)
        .then((response) => {
          this.activity_list = response.data;
        });
    },
    async getManagementTasks() {
      await this.axios(
        this.base_url + "api/summer/tasks-management-choices"
      ).then((response) => {
        this.management_tasks = response.data;
      });
    },
    async getShiftTimesList() {
      this.shift_times_list = (
        await this.axios.get(this.base_url + "api/summer/choices/shift-time")
      ).data;
    },
    addContact() {
      this.summerSchool.contacts.push({
        contact_type: undefined,
        contact: undefined,
      });
    },
    removeContact() {
      if (this.summerSchool.contacts.length > 1)
        this.summerSchool.contacts.pop();
    },
    resetForm() {
      this.summerSchool.yard_is_available = false;
      this.summerSchool.lecture_room_is_available = false;
      this.summerSchool.accoustics_is_available = false;
      this.summerSchool.electricity_is_available = false;
      this.summerSchool.activity_tools_is_available = false;
      this.summerSchool.statistics?.forEach((level) => (level.enable = false));
      this.summerSchool.tasks?.forEach((task) => (task.status = false));
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
      this.$refs.form6.reset();
      this.$refs.form7.reset();
      this.is_update = false;
      this.v$.$reset();
      this.tab = 0;
    },
    checkContactType(value, type) {
      let number = /^\d+$/;
      if (type == 1) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 8) return `${this.$t("globals.max_numbers")} 8`;
      }
      if (type == 2) {
        let email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.test(value)) return this.$t("errors.email");
        if (value.length > 100) return this.$t("globals.max_characters");
      }
      if (type == 3) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 14) return `${this.$t("globals.max_numbers")} 14`;
      }
      if (type == 4) {
        if (value.length > 100) return this.$t("globals.max_characters");
      }
      if (type == 5) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 14) return this.$t("globals.max_numbers");
      }
      if (type == 6) {
        let telegram_regex = /^[a-zA-Z0-9_]{5,32}$/;
        if (!telegram_regex.test(value))
          return this.$t("globals.must_be_telegram");
      }

      return true;
    },
    async saveSummer() {
      const { valid } = await this.$refs.form1.validate();
      if (valid) {
        this.saveDataLoading = true;
        var summerSchool = Object.assign({}, this.summerSchool);
        summerSchool.statistics = summerSchool.statistics.filter(
          (statistic) => statistic.enable
        );
        let result = await this.axios
          .post(this.base_url + "api/summer/school-open", summerSchool)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            this.disable_add = false;
            this.saveDataLoading = false;
            this.$emit(
              "errorAlert",
              error?.response?.data ?? this.$t("globals.error_in_data")
            );
          });
      }
    },
  },
  validations() {
    return {
      employee: {
        name_ar: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
          required: helpers.withMessage(this.$t("errors.required"), required),
          unique: helpers.withMessage(
            this.$t("summer.employee.unique"),
            (value) => {
              return !this.summerSchool.employees
                .filter((emp) => emp.name_ar != this.selected_employee?.name_ar)
                .map((emp) => emp.name_ar)
                .includes(value)
                ? true
                : false;
            }
          ),
        },
        birthdate: {
          minLength: helpers.withMessage(" تاريخ غير صحيح", minLength(4)),
        },
        gender: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        qualifications: {},
        role: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        phone_number: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(14)
          ),
        },
        entity_type: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
        },
        identity_id: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(15)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "5",
            minLength(5)
          ),
        },
        fk_directorate: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        governorate: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        address: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(50)
          ),
        },
        skill: {},
        specialization: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(50)
          ),
        },
        shared_before: {},
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
      summerSchool: {},
    };
  },
  watch: {
    id(value) {
      if (!value) {
        this.resetForm();
      }
    },
    "summerSchool.shortage_of_worker"(val) {
      if (!val) {
        this.summerSchool.shortage_of_worker = null;
      }
    },
    dialog(val) {
      if (val == false) {
        this.resetDialogForm();
      }
    },
    "employee.identity_id"(val) {
      if (val == "") {
        this.employee.identity_id = null;
      }
    },
    levels() {
      if (
        this.summerSchool?.statistics?.length == 0 &&
        this.is_update == false
      ) {
        this.levels.forEach((level) => {
          this.summerSchool.statistics.push({
            fk_level: level.id,
            level_name: level.level_with_phase_name,
            pervious_registars_male: 0,
            pervious_registars_female: 0,
            male_students_count: 0,
            female_students_count: 0,
            regular_registars_male: 0,
            regular_registars_female: 0,
          });
        });
      }
    },
    activity_list() {
      if (
        this.summerSchool?.activities?.length == 0 &&
        this.is_update == false
      ) {
        this.activity_list.forEach((activity) => {
          this.summerSchool.activities.push({
            fk_activity_summer: activity.id,
            activity_name: activity.name,
            activity_count: null,
          });
        });
      }
    },
    drop_out_reasons() {
      if (
        this.summerSchool.students_dropout?.length == 0 &&
        this.is_update == false
      ) {
        this.drop_out_reasons.forEach((reason) => {
          this.summerSchool.students_dropout.push({
            reason: reason.id,
            name: reason.name,
          });
        });
      }
    },
    management_tasks() {
      if (this.summerSchool.tasks?.length == 0 && this.is_update == false) {
        this.management_tasks.forEach((task) => {
          this.summerSchool.tasks.push({
            typetaskmangement: task.id,
            name: task.name,
            status: false,
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.table-open-summer .v-text-field input {
  text-align: center !important;
}
.table-open-summer {
  text-align: center;
  width: 100%;
  border-color: #ccc;
  border-collapse: collapse;
}
.table-open-summer th,
.table-open-summer table th {
  background-color: #cfd8dc !important;
  background-color: #fff;
  padding-block: 5px;
  padding-inline: 5px;
}
img {
  max-width: 270px;
}

.sticky-footer{
  position: sticky;
  bottom: 0;
  background: white;
  
}
</style>
