<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <v-col cols="12" md="6">
            <VAutocomplete
              prepend-inner-icon="mdi-account-group-outline"
              :items="committee"
              v-model="fk_committee"
              item-title="name"
              item-value="id"
              :label="$t('school.committee_name')"
              type="data"
              persistent-hint
              density="compact"
              clearable
              hide-details="auto"
              @update:modelValue="getmembercomit((page = 1))"
            ></VAutocomplete>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card
    :loading="loading"
    v-if="
      $checkPermissions('summer_center.view_commitee', [
        'sys_admin',
        'gen_admin',
        'summer_admin',
        'user',
        'gov_admin',
        'dir_admin',
      ])
    "
  >
    <v-card-text>
      <!-- ######## Header Of Data Table Server ####### -->
      <div
        class="d-flex justify-space-between mb-1"
        v-if="
          $checkPermissions('summer_center.view_commitee', [
            'sys_admin',
            'gen_admin',
            'summer_admin',
            'user',
            'gov_admin',
            'dir_admin',
          ])
        "
      >
        <div class="d-flex" style="flex: 10">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-settings-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>

          <v-text-field
            color="indigo"
            v-model="txt_search"
            style="
              border-radius: 30px;
              border: 1px solid #ccc;
              color: black;
              height: 30px;
              width: 90%;
            "
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('globals.search-here')"
            @input="getmembercomit((page = 1))"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
        <div class="d-flex justify-end" style="flex: 1">
          <VBtn
            v-if="
              $checkPermissions('summer_center.add_commitee', [
                'summer_admin',
                'user',
                'gov_admin',
                'dir_admin',
              ])
            "
            class="bg-success"
            @click="dicultsandslotions"
            size="small"
          >
            <span class="text-white">{{ $t("globals.assigin") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- Data Table  -->
      <v-card :disabled="loading" flat>
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          item-key="id"
          fixed-footer
          density="compact"
          class="elevation-1"
          id="vDataTable"
          v-model:sort-by="sortBy"
        >
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span v-else> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    v-if="
                      $checkPermissions('summer_center.change_commitee', [
                        'sys_admin',
                        'summer_admin',
                        'user',
                        'gov_admin',
                        'dir_admin',
                      ])
                    "
                    class="me-1 v-btn-icon"
                    size="small"
                    @click="editesloutiondifcltiess(item.raw)"
                  >
                    <v-icon color="warnning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    v-if="
                      $checkPermissions('summer_center.delete_commitee', [
                        'sys_admin',
                        'summer_admin',
                        'user',
                        'gov_admin',
                        'dir_admin',
                      ])
                    "
                    size="small"
                    class="v-btn-icon"
                    @click="(selected_lecture_attendance = item.raw.id), (dialog = true)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="length"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ totalItems }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card>

      <!-- End data Table  -->
    </v-card-text>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <delete-dialog
    v-model="dialog"
    :loading="deleteProgress"
    @confirm-delete="deletesloutiondifcltiess(selected_lecture_attendance)"
  />
  <!-- ############### end Dialog Delete  ################### -->
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "LecturesList",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getCommittee();
      await this.getmembercomit();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      deleteProgress: false,
      fk_committee: undefined,


      // fk_directorate: undefined,
      // fk_governorate: undefined,


      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,

      loading: false,
      user: null,
      sortBy: [{ key: "name", order: "asc" }],
      selectedHead: ["name", "adjective", "entity", "phone_number", "note", "actions"],
      selected_lecture_attendance: null,
      fk_level: null,
      fk_subject: null,
      fk_empoylee: null,
      fk_division: null,
      from_date: null,
      lectures_attendance: [],
      to_date: null,
      headers: [
        { title: this.$t("summer.member_name"), key: "name" },
        { title: this.$t("summer.member_adject"), key: "adjective" },
        { title: this.$t("summer.member_entity"), key: "entity" },
        { title: this.$t("summer.member_phonenumber"), key: "phone_number" },
        { title: this.$t("globals.description"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.summer.teachers,
      classes: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      subjects: (state) => state.summer.subjects,
      teacher_class: (state) => state.school.teacher_class,
      committee: (state) => state.summer.committee,
    }),
    ...mapGetters({}),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      var list = JSON.parse(JSON.stringify(this.lectures_attendance));
      list.forEach((element) => {
        let fk_level = element.fk_level;
        let fk_empoylee = element.fk_empoylee;
        let fk_subject = element.fk_subject;
        let fk_division = element.fk_division;
        element.fk_level = this.getClassName(fk_level);
        element.fk_subject = this.getSubjectName(fk_subject);
        element.fk_empoylee = this.getTeacherName(fk_empoylee);
        element.fk_division = this.getDivisionName(fk_division);
      });
      return list;
    },
    class_subjects() {
      if (this.fk_level) {
        var class_data = this.classes.find((item) => item.id == this.fk_level);
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name_ar: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
    class_teachers() {
      var teacher_data = this.teachers;
      if (this.fk_level) {
        var teacher_id = this.teacher_class.results
          .filter((item) => item.fk_level == this.fk_level)
          .map((item) => item.fk_empoylee);
        var teacher_unique = [...new Set(teacher_id)];
        teacher_data = [];
        teacher_unique.forEach((el) => {
          teacher_data.push({ id: el, name_ar: this.getTeacherName(el) });
        });
      }
      return teacher_data;
    },
    teacher_subjects() {
      var subject_data = this.subjects;
      if (this.fk_empoylee && this.fk_level) {
        var subject_id = this.teacher_class.results
          .filter(
            (item) =>
              item.fk_empoylee === this.fk_empoylee && item.fk_level == this.fk_level
          )
          .map((item) => item.fk_subject);
        var subjects_uniqe = [...new Set(subject_id)];
        subject_data = [];
        subjects_uniqe.forEach((el) => {
          subject_data.push({ id: el, name_ar: this.getSubjectName(el) });
        });
      } else if (!this.fk_empoylee && this.fk_level) {
        subject_data = this.class_subjects;
      }
      return subject_data;
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },

  validations() {
    return {
      lecture_attendance: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
        },
        lecture_date: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_empoylee: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        data_entry: {},
        updated_by: {},
      },
    };
  },
  methods: {
    ...mapActions({
      getClasses: "summer/getLevels",
      getSubjects: "summer/getSubjects",
      getAllTeachers: "summer/getAllTeachers",
      getTeacherClass: "school/getTeacherClass",
      getDivisions: "summer/getDivisions",
      getCommittee: "summer/getCommittee",
    }),
    dicultsandslotions() {
      this.$router.push({ name: "membercommit_add" });
    },
    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name_ar : item.name_en) : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name : item.name) : "";
    },
    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name_ar : item.name_en) : "";
    },
    getDivisionName(id) {
      var item = this.divisions.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name : item.name) : "";
    },

    editesloutiondifcltiess(item) {
      this.$router.push(`${item.id}/edit`);
    },

    async deletesloutiondifcltiess(id) {
      this.deleteProgress = true;
      await this.axios
        .delete(`${this.base_url}api/summer/committee-member/${id}`)
        .then((response) => {
          this.dialog = false;
          this.deleteProgress = false;
          this.$emit("successAlert", this.$t("globals.data_deleted"));
          this.getmembercomit();
        })
        .catch((err) => {
          this.deleteProgress = false;
        });
    },
    async getmembercomit(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/committee-member`, {
        params: {
          fk_committee: this.fk_committee,

          

          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.lectures_attendance = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.totalItems = response.data.pagination.count;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getmembercomit();
    },
    page() {
      this.getmembercomit(this.page);
    },
    order_data() {
      this.getmembercomit(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>
