<template>
  <DataTable
    v-if="
      $checkPermissions('summer_center.view_level')
    "
    :create="
      () => {
        addDialog = true;
      }
    "
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_level', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_level', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      $checkPermissions('summer_center.add_level', ['sys_admin', 'gen_admin'])
    "
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12" md="6">
                <VAutocomplete
                  :items="LevelPhaseChoice"
                  v-model="level.phase"
                  autofocus
                  item-title="name"
                  item-value="key"
                  :label="`${$t('summer.level.phase-select')} *`"                

                  persistent-hint
                  prepend-inner-icon="mdi-ladder"
                  density="compact"
                  :rules="rules.subjectRule"
                ></VAutocomplete>
            </VCol>
            <VCol cols="12" md="6">
                <VTextField
                  v-model="level.name_ar"
                  :label="`${$t('summer.level.name_ar')} *`"                

                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :rules="rules.subjectNameArRule"
                ></VTextField>
            </VCol>
            
            <!-- <VCol cols="12" md="6">
                <VTextField
                  v-model="level.name_en"
                  :label="`${$t('summer.level.name_en')} *`"                

                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :rules="rules.subjectNameEnRule"
                ></VTextField>
            </VCol> -->

            <VCol cols="12">
                <VAutocomplete
                  :items="subjects"
                  v-model="level.subject"
                  multiple
                  item-title="name"
                  item-value="id"
                  :label="`${$t('subject.select')} *`"                

                  persistent-hint
                  chips
                  closable-chips
                  density="compact"
                  prepend-inner-icon="mdi-bookshelf"
                  :rules="rules.subjectRule"
                >
                  <template v-slot:chip="{props , item}">
                    <v-chip 
                      class="mx-1"
                      style="font-size:0.9rem"
                      v-bind="props" :text="item.raw.name">
                    </v-chip>
                  </template>
                </VAutocomplete>
            </VCol>
            <VCol cols="12">
                <VTextarea
                  v-model="level.note"
                  clearable
                  :label="$t('summer.initiative.note')"
                  no-resize
                  color="indigo"
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      LevelPhaseChoice: [
        { key: 1, name: "تأهيلي" },
        { key: 2, name: "أساسي" },
        { key: 3, name: "متوسط" },
        { key: 4, name: "عالي" },
      ],
      levels: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      level: {},
      rules: {
        subjectNameArRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectNameEnRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[A-Za-z-  0-9]+$/.test(value) ||
            this.$t("globals.must_be_letters_and_numbers_en"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectPhaseRule: [(value) => !!value || this.$t("globals.required_field")],
        subjectRule: [
          (value) => {
            if (value == null || value.length < 1)
              return this.$t("globals.required_field");
            return 1;
          },
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.level.phase"), key: "phase_name" },
        { title: this.$t("summer.level.name_ar"), key: "name_ar" },
        // { title: this.$t("summer.level.name_en"), key: "name_en" },
        { title: this.$t("globals.note"), key: "note", sortable: false ,show:false},
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.summer.subjects,
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.levels) {
        return JSON.parse(JSON.stringify(this.levels));
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSubjects();
      await this.getItems()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getSubjects: "summer/getSubjects",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.level = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    getPhaseNameByKey(key) {
      const phase = this.LevelPhaseChoice.find((obj) => obj.key === key);
      return phase ? phase.name : "";
    },
    editItem(level) {
      this.level = level;
      this.addDialog=true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.level.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/level", this.level)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.level = {};
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if(error?.response?.data?.non_field_errors){
              this.$emit("errorAlert", this.$t("alert.failure.unique"));
            }else{
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      this.level.updated_by = this.user;
      if (valid) {
        this.editDataLoading = true;
        this.level.updated_by = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/level/" + this.level.id, this.level)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if(error?.response?.data?.non_field_errors){
              this.$emit("errorAlert", this.$t("alert.failure.unique"));
            }else{
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status ;
        let result = await this.axios.delete(this.base_url + "api/summer/level/" + delete_id)
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = error
          });
        return status
      }
    },
    async getItems(page = 1,per_page=10,ordering='id',search=null) {
      await this.axios(`${this.base_url}/api/summer/level`, {
        params: {
          search: search,
          page: page,
          page_size:per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.levels = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>

<style>

</style>
