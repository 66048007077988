<template>
  <VCard class="justify-center">
    <VForm ref="form" class="" @submit.prevent="saverecommendation">
      <VCard-text>
      <VRow class="pt-0 pb-0 mt-2">
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <v-autocomplete
              v-model="recommendation.fk_week"
              :label="`${$t('summer.calendar.week-name')} *`"
              persistent-hint
              item-title="week_name"
              item-value="id"
              clearable
              density="compact"
              :items="weeks"
              prepend-inner-icon="mdi-calendar-week"
              :rules="rules.week"
            ></v-autocomplete>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5" >
          <VCardItem class="pa-0">
            <VTextField
              v-model="recommendation.responsible"
              :label="`${$t('summer.recommendations.implementation-officer')} *`"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-account-tie"
              :rules="rules.responsible"
            ></VTextField>
          </VCardItem>
        </VCol>

        <VCol cols="12" class="pt-0 px-3" xs="12" md="5" lg="5">
           <VCardItem class="pa-0">
            <VRadioGroup 
              inline 
              :label="$t('summer.recommendations.is-optimastic')" 
              v-model="recommendation.is_positive"
              >
                <VRadio :label="$t('globals.yes')" :value="true" ></VRadio>
                <VRadio :label="$t('globals.no')" :value="false" ></VRadio>
            </VRadioGroup>
           </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <VTextField
              v-model="recommendation.title"
              :label="(recommendation.is_positive==1)? `${$t('summer.recommendations.address')} *`:`${$t('summer.recommendations.address-negative')} *`"
              persistent-hint
              rows="2"
              clearable
              density="compact"
              prepend-inner-icon="mdi-format-title"
              :rules="rules.title"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5" v-if="recommendation.is_positive==0">
          <VCardItem class="pa-0">
            <VTextField
              v-model="recommendation.solution"
              :label="$t('summer.recommendations.solutions')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-lightbulb-outline"
              :rules="rules.solution"
            ></VTextField>
          </VCardItem>
        </VCol>

        <VCol cols="12" class="pt-0 px-3" xs="10" md="10" lg="10">
          <VCardItem class="pa-0">
            <VTextarea
              clearable
              v-model="recommendation.note"
              :label="$t('summer.initiative.note')"
              no-resize
              counter="250"
              density="compact"
              rows="2"
              prepend-inner-icon="mdi-note-outline"
              :rules="rules.note"
            ></VTextarea>
          </VCardItem>
        </VCol>

      </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      class="mx-2"
      v-if="
        $checkPermissions('summer_center.add_recommendation',['sys_admin','summer_admin','user']) ||
        $checkPermissions('summer_center.change_recommendation',['sys_admin','summer_admin','user'])
      "
    >
      <VBtn v-if="!is_update && $checkPermissions('summer_center.add_recommendation',['sys_admin','summer_admin','user'])" class="bg-indigo" @click="saverecommendation()" :loading="saveloading" size="small">
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && $checkPermissions('summer_center.change_recommendation',['sys_admin','summer_admin','user'])"
        class="bg-success"
        @click="updaterecommendation()"
        size="small"
        :loading="saveloading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <v-divider class="my-4" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2" v-if="$checkPermissions('summer_center.view_recommendation',['sys_admin','summer_admin','user'])">
      <div class="d-flex justify-space-between pa-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div style="width: 100%">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              @input="getrecommendation((page = 1))"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
                width: 100%;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :loading="loading"
        :headers="selectedHeaders"
        :items="recommendationdata"
        v-model:sort-by="sortBy"
        item-value="title"
        item-key="id"
        id="vDataTable"
        fixed-footer
        density="compact"
        class="elevation-1 text-center"
      >
        <template v-slot:item="{ item }" >
          <tr class="row-hover" >
            <td v-for="(val, key) in item.columns" :key="key">
              
              <span v-if="key === 'is_positive'">
              <v-icon color="success" v-if="val == true">
                   mdi-check-circle
                   </v-icon>
                   <v-icon color="error" v-else>

                     mdi-close-circle
                   </v-icon>
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="editrecommendation(item.raw)"
                  v-if="$checkPermissions('summer_center.change_recommendation',['sys_admin','summer_admin','user'])"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                  v-if="$checkPermissions('summer_center.delete_recommendation',['sys_admin','summer_admin','user'])"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="pagination.num_pages"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ pagination.count }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table-server>
    </v-card>
  </VCard>
  <delete-dialog
    v-model="dialogVisible"
    :loading="deleteloading"
    @confirm-delete="deleterecommendation" 
  ></delete-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      recommendations: [],
      deleteloading:false,
      saveloading:false,
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      recommendation: {
        fk_week: undefined,
        title: undefined,
        responsible: undefined,
        solution: undefined,
        is_positive: true,
        note: "",
        data_entry: undefined,
        updated_by: undefined,
      },
      sortBy: [
        {
          key: "title",
          order: "asc",
        },
      ],
      rules: {
        title: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            value.length <= 50 ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
        ],
        week: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        responsible: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => value.length <= 50 || `${this.$t("globals.biggest_number_of_character")} 50`,
        ],
        solution: [
           (value) => value == null || value.length <= 100 || `${this.$t("globals.biggest_number_of_character")} 100`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      visitType:3,
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_type:"adjective",
      selectedHead: ["week_name","responsible","solution","is_positive","title","note", "actions"],
      headers: [
        { title: this.$t("summer.calendar.week-name"), key: "week_name" , sortable: false},
        { title: this.$t("summer.recommendations.name-implementation-officer"), key: "responsible" },
        { title: this.$t("summer.recommendations.solutions"), key: "solution" },
        { title: this.$t("summer.recommendations.optimastic"), key: "is_positive" },
        { title: this.$t("summer.recommendations.title-weekly-recommendations"), key: "title" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions" , sortable: false},
      ],
      txt_search: undefined,
    };
  },
  computed: {
    recommendationdata(){
      if(this.visitType==1 || this.visitType==2){
        return this.recommendations.filter(el=>el.type==this.visitType)
      } else {
        return this.recommendations
      }
    },
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
    }),
    ...mapGetters({}),
    selectedHeaders() {
        return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },

    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks()
      await this.getrecommendation()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
     ...mapActions({
            getWeeks: "summer/getWeeks",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.recommendation = {};
      this.recommendation.is_positive = true;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editrecommendation(recommendation) {
      this.recommendation = {...recommendation};
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saverecommendation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveloading = true;
        this.recommendation.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/recommendation", this.recommendation)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.recommendation = {};
            this.saveloading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            if(error.response.data.title[0]=="recommendation with this title already exists."){
              this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            }
            this.saveloading = false;
          });
        this.resetForm();
        this.getrecommendation();
        setTimeout(() => {
        }, 2000);
      }
    },
    async updaterecommendation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveloading = true;
        this.recommendation.updated_by = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/recommendation/" + this.recommendation.id,this.recommendation)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveloading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
            this.saveloading = false;
          });
        this.resetForm();
        this.getrecommendation();
       
      }
    },
    async deleterecommendation() {
      if (this.delete_id) {
        this.deleteloading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/recommendation/" + this.delete_id)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteloading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.deleteloading = false;
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getrecommendation();
      }
    },
    async getrecommendation(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/recommendation`, {
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.recommendations = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getrecommendation();
    },
    page() {
      this.getrecommendation(this.page);
    },
    order_data(){
      this.getrecommendation(this.page);
    }
  },
};
</script>

<style scoped>

</style>
