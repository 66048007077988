<template>
  <DataTable
    v-if="$checkPermissions('summer_center.view_skill')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getSkills"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editSkill"
    :delItem="deleteSkill"
    :del_perm="$checkPermissions('summer_center.delete_skill',['sys_admin','gen_admin'])"
    :edit_perm="$checkPermissions('summer_center.change_skill',['sys_admin','gen_admin'])"
    :add_perm="$checkPermissions('summer_center.add_skill',['sys_admin','gen_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
   <v-card flat >
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update?updateSkill():saveSkill()">
          <VRow class="mt-2">
            <VCol cols="12">
                <VTextField
                  v-model="skill.title"
                  :label="`${$t('summer.add-skill')} *`"

                  persistent-hint
                  clearable
                  autofocus
                  counter="50"
                  density="compact"
                  prepend-inner-icon="mdi-star"
                  :rules="rules.subjectNameArRule"
                ></VTextField>
            </VCol>

            <VCol cols="12" >
                <VTextarea
                  v-model="skill.note"
                  clearable
                  color="indigo"
                  :label="$t('summer.initiative.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveSkill()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateSkill()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      loading: false,
      del_loading: false,
      addDialog:false,
      skillPhaseChoice: [
        { key: 1, name: "تأهيلي" },
        { key: 2, name: "أساسي" },
        { key: 3, name: "متوسط" },
        { key: 4, name: "عالي" },
      ],
      skills: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      skill: {},
      
      rules: {
        subjectNameArRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectNameEnRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[A-Za-z  0-9]+$/.test(value) ||
            this.$t("globals.must_be_letters_and_numbers_en"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectPhaseRule: [(value) => !!value || this.$t("globals.required_field")],
        subjectRule: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
           !value || value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },

      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.skill-name"), key: "title" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    ...mapGetters({}),
    
    tableList() {
      if (this.skills) {
        return JSON.parse(JSON.stringify(this.skills));
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSkills();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.skill = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editSkill(skill) {
      this.skill = Object.assign({},skill);
      this.addDialog = true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    
    async saveSkill() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true ;
        this.skill.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/skill", this.skill)
          .then((response) => {
              this.saveDataLoading = false;
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.skill = {};
              this.resetForm();
              this.getSkills();
          })
          .catch((error) => {
            this.saveDataLoading = false;

            if (error.response.data.title) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      }
    },
    async updateSkill() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.skill.data_entry = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/skill/" + this.skill.id, this.skill)
          .then((response) => {
              this.saveDataLoading = false
              this.$emit("successAlert", this.$t("globals.data_updated"));
              this.resetForm();
              this.getSkills();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.title) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });

        
      }
    },
    async deleteSkill(delete_id) {
      if (delete_id) {
        var status ;
        this.del_loading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/skill/" + delete_id)
          .then((response) => {
            status = true
          })
          .catch((error) => {
           status = error
          });
        return status
      }
    },
    async getSkills(page = 1,per_page = 10,ordering = null,search = null) {
      await this.axios(`${this.base_url}/api/summer/skill`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.skills = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>

<style Scoped>

@media (max-width: 767px) {
  .contect {
    padding: 0px;
  }
}
.contect {
  padding: 16px;
}
</style>
