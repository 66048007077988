<template>
  <VCard>
    <v-card-text>
      <v-form @input="handleInput" ref="form" @submit.prevent="saveEmployee">
        <v-locale-provider :locale="$i18n.locale">
          <v-tabs
            v-model="tab"
            slider-color="indigo"
            center-active
            show-arrows
            density="compact"
            color="blue"
          >
            <v-tab v-for="item in items" :key="item.key" :value="item.key">
              {{ item.value }}
            </v-tab>
          </v-tabs>
        </v-locale-provider>
        <v-window v-model="tab">
          <VForm ref="form1" v-model="validForms.form1" >
            <v-window-item :value="items[0].key">
              <v-row class="my-4 pa-2">
                <v-col cols="12" md="10">
                  <v-row>
                    <VCol
                      class="pt-0 px-3 pd-3"
                      cols="12"
                      md="6"
                      v-if="role >= 0 && role <= 3"
                    >
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          v-model="employee.fk_summer"
                          item-value="id"
                          item-title="name_ar"
                          prepend-inner-icon="mdi-domain"
                          :items="summer_schools"
                          :label="`${$t('globals.summer-centre')} *`"
                          persistent-hint
                          density="compact"
                          :rules="rules.fk_summer"
                          :no-data-text="$t('summer.not-found')"
                          clearable
                          @update:modelValue="getSummerSchools()"
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.name_ar"
                          :label="`${$t('summer.name-employees')} *`"
                          persistent-hint
                          clearable
                          density="compact"
                          prepend-inner-icon="mdi-account-tie"
                          :rules="rules.nameAr"
                        ></VTextField>
                      </VCardItem>
                    </VCol>

                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.identity_id"
                          :label="$t('summer.card-number')"
                          persistent-hint
                          clearable
                          density="compact"
                          type="number"
                          prepend-inner-icon="mdi-numeric"
                          :rules="rules.identity_id"
                        ></VTextField>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="genders"
                          v-model="employee.gender"
                          item-title="name"
                          item-value="id"
                          :label="`${$t('globals.gender')} *`"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-gender-male-female"
                          :rules="rules.gender"
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="maritalStatus"
                          v-model="employee.marital_status"
                          item-title="name"
                          item-value="id"
                          :label="`${$t('globals.marital-status')} *`"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-ring"
                          :rules="rules.marital_status"
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol
                      class="pt-0 px-3"
                      cols="12"
                      md="6"
                      v-if="employee.marital_status && employee.marital_status != 1"
                    >
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.children"
                          :label="`${$t('summer.employee.children-numbers')} *`"
                          persistent-hint
                          clearable
                          density="compact"
                          type="number"
                          prepend-inner-icon="mdi-human-child"
                          :rules="rules.children"
                        ></VTextField>
                      </VCardItem>
                    </VCol>

                    <VCol
                      cols="12"
                    >
                      

                      <fieldset class="mb-2 pt-1 pb-3 border rounded px-2">
                        <legend class="mx-2 px-2">
                          <span class="text-medium-emphasis">مكان وتاريخ الميلاد</span>
                        </legend>
                        <VCol
                          class="pt-0 px-3"
                          cols="12"
                          md="6"
                          v-if="role == 0 || role == 1"
                        >
                          <VCardItem class="pa-0">
                            <VAutocomplete
                              :items="filterGovernorates(1)"
                              v-model="employee.fk_current_governorate"
                              item-title="name_ar"
                              item-value="id"
                              :label="`${$t('globals.governorate')} *`"
                              persistent-hint
                              density="compact"
                              :rules="rules.governorate"
                              prepend-inner-icon="mdi-city"
                              @update:modelValue="
                                getSummerSchools(),
                                  (employee.directorate = undefined),
                                  (employee.fk_summer = null)
                              "
                            ></VAutocomplete>
                          </VCardItem>
                        </VCol>
                        <VCol
                          class="pt-0 px-3"
                          cols="12"
                          md="6"
                          v-if="role >= 0 && role <= 2"
                        >
                          <VCardItem class="pa-0">
                            <VAutocomplete
                              :no-data-text="$t('directorate.not-found')"
                              :items="filterDirectorates(employee.fk_current_governorate)"
                              v-model="employee.fk_current_directorate"
                              item-title="name_ar"
                              item-value="id"
                              :label="`${$t('globals.directorate')} *`"
                              persistent-hint
                              density="compact"
                              prepend-inner-icon="mdi-map-outline"
                              :rules="rules.directorate"
                              @update:modelValue="
                                getSummerSchools(), (employee.fk_summer = null)
                              "
                            ></VAutocomplete>
                          </VCardItem>
                        </VCol>
                        <VCol class="pt-0 px-3" cols="12" md="6">
                          <DatetimePicker
                            v-model="employee.birthdate"
                            :calendar="'gregorg'"
                            :placeholder="$t('globals.year-month-day')"
                            :label="$t('school.student_birthdate')"
                            :rules="rules.birthdate"
                          ></DatetimePicker>
                        </VCol>
                        <VCol class="pt-0 px-3" cols="12" md="6">
                          <VCardItem class="pa-0">
                            <VTextField
                              v-model="employee.birth_place"
                              :label="$t('summer.employee.birth-place')"
                              persistent-hint
                              clearable
                              density="compact"
                              prepend-inner-icon="mdi-account-hard-hat"
                              :rules="rules.birth_place"
                            ></VTextField>
                          </VCardItem>
                        </VCol>
                      </fieldset>
                    </VCol>

                    <VCol cols="12" md="6">
                      <!-- <v-checkbox
                        v-model="employee.active"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.is-emmployee-active')"
                      >
                      </v-checkbox> -->

                      <VAutocomplete
                      :items="have_active"
                      v-model="employee.active"
                      :label="$t('summer.is-emmployee-active')"
                      item-title="name"
                      item-value="key"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi mdi-notebook-outline"
                      > </VAutocomplete>
                    </VCol>
                    <VCol cols="12" class="pa-0 ma-0">
                      <VCardItem class="px-3">
                        <VTextarea
                          v-model="employee.note"
                          clearable
                          :label="$t('summer.initiative.note')"
                          no-resize
                          counter="250"
                          density="compact"
                          rows="2"
                          :rules="rules.note"
                          prepend-inner-icon="mdi-note-outline"
                        ></VTextarea>
                      </VCardItem>
                    </VCol>
                  </v-row>
                </v-col>
              </v-row>
            </v-window-item>
          </VForm>
          <VForm ref="form2" v-model="validForms.form2">
            <v-window-item :value="items[1].key">
              <v-row class="my-4 pa-2">
                <v-col cols="12" md="10">
                  <v-row>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="
                            employee.role != 1
                              ? roles.filter((role) => role.id != 1)
                              : roles
                          "
                          v-model="employee.role"
                          item-title="name"
                          item-value="id"
                          :label="`${$t('summer.work-type')} *`"
                          persistent-hint
                          prepend-inner-icon="mdi-list-status"
                          density="compact"
                          :rules="rules.role"
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.entity_type"
                          :label="$t('summer.employee.place-of-work')"
                          persistent-hint
                          clearable
                          density="compact"
                          prepend-inner-icon="mdi-account-hard-hat"
                          :rules="rules.entity_type"
                        ></VTextField>
                      </VCardItem>
                    </VCol>
                    <VCol
                      class="pt-0 px-3"
                      cols="12"
                      md="6"
                      v-if="employee.role && employee.role !== 3"
                    >
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.job_id"
                          :label="$t('globals.job-id')"
                          persistent-hint
                          clearable
                          density="compact"
                          type="number"
                          prepend-inner-icon="mdi-numeric"
                          :rules="rules.job_id"
                        ></VTextField>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="workPeriods"
                          v-model="employee.work_period"
                          item-title="name"
                          item-value="id"
                          :label="`${$t('summer.shift-time')} *`"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          :rules="rules.work_period"
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="qualifications"
                          v-model="employee.qualifications"
                          item-title="name"
                          item-value="id"
                          :label="`${$t('globals.qualification')} *`"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-certificate-outline"
                          :rules="rules.qualifications"
                          clearable
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>

                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.specialization"
                          :label="$t('globals.specialization')"
                          persistent-hint
                          clearable
                          density="compact"
                          prepend-inner-icon="mdi-account-hard-hat"
                          :rules="rules.other_work"
                        ></VTextField>
                      </VCardItem>
                    </VCol>

                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.other_work"
                          :label="$t('summer.employee.other-work')"
                          persistent-hint
                          clearable
                          density="compact"
                          prepend-inner-icon="mdi-account-hard-hat"
                          :rules="rules.other_work"
                        ></VTextField>
                      </VCardItem>
                    </VCol>
                  </v-row>
                </v-col>
              </v-row>
            </v-window-item>
          </VForm>
          <VForm ref="form3" v-model="validForms.form3">
            <v-window-item :value="items[2].key">
              <v-row class="my-4 pa-2">
                <v-col cols="12" md="10">
                  <v-row>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="countries"
                          v-model="employee.country"
                          item-title="name_ar"
                          item-value="id"
                          :label="`${$t('globals.country')} *`"
                          persistent-hint
                          density="compact"
                          :rules="rules.country"
                          prepend-inner-icon="mdi-flag"
                          @update:modelValue="
                            (employee.governorate = undefined),
                              (employee.directorate = undefined)
                          "
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="filterGovernorates(employee.country)"
                          v-model="employee.governorate"
                          item-title="name_ar"
                          item-value="id"
                          :label="`${$t('globals.governorate')} *`"
                          persistent-hint
                          density="compact"
                          :rules="rules.governorate"
                          prepend-inner-icon="mdi-city"
                          @update:modelValue="employee.directorate = undefined"
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VAutocomplete
                          :items="filterDirectorates(employee.governorate)"
                          :no-data-text="$t('directorate.not-found')"
                          v-model="employee.directorate"
                          item-title="name_ar"
                          item-value="id"
                          :label="`${$t('globals.directorate')} *`"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-map-outline"
                          :rules="rules.directorate"
                          @update:modelValue="
                          (employee.address = undefined)
                          "
                        ></VAutocomplete>
                      </VCardItem>
                    </VCol>

                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VAutocomplete
                      :items="filterVillages(employee.directorate)"
                      v-model="employee.address"
                      item-title="name_ar"
                      :no-data-text="$t('globals.directorate-first')"
                      item-value="name_ar"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi-map-marker"
                      :label="$t('summer.solitude')"
                    ></VAutocomplete>
                  </VCol>



                    <!-- <VCol class="pt-0 px-3" cols="12" md="6">
                      <v-text-field
                        counter="150"
                        type="input"
                        v-model="employee.address"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('summer.solitude')"
                        :rules="rules.address"
                      />
                    </VCol> -->



                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VAutocomplete
                        v-model="employee.accommodation_type"
                        item-value="id"
                        item-title="name"
                        prepend-inner-icon="mdi-home"
                        :items="accommodation_types"
                        :label="`${$t('summer.accommodation-type')} *`"
                        persistent-hint
                        density="compact"
                        clearable
                        :rules="rules.accommodation"
                      ></VAutocomplete>
                    </VCol>
                    <VCol class="pt-0 px-3" cols="12" md="6">
                      <VCardItem class="pa-0">
                        <VTextField
                          v-model="employee.phone_number"
                          :label="`${$t('summer.employee.mobile-number')} *`"
                          persistent-hint
                          clearable
                          density="compact"
                          type="number"
                          prepend-inner-icon="mdi-cellphone-text"
                          :rules="rules.phone_number"
                        ></VTextField>
                      </VCardItem>
                    </VCol>
                  </v-row>
                </v-col>
              </v-row>
            </v-window-item>
          </VForm>
          <VForm ref="form4" v-model="validForms.form4">
            <v-window-item :value="items[3].key">
              <v-row class="my-4 pa-2">
                <v-col cols="12" md="10">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="employee.user.first_name"
                        clearable
                        counter="100"
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('user.fname')"
                        :error-messages="
                          v$.employee.user.first_name.$errors.map((e) => e.$message)
                        "
                      />
                      <!-- :rules="rules.first_name" -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="employee.user.last_name"
                        clearable
                        counter="100"
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('user.lname')"
                        :error-messages="
                          v$.employee.user.last_name.$errors.map((e) => e.$message)
                        "
                      />
                      <!-- :rules="rules.last_name" -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="employee.user.username"
                        clearable
                        counter="100"
                        autocomplete="off"
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('user.username')"
                        :error-messages="
                          v$.employee.user.username.$errors.map((e) => e.$message)
                        "
                      />
                      <!-- :rules="rules.username" -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="employee.user.email"
                        autocomplete="off"
                        clearable
                        type="email"
                        counter="100"
                        name="email"
                        id="email"
                        density="compact"
                        prepend-inner-icon="mdi-alpha-e"
                        :label="$t('user.email')"
                        :error-messages="
                          v$.employee.user.email.$errors.map((e) => e.$message)
                        "
                      />
                      <!-- :rules="rules.email" -->
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        type="password"
                        v-model="employee.user.password"
                        name="password"
                        id="password"
                        clearable
                        counter="100"
                        density="compact"
                        autocomplete="new-password"
                        prepend-inner-icon="mdi-alpha-e"
                        :label="$t('user.password')"
                        :error-messages="
                          v$.employee.user.password.$errors.map((e) => e.$message)
                        "
                        @input="v$.employee.user.password.$touch"
                      />
                      <!-- :rules="is_update ? '' : rules.password" -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        color="indigo"
                        multiple
                        v-model="employee.user.groups"
                        density="compact"
                        item-title="name"
                        item-value="pk"
                        prepend-inner-icon="mdi-account-group"
                        clearable
                        chips
                        closable-chips
                        :items="groups_list"
                        :label="$t('user.group')"
                        :error-messages="
                          v$.employee.user.groups.$errors.map((e) => e.$message)
                        "
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip
                            class="mx-1"
                            style="font-size: 0.9rem"
                            v-bind="props"
                            :text="item.raw.name"
                          >
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3" md="3">
                      <v-checkbox
                        :label="$t('user.active')"
                        color="indigo"
                        v-model="employee.user.is_active"
                        density="compact"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-window-item>
          </VForm>
        </v-window>
      </v-form>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="
        $checkPermissions('summer_center.add_employee', [
          'sys_admin',
          'summer_admin',
          'gen_admin',
          'gov_admin',
          'dir_admin',
          'user',
        ]) ||
        $checkPermissions('summer_center.change_employee', [
          'sys_admin',
          'summer_admin',
          'gen_admin',
          'gov_admin',
          'dir_admin',
          'user',
        ])
      "
    >
      <VBtn
        v-if="
          !is_update &&
          $checkPermissions('summer_center.add_employee', [
            'sys_admin',
            'summer_admin',
            'gen_admin',
            'gov_admin',
            'dir_admin',
            'user',
          ])
        "
        class="bg-indigo"
        @click="saveEmployee()"
        :loading="DataLoading"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          is_update &&
          $checkPermissions('summer_center.change_employee', [
            'sys_admin',
            'summer_admin',
            'gen_admin',
            'gov_admin',
            'dir_admin',
            'user',
          ])
        "
        class="bg-success"
        @click="updateEmployee()"
        :loading="DataLoading"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>

      <VBtn class="mx-3" size="small" @click="resetWindow(tab)">
        {{ $t("globals.page_clear") }}
        <VIcon icon="mdi-broom" color="red" end></VIcon>
      </VBtn>

      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, minLength, email } from "@vuelidate/validators";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import useValidate from "@vuelidate/core";

export default {
  props: {
    id: {
      type: Number, 
    },
  },
  components: {
    DatetimePicker,
  },
  data() {
    return {
      v$: useValidate(),
      has_unsaved_data: false,
       validForms: {
        form1: false,
        form2: false,
        form3: false,
        form4: false,
      },

      max_date: null,
      DataLoading: false,
      tab: null,
      governorate: null,
      directorate: null,
      fk_summer: null,
      groups_list: [],
      summer_schools: [],
      employee: {
        user: {
          username: null,
          email: null,
          password: null,
          groups: null,
        },
      },

      have_active: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],

      rules: {
        nameAr: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        birthdate: [
          // (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            !value ||
            value < this.max_date ||
            this.$t("globals.max_date") + this.max_date,
        ],
        role: [(value) => !!value || this.$t("globals.required_field")],
        entity_type: [
          (value) =>
            !value || value.length <= 50 || `${this.$t("globals.max_characters")} 50`,
          (value) =>
            !value ||
            value.length >= 3 ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        // address: [
        //   (value) =>
        //     !value ||
        //     value.length <= 50 ||
        //     `${this.$t("globals.biggest_number_of_character")} 50`,
        //   (value) =>
        //     !value ||
        //     value.length >= 3 ||
        //     `${this.$t("globals.lowest_number_of_character")} 3`,
        // ],
        other_work: [
          (value) => {
            if (value) {
              if (value.length <= 3) {
                return `${this.$t("globals.lowest_number_of_character")} 3`;
              }
              if (value.length >= 50) {
                return `${this.$t("globals.biggest_number_of_character")} 50`;
              }
            }
            return true;
          },
        ],
        birth_place: [
          (value) => {
            if (value) {
              if (value.length >= 50) {
                return `${this.$t("globals.biggest_number_of_character")} 50`;
              }
            }
            return true;
          },
        ],
        identity_id: [
          (value) => {
            if (value && value.length < 4) {
              return `${this.$t("globals.biggest_number_of_character")} 4`;
            }
            if (value && value.length > 14) {
              return `${this.$t("globals.max_numbers")} 14`;
            }
            return true;
          },
        ],
        job_id: [
          (value) => {
            if (value && value?.length > 9) {
              return `${this.$t("globals.max_numbers")} 9`;
            }
            if (value && value?.length < 3) {
              return `${this.$t("globals.min_numbers")} 3`;
            }
            return true;
          },
        ],
        phone_number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => /^7\d*$/.test(value) || `${this.$t("globals.must-begin-with-7")}`,
          (value) =>
            (value && value.length <= 14) || `${this.$t("globals.max_numbers")} 14`,
          (value) =>
            (value && value.length >= 9) || `${this.$t("globals.min_numbers")} 9`,
        ],
        children: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => {
            if (value == null || value == undefined)
              return this.$t("globals.required_field");
            if (value.length > 2) {
              return `${this.$t("globals.max_numbers")} 2`;
            }
            return true;
          },
        ],
        work_period: [(value) => !!value || this.$t("globals.required_field")],
        qualifications: [(value) => !!value || this.$t("globals.required_field")],
        gender: [(value) => !!value || this.$t("globals.required_field")],
        marital_status: [(value) => !!value || this.$t("globals.required_field")],
        country: [(value) => !!value || this.$t("globals.required_field")],
        governorate: [(value) => !!value || this.$t("globals.required_field")],
        directorate: [(value) => !!value || this.$t("globals.required_field")],
        fk_summer: [(value) => !!value || this.$t("globals.required_field")],
        accommodation: [(value) => !!value || this.$t("globals.required_field")],
      },
      dialogVisible: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      user_delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.summer.subjects,
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      roles: (state) => state.summer.roles,
      qualifications: (state) => state.summer.qualifications,
      genders: (state) => state.summer.genders,
      maritalStatus: (state) => state.summer.maritalStatus,
      employeeStates: (state) => state.summer.employeeStates,
      workPeriods: (state) => state.summer.workPeriods,
      user: (state) => state.User,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
      accommodation_types: (state) => state.summer_globals.accommodation_types,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterVillages: "school/getVillageByDirectorates",
    }),
    items() {
      return [
        { key: "personal_information", value: this.$t("globals.personal-information") },
        {
          key: "employment_information",
          value: this.$t("globals.employment-information"),
        },
        { key: "contact_information", value: this.$t("globals.contact-information") },
        { key: "user_data", value: this.$t("globals.user-data") },
      ];
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.$route.params.id) {
        this.is_update = true;
        this.getEmployee(this.$route.params.id);

        this.governorate.fk_country = this.countries[0].id;
      }
      let date = new Date();
      this.max_date = new Date(date.getFullYear() - 10, date.getMonth(), date.getDay())
        .toISOString()
        .split("T")[0];
      await this.getSubjects();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getVillages();
      await this.getRoles();
      await this.getQualifications();
      await this.getGenders();
      await this.getMaritalStatus();
      await this.getEmployeeStates();
      await this.getWorkPeriods();
      await this.getAccommodationType();
      await this.getSummerSchools();
      if (this.role == 2) {
        this.governorate = this.user_governorate;
      }
      if (this.role == 3) {
        this.directorate = this.user_directorate;
        this.getSummerSchools();
      }
      let groups = await this.axios.get(`${this.base_url}groups/?user=1`);
      if (groups) this.groups_list = groups.data;
      if (!this.$route.params.id) {
        this.employee.country = this.countries[0].id;
        this.employee.governorate = this.user_governorate;
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    resetWindow(windowNumber) {
      if (windowNumber === "personal_information") {
        this.$refs.form1.reset();
      } else if (windowNumber === "employment_information") {
        this.$refs.form2.reset();
      } else if (windowNumber === "contact_information") {
        this.$refs.form3.reset();
      } else if (windowNumber === "user_data") {
        this.$refs.form4.reset();
      }
    },

    handleInput() {
      this.has_unsaved_data = true;
    },
    handleBeforeUnload(event) {
      if (this.has_unsaved_data) {
        event.preventDefault();
        event.returnValue =
          "هناك معلومات لم تقم بحفظها في هذا الشاشة؛ هل انت متاكد من أنك تريد المغادرة؟";
      }
    },
    confirmNavigation() {
      return window.confirm(
        "هناك معلومات لم تقم بحفظها في هذا الشاشة؛ هل انت متاكد من أنك تريد المغادرة؟"
      );
    },

    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.employee.directorate,
            governorate: this.employee.governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    ...mapActions({
      getSubjects: "summer/getSubjects",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getVillages: "school/getVillages",
      getRoles: "summer/getRoles",
      getQualifications: "summer/getQualifications",
      getGenders: "summer/getGenders",
      getMaritalStatus: "summer/getMaritalStatus",
      getEmployeeStates: "summer/getEmployeeStates",
      getWorkPeriods: "summer/getWorkPeriods",
      getAccommodationType: "summer_globals/getAccommodationType",
    }),
    getEmployeeBirthDate(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.employee.birthdate = date;
    },
    resetForm() {
      this.$refs.form.reset();
      this.v$.employee.user.$reset();
      this.employee = { user: {} };
      this.is_update = false;
      this.updated_id = undefined;
      this.tab = "personal_information";
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveEmployee() {
      for (let i=1;i<=4;i++){
        await this.$refs[`form${i}`].validate();
      }

      const { valid } = await this.$refs.form1.validate();
      if (
        this.employee.user.email ||
        this.employee.user.password ||
        this.employee.user.username
      ) {
        this.v$.employee.user.$validate();
      }
      if (valid && !this.v$.employee.user.$error) {
        this.DataLoading = true;
        this.employee.created_by = this.user;
        if (this.employee.is_active !== true) this.employee.is_active = false;
        let result = await this.axios
          .post(this.base_url + "api/summer/employee", this.employee)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.DataLoading = false;
            this.has_unsaved_data=false;
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined) {
                this.$emit("errorAlert", this.$t("globals.user_already_exists"));
              } else if (error.response.data.job_id) {
                this.$emit("errorAlert", this.$t("summer.employee.unique-job-id"));
              } else if (error.response?.data?.non_field_errors) {
                this.$emit("errorAlert", this.$t("summer.employee.unique"));
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.DataLoading = false;
          });
      }
    },
    async updateEmployee() {
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.v$.employee.user.$error) {
        this.DataLoading = true;
        this.employee.update_by = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/employee/" + this.employee.id, this.employee)
          .then((response) => {
            this.DataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.has_unsaved_data=false;
            setTimeout(() => {
              this.$router.push({ name: "employees" });
            }, 1500);

          })
          .catch((error) => {
            this.DataLoading = false;
            if (error.response.data.email)
              this.$emit(
                "errorAlert",
                this.$t("summer.user-with-this-email-already-exists")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async getEmployee(id) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/employee/` + id).then((response) => {
        this.employee = response.data;
        this.employee.first_name = response.data.user_first_name;
        this.employee.last_name = response.data.user_last_name;
        this.employee.username = response.data.user_username;
        this.employee.email = response.data.user_email;
        this.employee.groups = response.data.group_user;
        this.employee.country = response.data.country_id;
        this.employee.directorate = response.data.fk_directorate;
        this.employee.governorate = response.data.governorate_id;
        this.loading = false;
      });
    },
  },
  validations() {
    return {
      employee: {
        user: {
          first_name: {
            maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
          },
          last_name: {
            maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
          },
          username: {
            required: helpers.withMessage(this.$t("errors.required"), required),
            maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(30)),
          },
          email: {
            email: helpers.withMessage(this.$t("errors.email"), email),
            maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(255)),
          },
          password: {
            required: helpers.withMessage(this.$t("errors.required"), required),
            maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(128)),
            minLength: helpers.withMessage(this.$t("errors.min-entry"), minLength(8)),
          },
          groups: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
        },
      },
    };
  },
  watch: {
    id() {
      this.resetForm();
    },
    "employee.user.email"() {
      if (this.employee.user.email?.length == 0) {
        this.employee.user.email = null;
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.has_unsaved_data) {
      const shouldLeave = this.confirmNavigation();
      if (shouldLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>

<style></style>
