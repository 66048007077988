<template>

  <VForm
    ref="form"
    v-if="
      $checkPermissions('summer_center.change_employeelevel', [
        'sys_admin',
        'summer_admin',
        'user',
      ])
    "
  >
    <v-expansion-panels model-value="1">
      <v-expansion-panel class="mb-2" value="1">
        <v-expansion-panel-title class="px-4">
          <span>{{ $t("globals.select-criteria") }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="py-2">
            <VCol cols="6" md="4">
              <VAutocomplete
                color="indigo"
                v-model="receiver_type"
                density="compact"
                item-title="name"
                item-value="id"
                hide-details="auto"
                prepend-inner-icon="mdi-account"
                :items="receiver_type_list"
                clearable
                :label="$t('summer.curriculum.reciever_type')"
                @update:modelValue="getStudentsTable(), (fk_level = null)"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6" md="4">
              <VAutocomplete
                :items="levels"
                v-model="fk_level"
                prepend-inner-icon="mdi-stairs-box"
                item-title="level_with_phase_name"
                item-value="id"
                :label="$t('summer.level.name_ar')"
                persistent-hint
                hide-details="auto"
                density="compact"
                clearable
                @update:modelValue="getStudentsTable()"
              ></VAutocomplete>
            </VCol>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="justify-center" :loading="dataLoading">
      <v-card-text class="mt-1 pb-0">
        <v-row class="my-1">
          <VCol cols="6" class="mb-2">
            <v-text-field
              color="indigo"
              v-if="receiver_type == 2"
              v-model="txt_search"
              @input="getStudentsTable"
              density="compact"
              clearable
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              :label="$t('globals.student-search')"
            />
          </VCol>
        </v-row>
        <v-card
          :disabled="dataLoading"
          v-if="receiver_table && receiver_table.length > 0"
        >
          <VTable
            density="compact"
            fixed-header
            :height="receiver_table?.length > 9 ? 420 : null"
          >
            <thead>
              <tr>
                <th>{{ $t("summer.curriculum.reciever_name") }}</th>
                <th>{{ $t("summer.curriculum.recieved?") }}</th>
                <th>{{ $t("globals.note") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="receiver in receiver_table" :key="receiver">
                <td>
                  <v-lazy>
                    {{ receiver.name }}
                  </v-lazy>
                </td>
                <td>
                  <v-lazy>
                    <v-checkbox
                      v-model="receiver.is_recived_books"
                      color="indigo"
                      density="compact"
                      hide-details
                    >
                    </v-checkbox>
                  </v-lazy>
                </td>
                <td style="width: 50%">
                  <v-lazy>
                    <v-text-field
                      v-model="receiver.note"
                      hide-details="auto"
                      density="compact"
                      :placeholder="$t('school.notes_placeholder')"
                      variant="underlined"
                      clearable
                      :rules="note_rule"
                    />
                  </v-lazy>
                </td>
              </tr>
            </tbody>
          </VTable>
          <div class="d-flex mt-1" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </v-card>
      </v-card-text>
      <v-card-actions class="px-3">
        <v-btn
          v-if="
            !update &&
            $checkPermissions('summer_center.change_employeelevel', [
              'sys_admin',
              'summer_admin',
              'user',
            ])
          "
          @click="saveRecords"
          class="bg-indigo"
          :loading="saveProgress"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <!-- <v-btn v-if="update" @click="updateMarks"   class=" bg-success"    size="small">
                <span class="text-white">
                    {{$t('globals.edit')}}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn> -->
        <v-btn @click="cleanMarks" class="mx-3 bg-ccc" size="small">
          <span>
            {{ $t("globals.clear") }}
          </span>
          <v-icon icon="mdi-broom" color="golden" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </VForm>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import { log } from 'pdfmake/build/pdfmake';
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getlevels();
      await this.getStudentsTable();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      fk_level: undefined,
      receiver_type: 2,
      saveProgress: false,
      dataLoading: false,
      perPage: 10,
      txt_search: null,
      perPage: 10,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      pagination: {},
      page: 1,
      length: null,
      totalItems: null,
      receiver_table: [],
      receiver_type_list: [
        { id: 1, name: this.$t("summer.curriculum.teacher") },
        { id: 2, name: this.$t("summer.curriculum.student") },
      ],
      note_rule: [
        (value) =>
          value == undefined ||
          value.length <= 250 ||
          `${this.$t("globals.biggest_number_of_character")} 250`,
      ],
    };
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      user: (state) => state.User,
      current_year_name: (state) => state.current_year_name,
    }),
  },
  methods: {
    ...mapActions({
      getlevels: "summer/getLevels",
    }),
    async getStudentsTable() {
      if (this.receiver_type == 1 ) {
        let list = await this.axios.get(
          `${this.base_url}/api/summer/receive-curriculum-books/`,
          {
            params: {
              level: this.fk_level,
            },
          }
        );
        if (list.data) {
          let array = [];
          list.data.forEach((element) => {
            array.push({
              fk_level: element.fk_level,
              fk_employee: element.fk_employee,
              is_recived_books: element.is_recived_books,
              name: element.teacher_name,
              note: element.note,
              type: 1,
            });
          });
          this.receiver_table = array;
        }
      }
      if (this.receiver_type == 2) {
        this.dataLoading = true;
        let list = await this.axios.get(
          `${this.base_url}/api/summer/get-student-by-level-and-division`,
          {
            params: {
              level: this.fk_level,
              search_txt: this.txt_search,
              page: this.page,
              page_size: this.perPage,
            }
          }
        );
        this.dataLoading = false;
        if (list.data && list.data.results) {
          let array = [];
          list.data.results.forEach((element) => {
            array.push({
              fk_level: element.fk_level,
              id: element.fk_student,
              is_recived_books: element.is_recived_books,
              name: element.student_name,
              note: element.note,
              type: 2,
            });
          });
          this.receiver_table = array;
          this.pagination = list.data.pagination;
          this.length = this.pagination?.num_pages;
          this.itemsPerPage[4]["value"] = this.pagination?.count;
          this.totalItems = this.pagination?.count;
        }
      }
    },
    async saveRecords() {
      const { valid } = await this.$refs.form.validate();
       console.log(this.receiver_table,"aaaaaaaaaaaaaaaaaaaaaaaaaa");
      if (this.receiver_table.length > 0 && valid) {
        this.receiver_table.data_entry = this.user;
        this.saveProgress = true;
        await this.axios
          .post( `${this.base_url}/api/summer/receive-curriculum-books/`,this.receiver_table)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_saved"));
            this.getStudentsTable();
            this.saveProgress = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveProgress = false;
          });
      } else {
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      }
    },
    cleanMarks() {
      this.getStudentsTable();
    },
  },
  watch: {
    perPage() {
      this.page = 1;
      this.getStudentsTable();
    },
    page() {
      this.getStudentsTable(this.page);
    },
  },
};
</script>
<style scoped>
#pagination-bar * {
  font-size: 0.95rem;
}
</style>
