<template>
  <DataTable
    v-if="$checkPermissions('summer_center.view_parentsummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getParent"
    :create="
      () => {
        $router.push({ name: 'parent' })
      }
    "
    :pagination="pagination"
    :editItem="parentEdit"
    :delItem="deleteParent"
    :del_perm="
      $checkPermissions('summer_center.delete_parentsummer', [
        'sys_admin',
        'gen_admin',

        'summer_admin',
      ])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_parentsummer', [
        'sys_admin',
        'gen_admin',
        
        'summer_admin',
      ])
    "
    :add_perm="
      $checkPermissions('summer_center.add_parentsummer', [
        'sys_admin',
        'gen_admin',
        
        'summer_admin',
      ])
    "
  >
    <template v-slot:item.identity_image="{ item }">
      <button @click="showImage(item.raw.identity_image)" v-if="item.raw.identity_image">
        <v-avatar
          :image="item.raw.identity_image"
          class=""
          size="small"
        ></v-avatar>
      </button>
      <span v-else>
        {{ $t("summer.not-found-image") }}
      </span>
    </template>
  </DataTable>

  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">{{
          $t("globals.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import ExcelJS from "exceljs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { saveAs } from "file-saver";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  name: "ParentsList",

  data() {
    return {
      DataLoading: false,
      addParentDialog:false,
      addDialog: false,
      showImg_dialog: false,
      dialog_image: undefined,
      pagination: {},
      parents: [],
      loading: false,
    };
  },
  components: { DataTable },
  computed: {
    ...mapState({
      identify_type: (state) => state.summer_globals.identify_type,
      role: (state) => state.role,
    }),
    headers() {
      return [
        { title: this.$t("school.parent_name"), key: "name" },
        { title: this.$t("school.parent_id"), key: "identity_type" },
        { title: this.$t("school.parent_id_no"), key: "identity_number" },
        {
          title: this.$t("school.parent_id_image"),
          key: "identity_image",
          sortable: false,
        },
        { title: this.$t("school.parent_job"), key: "job" },
        { title: this.$t("school.parent_address"), key: "address" },
        { title: this.$t("school.parent_phone"), key: "phone_number" },
        { title: this.$t("school.parent_landline"), key: "home_number" },
        {
          title: this.$t("school.notes"),
          key: "note",
          sortable: false,
          show: false,
        },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
          show: true,
        },
      ];
    },
    tableList() {
      let list = [];
      this.parents.forEach((element) => {
        let identity = this.identify_type.find(
          (item) => item.id == element.identity_type
        );
        if (identity != undefined) identity = identity.name;
        list.push({
          name: element.name,
          identity_type: identity,
          identity_number: element.identity_number,
          identity_image: element.identity_image,
          job: element.job,
          address: element.address,
          phone_number: element.phone_number,
          home_number: element.home_number,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getParent();
      await this.getIdentifyType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getIdentifyType: "summer_globals/getIdentifyType",
    }),
  
    parentEdit(item) {
      this.$router.push(`parent/${item?.id}/edit/`);
    },
    selectImage(e) {
      this.parent.image = e;
    },
    showImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_image = value;
      else this.dialog_image = this.base_url + value;
      this.dialog_title = this.$t("school.parent_id_image");
      this.showImg_dialog = true;
    },
    async getParent(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      return await this.axios(`${this.base_url}/api/summer/parent/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      })
        .then((response) => {
          this.parents = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async deleteParent(delete_id) {
      if (delete_id) {
        var status ;
        this.del_loading = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/parent/${delete_id}/`)
          .then((response) => {
            status = true
          })
          .catch((error) => {
           status = error
          });
        return status
      }
    },
  },
  mounted() {
   
  },
  watch: {
    
  },
};
</script>
<style scoped>
#dataTable span {
  font-size: 12px;
}
</style>
