<template>
  <!-- <VLocaleProvider :rtl="$i18n.locale==='en'?false:true"> -->
      <VApp  >
        <router-view />
      </VApp>

  <!-- </VLocaleProvider> -->
</template>

<script>
import { mapGetters, mapState ,mapActions} from "vuex";
import tokenService from '@/assets/js/tokenService.js';
import inactivityTimer from '@/assets/js/inactivityTimer.js';
import {decryptString, encryptString} from '@/utils/index.js'

export default {
  async created(){
    const locale=localStorage.getItem('user-locale')
    if(locale=='en'){
      this.$store.dispatch("updatedirection", 'ltr');
      this.$store.dispatch("updatelanguage",'en');
      this.$i18n.locale="en"
      document.querySelector('.v-overlay-container')?.setAttribute('dir','ltr')
     
    }else{
      this.$store.dispatch("updatedirection", 'rtl');
      this.$store.dispatch("updatelanguage",'ar');
      this.$i18n.locale="ar"
      document.querySelector('.v-overlay-container')?.setAttribute('dir','rtl')
    }
    if(this.$store.state.isAuthenticated){
      this.getActiveCirculars()
    }
    
  },
  computed:{
    ...mapState({
      logout_alert:(state)=>state.logout_alert,
    })
  },
  
  methods:{
    ...mapActions(["logout"]),
    async getActiveCirculars() {
      this.axios(`${this.base_url}/api/summer/generalization/active-circulars/`).then((res) => {
        this.$store.commit('seetCirculars',res.data)
      });
    },
  },
  mounted(){
    if (this.$i18n.locale=='en'){
      document.title="Summer Centers System"
    }
    if (this.$store.state.isAuthenticated) {
      inactivityTimer.initialize();
    }
    
  },
  beforeDestroy() {
    inactivityTimer.destroy();
  },
}
</script>
<style >
.v-data-table-footer__items-per-page >span{
  display: none;
}

</style>


