import custom_axios from '@/plugins/axios';
import  host  from '../../main'

export default ({
    namespaced:true,
    state:{
        level_phase:[],
        study_mode:[],
        commitees_type_mode:[],

        commitees_show:[],

        manger_statistics_mode:[],
        statistics_mode:[],
        statistics_evaluation:[],
        managment_task:[],
        week:[],
        role:[],
        place_type:[],
        portability:[],
        school_type:[],
        drop_out_reason:[],
        true_fals:[],
        status_student:[],
        identify_type:[],
        marital_status:[],
        gender:[],
        shift_time:[],
        contact_type:[],
        evaluation:[],
        student_type:[],
        qulification:[],
        support_committees:[],
        study_type:[],
        school_student_type:[],
        employee_state:[],
        contacts_type:[],
        activity_iterations:[],
        accommodation_types:[],
    },
    getters:{
        getLevelPhaseName: (state) => (id) =>{
            let level_phase = state.level_phase.find((item)=> item.id=id)
            if(level_phase)
                return level_phase.name
        },
        getStudyModeName: (state) => (id) =>{
            let study_mode = state.study_mode.find((item)=> item.id=id)
            if(study_mode)
                return study_mode.name
        },
        getCommiteesTypeModeName: (state) => (id) =>{
            let commitees_type_mode = state.commitees_type_mode.find((item)=> item.id=id)
            if(commitees_type_mode)
                return commitees_type_mode.name
        },


        getUserRoleName: (state) => (id) =>{
            let commitees_show = state.commitees_show.find((item)=> item.id=id)
            if(commitees_show)
                return commitees_show.name
        },


        getMangerStatisticsModeName: (state) => (id) =>{
            let manger_statistics_mode = state.manger_statistics_mode.find((item)=> item.id=id)
            if(manger_statistics_mode)
                return manger_statistics_mode.name
        },
        getStatisticsModeName: (state) => (id) =>{
            let statistics_mode = state.statistics_mode.find((item)=> item.id=id)
            if(statistics_mode)
                return statistics_mode.name
        },
        getStatisticsEvaluationName: (state) => (id) =>{
            let statistics_evaluation = state.statistics_evaluation.find((item)=> item.id=id)
            if(statistics_evaluation)
                return statistics_evaluation.name
        },
        getManagmentTaskName: (state) => (id) =>{
            let managment_task = state.managment_task.find((item)=> item.id=id)
            if(managment_task)
                return managment_task.name
        },
        getWeekName: (state) => (id) =>{
            let week = state.week.find((item)=> item.id=id)
            if(week)
                return week.name
        },
        getRoleName: (state) => (id) =>{
            let role = state.role.find((item)=> item.id=id)
            if(role)
                return role.name
        },
        getPlaceTypeName: (state) => (id) =>{
            let place_type = state.place_type.find((item)=> item.id=id)
            if(place_type)
                return place_type.name
        },
        getPortabilityName: (state) => (id) =>{
            let portability = state.portability.find((item)=> item.id=id)
            if(portability)
                return portability.name
        },
        getSchoolTypeName: (state) => (id) =>{
            let school_type = state.school_type.find((item)=> item.id=id)
            if(school_type)
                return school_type.name
        },
        getDropOutReasonName: (state) => (id) =>{
            let drop_out_reason = state.drop_out_reason.find((item)=> item.id=id)
            if(drop_out_reason)
                return drop_out_reason.name
        },
        getTrueFalsName: (state) => (id) =>{
            let true_fals = state.true_fals.find((item)=> item.id=id)
            if(true_fals)
                return true_fals.name
        },
        getStatusStudentName: (state) => (id) =>{
            let status_student = state.status_student.find((item)=> item.id=id)
            if(status_student)
                return status_student.name
        },
        getIdentifyTypeName: (state) => (id) =>{
            let identify_type = state.identify_type.find((item)=> item.id=id)
            if(identify_type)
                return identify_type.name
        },
        getMaritalStatusName: (state) => (id) =>{
            let marital_status = state.marital_status.find((item)=> item.id=id)
            if(marital_status)
                return marital_status.name
        },
        getGenderName: (state) => (id) =>{
            let gender = state.gender.find((item)=> item.id=id)
            if(gender)
                return gender.name
        },
        getShiftTimeName: (state) => (id) =>{
            let shift_time = state.shift_time.find((item)=> item.id=id)
            if(shift_time)
                return shift_time.name
        },
        getContactTypeName: (state) => (id) =>{
            let contact_type = state.contact_type.find((item)=> item.id=id)
            if(contact_type)
                return contact_type.name
        },
        getEvaluationName: (state) => (id) =>{
            let evaluation = state.evaluation.find((item)=> item.id=id)
            if(evaluation)
                return evaluation.name
        },
        getStudentTypeName: (state) => (id) =>{
            let student_type = state.student_type.find((item)=> item.id=id)
            if(student_type)
                return student_type.name
        },
        getQulificationName: (state) => (id) =>{
            let qulification = state.qulification.find((item)=> item.id=id)
            if(qulification)
                return qulification.name
        },
        getSupportCommitteesName: (state) => (id) =>{
            let support_committees = state.support_committees.find((item)=> item.id=id)
            if(support_committees)
                return support_committees.name
        },
        getStudyType: (state) => (id) =>{
            let study_type = state.study_type.find((item)=> item.id=id)
            if(study_type)
                return study_type.name
        },
        getSchoolStudentType: (state) => (id) =>{
            let school_student_type = state.school_student_type.find((item)=> item.id=id)
            if(school_student_type)
                return school_student_type.name
        },
        getEmployeeState: (state) => (id) =>{
            let employee_state = state.employee_state.find((item)=> item.id=id)
            if(employee_state)
                return employee_state.name
        },
        getAccommodationTypeName: (state) => (id) =>{
            let accommodation_types = state.accommodation_types.find((item)=> item.id=id)
            if(accommodation_types)
                return accommodation_types.name
        },
    },
    actions:{
        async getLevelPhase({commit}){
            let level_phase=(await custom_axios.get(host()+'api/summer/choices/level-phase')).data
            return commit('getLevelPhase',level_phase)
        },
        async getStudyMode({commit}){
            let study_mode=(await custom_axios.get(host()+'api/summer/choices/study-mode')).data
            return commit('getStudyMode',study_mode)
        },
        async getCommiteesTypeMode({commit}){
            let commitees_type_mode=(await custom_axios.get(host()+'api/summer/choices/commitees-type-mode')).data
            return commit('getCommiteesTypeMode',commitees_type_mode)
        },

        async getUserRole({commit}){
            let commitees_show=(await custom_axios.get(host()+'user-committee-role')).data
            return commit('getUserRole',commitees_show)
        },
        async getMangerStatisticsMode({commit}){
            let manger_statistics_mode=(await custom_axios.get(host()+'api/summer/choices/manager-statistics-mode')).data
            return commit('getMangerStatisticsMode',manger_statistics_mode)
        },
        async getStatisticsMode({commit}){
            let statistics_mode=(await custom_axios.get(host()+'api/summer/choices/statistics-mode')).data
            return commit('getStatisticsMode',statistics_mode)
        },
        async getStatisticsEvaluation({commit}){
            let statistics_evaluation=(await custom_axios.get(host()+'api/summer/choices/statistics-evaluation')).data
            return commit('getStatisticsEvaluation',statistics_evaluation)
        },
        async getManagmentTask({commit}){
            let managment_task=(await custom_axios.get(host()+'api/summer/choices/managment-task')).data
            return commit('getManagmentTask',managment_task)
        },
        async getWeek({commit}){
            let week=(await custom_axios.get(host()+'api/summer/choices/week')).data
            return commit('getWeek',week)
        },
        async getRole({commit}){
            let role=(await custom_axios.get(host()+'api/summer/choices/role')).data
            return commit('getRole',role)
        },


        // async getUserRole({commit}){
        //     let role=(await custom_axios.get(host()+'user-role')).data
        //     return commit('getRole',role)
        // },



        async getPlaceType({commit}){
            let place_type=(await custom_axios.get(host()+'api/summer/choices/place-type')).data
            return commit('getPlaceType',place_type)
        },
        async getPortability({commit}){
            let portability=(await custom_axios.get(host()+'api/summer/choices/is-moveable')).data
            return commit('getPortability',portability)
        },
        async getSchoolType({commit}){
            let school_type=(await custom_axios.get(host()+'api/summer/choices/school-type')).data
            return commit('getSchoolType',school_type)
        },
        async getDropOutReason({commit}){
            let drop_out_reason=(await custom_axios.get(host()+'api/summer/choices/dropout-reason')).data
            return commit('getDropOutReason',drop_out_reason)
        },
        async getTrueFals({commit}){
            let true_fals=(await custom_axios.get(host()+'api/summer/choices/true-fals')).data
            return commit('getTrueFals',true_fals)
        },
        async getStatusStudent({commit}){
            let status_student=(await custom_axios.get(host()+'api/summer/choices/status-student')).data
            return commit('getStatusStudent',status_student)
        },
        async getIdentifyType({commit}){
            let identify_type=(await custom_axios.get(host()+'api/summer/choices/identify-type')).data
            return commit('getIdentifyType',identify_type)
        },
        async getMaritalStatus({commit}){
            let marital_status=(await custom_axios.get(host()+'api/summer/choices/marital-status')).data
            return commit('getMaritalStatus',marital_status)
        },
        async getGender({commit}){
         
            let gender=(await custom_axios.get(host()+'api/summer/choices/gender')).data
            return commit('getGender',gender)
        },
        async getShiftTime({commit}){
            let shift_time=(await custom_axios.get(host()+'api/summer/choices/shift-time')).data
            return commit('getShiftTime',shift_time)
        },
        async getContactType({commit}){
            let contact_type=(await custom_axios.get(host()+'api/summer/choices/contact-type')).data
            return commit('getContactType',contact_type)
        },
        async getEvaluation({commit}){
            let evaluation=(await custom_axios.get(host()+'api/summer/choices/evaluation')).data
            return commit('getEvaluation',evaluation)
        },
        async getStudentType({commit}){
            let student_type=(await custom_axios.get(host()+'api/summer/choices/student-type')).data
            return commit('getStudentType',student_type)
        },
        async getQulification({commit}){
            let qulification=(await custom_axios.get(host()+'api/summer/choices/qulification')).data
            return commit('getQulification',qulification)
        },
        async getSupportCommittees({commit}){
            let support_committees=(await custom_axios.get(host()+'api/summer/choices/support-committees')).data
            return commit('getSupportCommittees',support_committees)
        },
        async getStudyType({commit}){
            let study_type=(await custom_axios.get(host()+'api/summer/choices/study-type')).data
            return commit('getStudyType',study_type)
        },
        async getSchoolStudentType({commit}){
            let school_student_type=(await custom_axios.get(host()+'api/summer/choices/school-student-type')).data
            return commit('getSchoolStudentType',school_student_type)
        },
        async getEmployeeState({commit}){
            let employee_state=(await custom_axios.get(host()+'api/summer/choices/employee-state')).data
            return commit('getEmployeeState',employee_state)
        },
        async getContactTypes({commit}){
            let contacts_type=(await custom_axios.get(host()+'api/summer/choices/contact-type')).data
            return commit('getContactTypes',contacts_type)
        },
        async getActivityIterations({commit}){
            let activity_iterations=(await custom_axios.get(host()+'api/summer/choices/activity-iterations')).data
            return commit('getActivityIterations',activity_iterations)
        },
        async getAccommodationType({commit}){
            let accommodation_types=(await custom_axios.get(host()+'api/summer/choices/accommodation-type')).data
            return commit('getAccommodationType',accommodation_types)
        },
    },
    mutations:{
        getPermissionType(state,permission_type){
            state.permission_type=permission_type
        },
        getLevelPhase(state,level_phase){
            state.level_phase=level_phase
        },
        getStudyMode(state,study_mode){
            state.study_mode=study_mode
        },
        getCommiteesTypeMode(state,commitees_type_mode){
            state.commitees_type_mode=commitees_type_mode
        },

        getUserRole(state,commitees_show){
            state.commitees_show=commitees_show
        },


        getMangerStatisticsMode(state,manger_statistics_mode){
            state.manger_statistics_mode=manger_statistics_mode
        },
        getStatisticsMode(state,statistics_mode){
            state.statistics_mode=statistics_mode
        },
        getStatisticsEvaluation(state,statistics_evaluation){
            state.statistics_evaluation=statistics_evaluation
        },
        getManagmentTask(state,managment_task){
            state.managment_task=managment_task
        },
        getWeek(state,week){
            state.week=week
        },
        getRole(state,role){
            state.role=role
        },
        getPlaceType(state,place_type){
            state.place_type=place_type
        },
        getPortability(state,portability){
            state.portability=portability
        },
        getSchoolType(state,school_type){
            state.school_type=school_type
        },
        getDropOutReason(state,drop_out_reason){
            state.drop_out_reason=drop_out_reason
        },
        getTrueFals(state,true_fals){
            state.true_fals=true_fals
        },
        getStatusStudent(state,status_student){
            state.status_student=status_student
        },
        getIdentifyType(state,identify_type){
            state.identify_type=identify_type
        },
        getMaritalStatus(state,marital_status){
            state.marital_status=marital_status
        },
        getGender(state,gender){
            state.gender=gender
        },
        getShiftTime(state,shift_time){
            state.shift_time=shift_time
        },
        getContactType(state,contact_type){
            state.contact_type=contact_type
        },
        getEvaluation(state,evaluation){
            state.evaluation=evaluation
        },
        getStudentType(state,student_type){
            state.student_type=student_type
        },
        getQulification(state,qulification){
            state.qulification=qulification
        },
        getSupportCommittees(state,support_committees){
            state.support_committees=support_committees
        },
        getStudyType(state,study_type){
            state.study_type=study_type
        },
        getSchoolStudentType(state,school_student_type){
            state.school_student_type=school_student_type
        },
        getEmployeeState(state,employee_state){
            state.employee_state=employee_state
        },
        getContactTypes(state,contacts_type){
            state.contacts_type=contacts_type
        },
        getActivityIterations(state,activity_iterations){
            state.activity_iterations=activity_iterations
        },
        getAccommodationType(state,accommodation_types){
            state.accommodation_types=accommodation_types
        },
    }
    
})