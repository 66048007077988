<template>

  <DataTable
    v-if="$checkPermissions('globaltable.view_school')"
    :items="tableList"
    :headers="headers"
    density="compact"
    
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="$checkPermissions('globaltable.delete_school', ['gen_admin', 'sys_admin'])"
    :edit_perm="
      $checkPermissions('globaltable.change_school', ['gen_admin', 'sys_admin'])
    "
    :add_perm="$checkPermissions('globaltable.add_school', ['gen_admin', 'sys_admin'])"
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm ref="form">
          <VRow class="mt-2">
            <VCol cols="6">
              <VAutocomplete
                :items="countries"
                autofocus
                v-model="school.fk_country"
                prepend-inner-icon="mdi-flag"
                item-title="name_ar"
                item-value="id"
                :label="`${$t('school.country_name')} *`"
                persistent-hint
                density="compact"
                @update:modelValue="school.fk_governorate = undefined"
                :rules="rules.country"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <!-- v-model="school.fk_directorate" -->
              <VAutocomplete
                :items="filterGovernorates(school.fk_country)"
                v-model="school.fk_governorate"
                prepend-inner-icon="mdi-city"
                item-title="name_ar"
                :no-data-text="$t('globals.country-first')"
                item-value="id"
                :label="`${$t('school.governorate_name')} *`"
                persistent-hint
                density="compact"
                @update:modelValue="school.fk_directorate = undefined"
                :rules="rules.fk_governorate"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="filterDirectorates(school.fk_governorate)"
                prepend-inner-icon="mdi-city"
                v-model="school.fk_directorate"
                item-title="name_ar"
                :no-data-text="$t('globals.governorate-first')"
                item-value="id"
                :label="`${$t('school.directorate_name')} *`"
                persistent-hint
                density="compact"
                :rules="rules.fk_governorate"
              ></VAutocomplete>
            </VCol>


            <VCol cols="6">
                <!-- :items="filterVillages(school.fk_directorate)" -->
                <!-- :items="filterDirectorates(school.fk_governorate)" -->
              <VAutocomplete
                :items="filterVillages(school.fk_directorate)"

                prepend-inner-icon="mdi-city"
                v-model="school.fk_village"
                item-title="name_ar"
                :no-data-text="$t('globals.directorate-first')"
                item-value="id"
                :label="`${$t('school.village-name')} *`"
                persistent-hint
                density="compact"
                :rules="rules.fk_governorate"
              ></VAutocomplete>
            </VCol>


            <VCol cols="6">
              <VAutocomplete
                :items="school_type"

                prepend-inner-icon="mdi-school"
                v-model="school.school_type"
                item-title="name"
                item-value="id"
                :label="`${$t('school.school_type')} *`"
                persistent-hint
                density="compact"
                :rules="rules.school_type_rule"
              ></VAutocomplete>
            </VCol>


            <VCol cols="6">
              <VAutocomplete
                :items="student_type"
                v-model="school.student_type"

                prepend-inner-icon="mdi-school"
                item-title="name"
                item-value="id"
                :label="`${$t('school.students_type')} *`"
                persistent-hint
                density="compact"
                :rules="rules.students_type_rule"
              ></VAutocomplete>
            </VCol>




            <VCol cols="6">
              <VTextField
                v-model="school.name_ar"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="`${$t('school.school-name')} *`"
                :placeholder="$t('school.placeholder-school-name')"
                density="compact"
                :rules="rules.name_ar"
              >
              </VTextField>
            </VCol>
            <!-- <VCol  cols="6" >
                <VTextField
                  v-model="school.name_en"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.school-name-en')"
                  :placeholder="$t('school.placeholder-school-name-en')"
                  density="compact"
                  :rules="rules.name_en"
                >
                </VTextField>
            </VCol> -->
            <VCol cols="6">
              <VTextField
                v-model="school.code_number"
                maxLength="1000000"
                prepend-inner-icon="mdi-numeric"
                :label="`${$t('school.code-number')} *`"
                density="compact"
                :placeholder="$t('school.code-number')"
                :rules="rules.code_number"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="school.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                color="indigo"
                :label="$t('globals.note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                :rules="rules.note"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      protected_records: false,
      data_message: [],
      directorates: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      data_message: [],
      rules: {
      students_type_rule: [(value) => !!value || this.$t("globals.required_field")],
      school_type_rule: [(value) => !!value || this.$t("globals.required_field")],
      

        country: [(value) => !!value || this.$t("globals.required_field")],
        fk_governorate: [(value) => !!value || this.$t("globals.required_field")],
        name_ar: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        code_number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 7) ||
            `${this.$t("globals.biggest_number_of_character")} 1000000`,
          (value) => /^\d*$/.test(value) || this.$t("globals.must_be_numbers"),
        ],
        name_en: [
          (value) =>
            !value ||
            (value && /^[A-Za-z-\s]+$/.test(value)) ||
            this.$t("globals.must_be_letters_en"),
          (value) =>
            !value ||
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            !value ||
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      school: {},
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,

      headers: [
        { title: this.$t("school.school-name"), key: "name_ar" },

        { title: this.$t("school.governorate_name"), key: "governorate_name" },
        { title: this.$t("school.directorate_name"), key: "directorate_name" },
        { title: this.$t("school.village-name"), key: "village_name" },

        { title: this.$t("school.school_type"), key: "school_type_name" },
        { title: this.$t("school.students_type"), key: "student_type_name" },


        // { title: this.$t("school.school-name-en"), key: "name_en" },
        { title: this.$t("school.code-number"), key: "code_number" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {
    ...mapState({
      school_type: (state) => state.summer_globals.school_type,
      student_type: (state) => state.summer_globals.student_type,


      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      villages: (state) => state.school.villages,
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterVillages: "school/getVillageByDirectorates",
      
    }),
    tableList() {
      if (this.directorates) {
        return JSON.parse(JSON.stringify(this.directorates));
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getCountries();
      await this.getDirectorates();
      await this.getVillages();
      await this.getItems();
      await this.getStudentType();
      await this.getSchoolType();

      // await this.getItems();
      this.$store.commit("updateLoadingValue", false);
      this.school.fk_country = this.countries[0].id;
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorates: "school/getDirectorate",
      getVillages: "school/getVillages",
      getStudentType: "summer_globals/getStudentType",
      getSchoolType: "summer_globals/getSchoolType",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;

      this.school.fk_country = this.countries[0].id;
    },
    editItem(data) {
      this.school = Object.assign({}, data);
      this.is_update = true;
      this.addDialog = true;
      this.updated_id = data.id;

      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    async saveItem() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveDataLoading = true;
        this.school.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/school/", this.school)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate"));
            } else if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();

      if (valid && this.school.id != undefined) {
        this.saveDataLoading = true;
        this.school.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/school/${this.school.id}/`, this.school)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate"));
            } else if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        this.deleteDataLoading = true;
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/school/${delete_id}/`)
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });

        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/school-for-data-table/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.directorates = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style></style>
