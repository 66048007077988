<template>
    <!-- v-if="$checkPermissions('globaltable.view_coach')" -->
  <DataTable
    :items="items"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
  >
  </DataTable>
    <!-- :del_perm="$checkPermissions('globaltable.delete_coach',['sys_admin','gen_admin'])"
    :edit_perm="$checkPermissions('globaltable.change_coach',['sys_admin','gen_admin'])"
    :add_perm="$checkPermissions('globaltable.add_coach',['sys_admin','gen_admin'])" -->
   <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
   <v-card flat >
      <v-card-text class="pb-0">
        <VForm ref="form"  @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-2">
            <VCol cols="12">
                <VTextField
                  v-model="item.name_ar"
                  autofocus
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="`${$t('summer.coach-name')} *`"
                  density="compact"
                  clearable
                  :rules="coach_name_rule"
                >
                </VTextField>
            </VCol>

              <VCol cols="6">
                <VAutocomplete
                  :items="filterGovernorates(1)"
                  v-model="item.fk_governorate"
                  item-title="name_ar"
                  item-value="id"
                  :label="`${$t('globals.governorate')} *`"
                  persistent-hint
                  density="compact"
                  :rules="governorate"
                  prepend-inner-icon="mdi-city"
                  @update:modelValue="item.fk_directorate = null"
                  
                >
                </VAutocomplete>
              </VCol>
              <VCol cols="6">
                <VAutocomplete
                  :no-data-text="$t('globals.governorate-first')"
                  :items="filterDirectorates(item.fk_governorate)"
                  v-model="item.fk_directorate"
                  item-title="name_ar"
                  item-value="id"
                  :rules="directorate"
                  :label="`${$t('globals.directorate')} *`"
                  persistent-hint
                  density="compact"
                  prepend-inner-icon="mdi-map-outline"
                ></VAutocomplete>
              </VCol>
            

              <VCol cols="12" md="4">
                <VTextField
                  v-model="item.id_card"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('summer.coach-id-card')"
                  persistent-hint
                  density="compact"
                  clearable
                  type="number"
                  :rules=coach_id_rule
                ></VTextField>
              </VCol>
              <VCol cols="12" md="4">
                <VTextField
                  v-model="item.phone_number"
                  prepend-inner-icon="mdi-cellphone-text"
                  :label="$t('summer.member_phonenumber')"
                  density="compact"
                  clearable
                  type="number"
                  :rules="coach_phone_rule"
                ></VTextField>
              </VCol>

              <VCol cols="12" md="4">
                <VTextField
                  v-model="item.work_type"
                  prepend-inner-icon="mdi-account-hard-hat"
                  :label="`${$t('summer.coach-work-type')} *`"
                  density="compact"
                  clearable
                  :rules="coach_work_type_rule"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="4">
                <VTextField
                  v-model="item.work_place"
                  prepend-inner-icon="mdi-account-hard-hat"
                  :label="`${$t('summer.coach-work-place')} *`"
                  density="compact"
                  clearable
                  :rules="coach_work_place_rule"
                ></VTextField>
              </VCol>

              <VCol cols="12">
                <VTextarea
                  v-model="item.note"
                  prepend-inner-icon="mdi-note"
                  clearable
                  :label="$t('school.notes')"
                  :placeholder="$t('school.notes_placeholder')"
                  :rules="notes_rule"
                  counter
                  no-resize
                  density="compact"
                  rows="2"
                ></VTextarea>
              </VCol>


          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="mx-4"
      >
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  name: "CoachView",
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading: false,
      deleteDataLoading: false,
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      item: {},
      sortBy: [
        {
          key: "title",
          order: "asc",
        },
      ],


      coach_name_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      coach_id_rule: [

        (value) =>!value || /^[0-9]+$/.test(value) || this.$t("globals.must_be_numbers"),
        (value) =>
          !value ||
          (value && value.length <= 16) ||
          `${this.$t("globals.biggest_number_of_character")} 16`,
      ],
      coach_work_place_rule: [
        (value) => !!value || this.$t("globals.required_field"),
      ],
      coach_work_type_rule: [
        (value) => !!value || this.$t("globals.required_field"),
      ],

      coach_phone_rule: [

        (value) =>!value|| /^7\d*$/.test(value) || `${this.$t("globals.must-begin-with-7")}`,

        (value) =>
          !value ||
          (value && /^[0-9+]+$/.test(value)) ||
          this.$t("globals.must_be_numbers"),
        (value) =>
          !value ||
          (value && value.length <= 14) ||
          `${this.$t("globals.biggest_number_of_character")} 14`,
        (value) =>
          !value ||
          (value && value.length >= 9) ||
          `${this.$t("globals.lowest_number_of_character")} 9`,
      ],

      governorate: [(value) => !!value || this.$t("globals.required_field")],
      directorate: [(value) => !!value || this.$t("globals.required_field")],

      notes_rule: [
        (value) =>
          value == undefined ||
          value.length <= 250 ||
          `${this.$t("globals.biggest_number_of_character")} 250`,
      ],


      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.coach-name"), key: "name_ar" },
        { title: this.$t("globals.governorate"), key: "governorate_name" },
        { title: this.$t("globals.directorate"), key: "directorate_name" },
        { title: this.$t("summer.coach-id-card"), key: "id_card" },
        { title: this.$t("summer.member_phonenumber"), key: "phone_number" },
        { title: this.$t("summer.coach-work-type"), key: "work_type" },
        { title: this.$t("summer.coach-work-place"), key: "work_place" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      getGovernoratesByCountry: "school/getGovernoratesByCountry",
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();

      await this.getGovernorates();
      await this.getDirectorate();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
      ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),


    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    
    resetForm() {
      this.$refs.form.resetValidation();
      this.item = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(item) {
      this.item = Object.assign({},item);
      this.addDialog = true;
      this.is_update = true;
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.item.data_entry = this.user;
        let result = await this.axios.post(this.base_url + "api/coach/", this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.item = {};
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.resetForm();
          });
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.item.updated_by = this.user;
        let result = await this.axios.put(`${this.base_url}api/coach/${this.item.id}/`, this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status ;
        this.delete_id = delete_id;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/coach/${delete_id}/`, {
          })
          .then((response) => {
            status = true
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            status = error
            this.force_delete=false
            this.deleteDataLoading = false;
          });
          return status
        }
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/coach/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
