<template>
<DataTable
    v-if="$checkPermissions('globaltable.view_directorate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="$checkPermissions('globaltable.delete_directorate',['gen_admin','sys_admin'])"
    :edit_perm="$checkPermissions('globaltable.change_directorate',['gen_admin','sys_admin'])"
    :add_perm="$checkPermissions('globaltable.add_directorate',['gen_admin','sys_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
   <v-card flat>
      <v-card-text class="pb-0">
        <VForm ref="form">
          <VRow class="mt-2">
            <VCol  cols="6">
                <VAutocomplete
                  :items="countries"
                  autofocus
                  v-model="directorate.fk_country"
                  prepend-inner-icon="mdi-flag"
                  item-title="name_ar"
                  item-value="id"
                  :label="`${$t('school.country_name')} *`"                
                  persistent-hint
                  density="compact"
                  @update:modelValue="directorate.fk_governorate = undefined"
                  :rules="rules.country"
                ></VAutocomplete>
            </VCol>
            <VCol  cols="6" >
                <VAutocomplete
                  :items="filterGovernorates(directorate.fk_country)"
                  prepend-inner-icon="mdi-city"
                  v-model="directorate.fk_governorate"
                  item-title="name_ar"
                  :no-data-text="$t('globals.country-first')"

                  item-value="id"
                  :label="`${$t('school.governorate_name')} *`"                
                  persistent-hint
                  density="compact"
                  :rules="rules.fk_governorate"
                ></VAutocomplete>
            </VCol>
            <VCol  cols="6">
                <VTextField
                  v-model="directorate.name_ar"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="`${$t('school.directorate_name')} *`"                

                  :placeholder="$t('school.placeholder_directorate_name')"
                  density="compact"
                  :rules="rules.name_ar"
                >
                </VTextField>
            </VCol>
            <!-- <VCol  cols="6" >
                <VTextField
                  v-model="directorate.name_en"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.directorate_name_en')"
                  :placeholder="$t('school.placeholder_directorate_name_en')"
                  density="compact"
                  :rules="rules.name_en"
                >
                </VTextField>
            </VCol> -->
            <VCol  cols="6" >
                <VTextField
                  v-model="directorate.code_number"
                  maxLength="2"
                  prepend-inner-icon="mdi-numeric"
                  :label="`${$t('directorate.code-number')} *`"                
                
                  density="compact"
                  :placeholder="$t('directorate.code-number')"
                  :rules="rules.code_number"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" >
                <VTextarea
                  v-model="directorate.note"
                  prepend-inner-icon="mdi-note-outline"
                  clearable
                  color="indigo"
                  :label="$t('globals.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
export default {
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      protected_records: false,
      data_message: [],
      directorates: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      data_message: [],
      rules: {
        country: [(value) => !!value || this.$t("globals.required_field")],
        fk_governorate: [(value) => !!value || this.$t("globals.required_field")],
        name_ar: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        code_number:[
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>(value && value.length <= 2) || `${this.$t("globals.biggest_number_of_character")} 99`,
          (value) =>(/^\d*$/.test(value)) || this.$t('globals.must_be_numbers')
        ],
        name_en: [
          (value) =>
            !value || (value&&/^[A-Za-z-\s]+$/.test(value)) || this.$t("globals.must_be_letters_en"),
          (value) =>
            !value ||(value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            !value ||(value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      directorate: {},
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      
      headers: [
        { title: this.$t("school.country_name"), key: "country_name" },
        { title: this.$t("school.governorate_name"), key: "governorate_name" },
        { title: this.$t("school.directorate_name"), key: "name_ar" },
        // { title: this.$t("school.directorate_name_en"), key: "name_en" },
        { title: this.$t("directorate.code-number"), key: "code_number" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    tableList() {
      if (this.directorates) {
        return JSON.parse(JSON.stringify(this.directorates));
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getCountries();
      await this.getItems()
      // await this.getItems();
      this.$store.commit("updateLoadingValue", false);
      this.directorate.fk_country = this.countries[0].id;

    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;

      this.directorate.fk_country = this.countries[0].id;


    },
    editItem(data) {
      this.directorate = Object.assign({},data)
      this.is_update = true;
      this.addDialog = true;
      this.updated_id = data.id;
      
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  
    async saveItem() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveDataLoading = true;
        this.directorate.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/directorate/", this.directorate)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate"));
            } else if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();

      if (valid && this.directorate.id != undefined) {
        this.saveDataLoading = true;
        this.directorate.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/directorate/${this.directorate.id}/`, this.directorate)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate"));
            } else if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-directorate-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        this.deleteDataLoading = true;
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/directorate/${delete_id}/`)
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          });

        return status
      }
    },
    async getItems(page = 1,per_page = 10,ordering = 'id',search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/directorate-for-data-table/`, {
        params: {
          search: search,
          page: page,
          page_size:per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.directorates = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
<style>

</style>
