// import axios from 'axios';
import custom_axios from '@/plugins/axios';
import  host  from './../../main'

export default ({
    namespaced:true,
    state:{
        countries:[],
        governorates:[],
        directorate:[],

        villages:[],
        // host:'http://127.0.0.1:5000',
        school_data:undefined,
        building:[],
        hall:[],
        committee:[],
        current_page:1,
        items_count:5,
        // host:'http://127.0.0.1:7000/',
        // shehab:'http://127.0.0.1:5000/',
        // abod:'http://127.0.0.1:3000/',
        abood:'http://127.0.0.1:7700/',
        branch:[],
        contact:[],
        subjects:[],
        drivers:[],
        exam_period:[],
        classes:[],
        semesters:[],
        divisions:[],
        teachers:[],
        schedules:[],
        nationalities:[],
        discounts:[],
        assigned_teachers:[],
        academic_years:[],
        current_year:undefined,
        fees:[],
        fee_types:[],
        exam_period:[],
        yearsOfStudy:[],
        classDocuments:[],
        documents:[],
        activities:[],
        lectures_attendance:[],
        days_choices:[],
        schedules:[],
        homeworks:[],
        teacher_class:[],
        months:[],
        class_assign_hall:[],

        teacher_class:[],
        estimates:[],
        documents:[],
        buses:[],
        groups:[]
    },
    getters:{
        getGovernoratesByCountry:(state)=>(country)=>{
            let gov_list=[]
            // console.log(state.governorates,'sssssssssss');
            state.governorates.forEach(element => {
                if(element.fk_country==country)
                gov_list.push(element)
            });
            return gov_list;
        },
        getGovernorateName:(state)=>(gov_id)=>{
            return state.governorates?.find((gov) => gov.id == gov_id)?.name_ar;
        },
        getDirectorateName:(state)=>(gov_id)=>{
            return state.directorate?.find((gov) => gov.id == gov_id)?.name_ar;
        },
        getDirectorateByGovernorates:(state)=>(gov)=>{
            let dir_list=[]
            state.directorate.forEach(element => {
                if(element.fk_governorate==gov)
                dir_list.push(element)
            });
            return dir_list;
        },

        getVillageByDirectorates:(state)=>(dir)=>{
            let vill_list=[]
            state.villages.forEach(element => {
                if(element.fk_directorate==dir)
                vill_list.push(element)
            });
            return vill_list;
        },

        getSchoolByVillages:(state)=>(vill)=>{
            let sch_list=[]
            state.schools.forEach(element => {
                if(element.fk_village==vill)
                sch_list.push(element)
            });
            return sch_list;
        },


        getMonthBySemester:(state)=>(semester)=>{
            let month_list=[]
            state.months.forEach(element => {
                if(element.fk_semester!=undefined && element.fk_semester==semester)
                month_list.push(element)
            });
            return month_list;
        },
        getSubjectName:(state)=>(id)=>{
            let subject=state.subjects.find(item=>item.id==id)
            if(subject){
                return subject.name_ar
            }
        },
        
        getTeacherName:(state)=>(id)=>{
            let teacher=state.teachers.find(item=>item.id==id)
            if(teacher){
                return teacher.name_ar
            }
        },
        getClassName:(state)=>(id)=>{
            let class_data=state.classes.find(item=>item.id==id)
            if(class_data){
                return class_data.name_ar
            }
        },
        getSemesterName:(state)=>(id)=>{
            let semester=state.semesters.find(item=>item.id==id)
            if(semester){
                return semester.name_ar
            }
        },
        getMonthName:(state)=>(id)=>{
            let month=state.months.find(item=>item.id==id)
            if(month){
                return month.name
            }
        },
        getDivisionName:(state)=>(id)=>{
            let division=state.divisions.find(item=>item.id==id)
            if(division){
                return division.name
            }
        },
        getYearName:(state)=>(id)=>{
            let year=state.academic_years.find(item=>item.id==id)
            if(year){
                return `${year.year} - ${year.year_m}`
            }
        },
        getClassDivisions:(state,getters)=>(fk_class)=>{
            if(state.class_assign_hall.results){
                let divisions=state.class_assign_hall.results.filter(item=>item.fk_class==fk_class)
                if(divisions){
                    let divisions_data=[]
                    divisions.forEach(element=>{
                        divisions_data.push({id:element.fk_division,name:getters.getDivisionName(element.fk_division)})
                    })
                    return divisions_data
                }
            }
        },    
        getBuildingsHalls:(state,getters)=>(fk_building)=>{
            let list=state.hall.filter(item=>item.fk_building==fk_building)
            return list
        },    
        current_month:(state,actions)=>{
            actions.getMonths
            let current_month=state.months.find(item=>item.current_month==true)
            if(current_month){
                return current_month.id
            }
            return state.months[0].id
        },     
    }, 
    actions:{
        async getMonths({state,commit}){
            let months=(await custom_axios.get(host()+'api/month/')).data
            return commit('getMonths',months)
        },
        async getClassAssignHall({state,commit}){
            let class_assign_hall=(await custom_axios.get(host()+'/assign-hall/',
            {
                params:{
                    page_size:1000
                }
            })).data
            return commit('getClassAssignHall',class_assign_hall)
        },
        async getTeacherClass({state,commit}){
            let teacher_class=(await custom_axios.get(host()+'/assign-teacher/',
            {
                params:{
                    page_size:1000
                }
            })).data
            return commit('getTeacherClass',teacher_class)
        },
        async getLecturesAttendance({state,commit}){
            let lectures_attendance=(await custom_axios.get(host()+'/lecture-attendance/',
            {
                params:{
                    page_size:1000
                }
            })).data
            return commit('getLecturesAttendance',lectures_attendance)
        },
        async getHomeworks({state,commit}){
            let homeworks=(await custom_axios.get(state.shehab+'api/homework/',
            {
                params:{
                    page_size:1000
                }
            })).data
            return commit('getHomeworks',homeworks)
        },
        async getExamPeriods({state,commit}){
            let exam_period=(await custom_axios.get(host()+'examsPeriod/')).data
            return commit('getExamPeriods',exam_period)
        },
        async getAcademicYears({state,commit}){
            let academic_years=(await custom_axios.get(host()+'api/academic-years/')).data
            return commit('getAcademicYears',academic_years)
        },
        async getDivisions({state,commit},current_class=0){
            let divisions=(await custom_axios.get(host()+'api/divisions/?class='+current_class)).data
            return commit('getDivisions',divisions)
        },
        async getSemesters({state,commit}){
            let semesters=(await custom_axios.get(host()+'api/semesters/')).data
            return commit('getSemesters',semesters)
        },
        async getSubjects({state,commit},fk_class=0){
            let subjects=(await custom_axios.get(host()+'api/subjects/?class='+fk_class)).data
            return commit('getSubjects',subjects)
        },
        async getExamPeriods({state,commit}){
            let exam_period=(await custom_axios.get(host()+'examsPeriod/')).data
            return commit('getExamPeriods',exam_period)
        },
        async getAllMonths({state,commit}){
            let months=(await custom_axios.get(host()+'months/')).data
            
            return commit('getAllMonths',months)
        },
        /////this is repated
        async getSubjects({state,commit}){
            let subjects=(await custom_axios.get(host()+'api/subjects/')).data
        },
        async getLecturesAttendance({state,commit}){
            let lectures_attendance=(await custom_axios.get(host()+'api/lecture-attendance/')).data
            return commit('getLecturesAttendance',lectures_attendance)
        },
        
        async getSchedule({state,commit}){
            let schedule=(await custom_axios.get(state.abod+'/schedule/')).data
            return commit('getSchedule',schedule)
        },
        async getDaysChoices({state,commit}){
            let days=(await custom_axios.get(host()+'days-choices/')).data
            return commit('getDaysChoices',days)
        },
        async getAllTeachers({state,commit}){
            let teachers=(await custom_axios.get(host()+'api/teachers/',{
                params: {
                page:1,
                page_size:1000
            }
        })).data.results 
            return commit('getAllTeachers',teachers)
        },
        async getAllDrivers({state,commit}){
            let drivers=(await custom_axios.get(host()+'/driver/',{
                params: {
                    page:1,
                    page_size:1000
                }
            })).data.results 
            return commit('getAllDrivers',drivers)
        },
        async getActivities({state,commit}){
            let activities=(await custom_axios.get(host()+'api/activities/')).data
            return commit('getActivities',activities)
        },
        
        async getClassDocuments({state,commit}){
            let classDocuments=(await custom_axios.get(host()+'api/class-documents/')).data
            return commit('getClassDocuments',classDocuments)
        },
        async getDocuments({state,commit}){
            let documents=(await custom_axios.get(host()+'api/documents/')).data
            return commit('getDocuments',documents)
        },
        async getYearsOfStudy({state,commit}){
            let yearsOfStudy=(await custom_axios.get(host()+'api/academic-years/')).data
            return commit('getYearsOfStudy',yearsOfStudy)
        },
        
        async getSemesters({state,commit}){
            let semesters=(await custom_axios.get(host()+'api/semesters/')).data
            return commit('getSemesters',semesters)
        },
        async getClasses({state,commit}){
            let classes=(await custom_axios.get(host()+'api/classes/')).data
            return commit('getClasses',classes)
        },
        async getSubjects({state,commit}){
            let subjects=(await custom_axios.get(host()+'api/subjects/')).data
            return commit('getSubjects',subjects)
        },
        async getCountries({state,commit}){
            let countries=(await custom_axios.get(host()+'/country/')).data
            return commit('getCountries',countries)
        },
        async getGovernorates({state,commit},country=0){
            let governorates=(await custom_axios.get(host()+'/governorate/?country='+country)).data
            return commit('getGovernorates',governorates)
        },
        async getDirectorate({state,commit},governorate=0){
            let directorate=(await custom_axios.get(host()+'/directorate/?gov='+governorate)).data
            return commit('getDirectorate',directorate)
        },

        async getVillages({state,commit},directorate=0){
            let villages=(await custom_axios.get(host()+'/village/select/?dir='+directorate)).data
            return commit('getVillages',villages)
        },
        
        async getSchools({state,commit},village=0){
            let schools=(await custom_axios.get(host()+'/school/select/?vill='+village)).data
            return commit('getSchools',schools)
        },
        

        async getSchoolData({state,commit}){
            let school_data=(await custom_axios.get(host()+'/school-data/',
            )).data
            return commit('getSchoolData',school_data)
        },
        async getBrenches({state,commit},is_main=0){
            let branch=(await custom_axios.get(host()+'/branches/?is_main='+is_main)).data
            return commit('getBrenches',branch)
        },
        async getContact({state,commit},branch=0){
            let contact=(await custom_axios.get(host()+'/contacts/?branch='+branch)).data
            return commit('getContact',contact)
        },
        async getBuilding({state,commit},branch=0){
            let building=(await custom_axios.get(host()+'/building/?branch='+branch)).data
            return commit('getBuilding',building)
        },
        async getAllHalls({state,commit},building=0){
            let hall=(await custom_axios.get(host()+'/hall/?building='+building,{
                params: {
                    page:1,
                    page_size:1000
                }
            })).data.results
            return commit('getAllHalls',hall)
        },
        async getAllCommittees({state,commit},hall=0){
            let committee=(await custom_axios.get(host()+'/committees/?hall='+hall,{
                params: {
                    page:1,
                    page_size:1000
                }
            })).data.results
            return commit('getAllCommittees',committee)
        },
        async getCommittee({state,commit},hall=0){
            let committee=(await custom_axios.get(host()+'/committees/?hall='+hall)).data
            return commit('getCommittee',committee)
        },
        changeCurrentPage({commit},payload){
            return commit('changeCurrentPage',payload)
        },
        async getSchedule({state,commit}){
            let schedule=(await custom_axios.get(host()+'/schedule/')).data
            return commit('getSchedule',schedule)
        },
        async getAllTeachers({state,commit}){
            let teachers=(await custom_axios.get(host()+'api/teachers/',
            {params: {
                page:1,
                page_size:1000
            }})).data.results 
            
            return commit('getAllTeachers',teachers)
        },
        async getNationalities({state,commit}){
            let nationalities=(await custom_axios.get(host()+'/nationalities/')).data
            return commit('getNationalities',nationalities)
        },
        async getDiscount({state,commit}){
            let discounts=(await custom_axios.get(host()+'/discount/',
            )).data
            return commit('getDiscount',discounts)
        },
        async getAssignedTeachers({commit}){
            let assigned_teachers=(await custom_axios.get(host()+'assign-teacher/',
            )).data
            return commit('getAssignedTeachers',assigned_teachers)
        },
        async getFees({commit}){
            let fees=(await custom_axios.get(host()+'fees/',
            )).data
            return commit('getFees',fees)
        },
        async getFeeTypes({commit}){
            let fee_types=(await custom_axios.get(host()+'fee-types/',
            )).data
            return commit('getFeeTypes',fee_types)
            
        },
        
        async getAllEstimations({commit}){
            let estimates=(await custom_axios.get(host()+'estimate/',
            )).data
            return commit('getAllEstimations',estimates)   
        },

        async getAllDocuments({commit}){
            let documents=(await custom_axios.get(host()+'document/',
            )).data
            return commit('getAllDocuments',documents)
            
        },
        async getAllBuses({state,commit}){
            let buses=(await custom_axios.get(host()+'/bus/',
            {params: {
                page:1,
                page_size:1000
            }})).data.results 
            return commit('getAllBuses',buses)
        },
        async getAllGroups({state,commit}){
            let groups=(await custom_axios.get(host()+'groups/',
            )).data
            return commit('getAllGroups',groups)
        },
    },
    mutations:{
        getClassAssignHall(state,class_assign_hall){
            state.class_assign_hall=class_assign_hall
        },
        getMonths(state,months){
            state.months=months
        },
        getLecturesAttendance(state,lectures_attendance){
            state.lectures_attendance=lectures_attendance
        },
        getTeacherClass(state,teacher_class){
            state.teacher_class=teacher_class
        },
        getHomeworks(state,homeworks){
            state.homeworks=homeworks.results
        },
        getAcademicYears(state,academic_years){
            state.academic_years=academic_years
            let year=academic_years.find(item=>item.curentyear==true)
            if(year!=undefined)
                state.current_year=year.id
        },
        getSchedule(state,schedule){
            state.schedules=schedule
        },
        getDaysChoices(state,days){
            state.days_choices=days
        },
        getAllTeachers(state,teachers){
            state.teachers=teachers
        },
        getAllDrivers(state,drivers){
            state.drivers=drivers
        },
        getActivities(state,activities){
            state.activities=activities
        },
        
        getClassDocuments(state,classDocuments){
            state.classDocuments=classDocuments
        },
        getDocuments(state,documents){
            state.documents=documents
        },
        getYearsOfStudy(state,yearsOfStudy){
            state.yearsOfStudy=yearsOfStudy
        },
        getDivisions(state,divisions){
            state.divisions=divisions
        },
        getSemesters(state,semesters){
            state.semesters=semesters
        },
        getClasses(state,classes){
            state.classes=classes
        },
        getSubjects(state,subjects){
            state.subjects=subjects
        },
        getCountries(state,countries){
            state.countries=countries
        },
        getGovernorates(state,governorates){
            state.governorates=governorates
        },
        getDirectorate(state,directorate){
            state.directorate=directorate
        },

        getVillages(state,villages){
            state.villages=villages
        },

        getSchools(state,schools){
            state.schools=schools
        },

        getSchoolData(state,school_data){
            state.school_data=school_data
        },
        getBrenches(state,branch){
            state.branch=branch
        },
        getContact(state,contact){
            state.contact=contact
        },    
        getBuilding(state,building){
            state.building=building
        },    
        getAllHalls(state,hall){
            state.hall=hall
        },
        getAllCommittees(state,committee){
            state.committee=committee
        },
        getCommittee(state,committee){
            state.committee=committee
        },
        changeCurrentPage(state,payload){
            state.current_page=payload.page
            state.items_count=payload.count
        },
        getSchedule(state,schedule){
            state.schedules=schedule
        },
        getAllTeachers(state,teachers){
            state.teachers=teachers
        },
        getNationalities(state,nationalities){
            state.nationalities=nationalities
        },
        getDiscount(state,discounts){
            state.discounts=discounts
        },
        getAssignedTeachers(state,assigned_teachers){
            state.assigned_teachers=assigned_teachers
        },
        getFees(state,fees){
            state.fees=fees
        },
        getFeeTypes(state,fee_types){
            state.fee_types=fee_types
        },
        getExamPeriods(state,exam_period){
            state.exam_period=exam_period
        },
        getAllMonths(state,months){
            state.months=months
        
        },
        getAllEstimations(state,estimates){
            state.estimates=estimates
        },
        getAllDocuments(state,documents){
            state.documents=documents
        },
        getAllBuses(state,buses){
            state.buses=buses
        },
        getAllGroups(state,groups){
            state.groups=groups
        },
    }

})
