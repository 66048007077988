<template>
  <DataTable
    v-if="$checkPermissions('summer_center.view_mobileversion')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_mobileversion', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_mobileversion', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      $checkPermissions('summer_center.add_mobileversion', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
  <template v-slot:item.is_active="{item}">
    <v-icon v-if="item.raw.is_active" color="green-darken-2">mdi-check-circle</v-icon>
    <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
  </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol  cols="12" md="5" >
                <VTextField
                  v-model="mobile_version.version"
                  autofocus
                  :label="`${$t('mobile.version-name')} *`"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-cellphone-cog"
                  :error-messages="v$.mobile_version.version.$errors.map((e) => e.$message)"
                  @input="v$.mobile_version.version.$touch"
                ></VTextField>
            </VCol>
            <VCol  cols="8" md="5">
              <DatetimePicker  
                v-model="mobile_version.upload_date"
                :placeholder="`${$t('globals.upload-date')} *`"
                :label="`${$t('globals.upload-date')} *`"
                :error-messages="v$.mobile_version.upload_date.$errors.map((e) => e.$message)"
                @input="v$.mobile_version.upload_date.$touch"
              ></DatetimePicker>
            </VCol>
            <VCol  cols="4" md="2" >
                <VCheckbox
                  v-model="mobile_version.is_active"
                  :label="$t('globals.active')"
                  persistent-hint
                  density="compact"
                ></VCheckbox>
            </VCol>
            <VCol  cols="12" >
                <VTextarea
                  v-model="mobile_version.features"
                  clearable
                  :label="`${$t('globals.app-features')} *`"
                  no-resize
                  counter="1000"
                  density="compact"
                  rows="2"
                  :error-messages="v$.mobile_version.features.$errors.map((e) => e.$message)"
                  @input="v$.mobile_version.features.$touch"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
            <VCol  cols="12" >
                <VTextarea
                  v-model="mobile_version.note"
                  clearable
                  :label="$t('globals.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :error-messages="v$.mobile_version.note.$errors.map((e) => e.$message)"
                  @input="v$.mobile_version.note.$touch"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState} from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers, maxLength, minLength,email } from "@vuelidate/validators";
import DataTable from "@/components/Globals/DataTable.vue";


export default {
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading:false,
      items: [],
      dialogVisible: false,
      delete_id:null,
      v$: useValidate(),
      mobile_version:{
        version:null,
        is_active:false,
        features:null,
        app:null,
        upload_date:null,
        note:null
      },
      is_update:false,
      selectedHead: ["version", "upload_date","is_active", this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null],
      headers: [
        { title: this.$t("mobile.version-name"), key: "version" },
        { title: this.$t("globals.upload-date"), key: "upload_date" },
        { title: this.$t("globals.active"), key: "is_active" },
        { title: this.$t("globals.app-features"), key: "features" },
        { title: this.$t("globals.note"), key: "note", sortable: false,show:false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
      perPage: 10,
      length: 0,
      page: 1,
      pagination: {},
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      txt_search: null,
    };
  },
  computed: {
        ...mapState({
      user: (state) => state.User
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems()

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.mobile_version.is_active=false
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(mobile_version) {
      this.mobile_version = Object.assign({},mobile_version);
      this.addDialog = true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveItem() {
        this.v$.$validate();
        if(!this.v$.$error){
          this.mobile_version.data_entry = this.user;
              this.saveDataLoading = true;
            let result = await this.axios
            .post(this.base_url + "api/summer/mobile-version/",this.mobile_version)
            .then((response) => {
                this.$emit("successAlert", this.$t("globals.data_added"));
                this.saveDataLoading = false;
                this.resetForm();
                this.getItems();
            })
            .catch((error) => {
                this.saveDataLoading = false;
                if (error.response.data.title)
                this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
                else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            });
            
        }
    },
    async updateItem() {
      this.v$.$validate()
      if (!this.v$.$error&&this.mobile_version?.id) {
        this.mobile_version.updated_by = this.user;
        this.editDataLoading = true;
        let result = await this.axios
          .put(`${this.base_url}api/summer/mobile-version/${this.mobile_version?.id}/`,this.mobile_version)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
             this.getItems();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.title)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
       
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/mobile-version/${delete_id}/`)
          .then((response) =>status=true)
          .catch((error) => status = error);
        return status  
      }
    },
    async getItems(page=1,per_page=10,ordering='id',search=null) {
      await this.axios(`${this.base_url}/api/summer/mobile-version/`, {
        params:{
          search:search,
          page:page,
          page_size:per_page,
          ordering:ordering
        }
      }).then((response) => {
        this.items = response.data?.results;
        this.pagination = response.data.pagination;
      });
    }, 
    
  },
  validations(){
    return{
        mobile_version:{
            version:{
                maxLength: helpers.withMessage(this.$t("errors.max-entry"),maxLength(100)),
                required: helpers.withMessage(this.$t("errors.required"), required),
            },
            features:{
              maxLength: helpers.withMessage(this.$t("errors.max-entry"),maxLength(1000)),
              required: helpers.withMessage(this.$t("errors.required"), required),
            },
            upload_date:{
              maxLength: helpers.withMessage(this.$t("errors.max-entry"),maxLength(10)),
              required: helpers.withMessage(this.$t("errors.required"), required),
            },
            note:{
                maxLength: helpers.withMessage(this.$t("errors.max-entry"),maxLength(250)),
            }
        }
    }
  },
  watch:{
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  }
  
};
</script>

<style scoped>

</style>
