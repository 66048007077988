<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <v-col cols="12" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_level"
              density="compact"
              item-title="level_with_phase_name"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              clearable
              :items="levels"
              hide-details="auto"
              :label="$t('summer.level.name_ar')"
              @update:modelValue="(fk_subject = null), getLevelSubjects(fk_level)"
            />
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_subject"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              hide-details="auto"
              :items="level_subjects"
              :label="$t('subject.select')"
            />
          </v-col>
          <v-col cols="6" md="1" class="mt-2">
            <v-btn
              @click="getItems((page = 1)), (DataLoading = true)"
              :loading="DataLoading"
              class="bg-indigo"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <DataTable
    v-if="$checkPermissions('summer_center.view_lecturesummer')"
    :create="
      () => {
        addDialog = true;
      }
    "
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_lecturesummer', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_lecturesummer', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      $checkPermissions('summer_center.add_lecturesummer', ['sys_admin', 'gen_admin'])
    "
  >
    <template v-slot:item.fk_subject="{ item }">
      {{ getSubjectName(item.raw.fk_subject) }}
    </template>
    <template v-slot:item.fk_level="{ item }">
      {{ getLevelName(item.raw.fk_level) }}
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <v-form ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                color="indigo"
                v-model="lecture_attendance.name"
                counter="100"
                autofocus
                prepend-inner-icon="mdi-format-text"
                :label="`${$t('lecture.name')} *`"
                :error-messages="
                  v$.lecture_attendance.name.$errors.map((e) => e.$message)
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                color="indigo"
                v-model="lecture_attendance.fk_level"
                :item-title="classesName"
                item-value="id"
                prepend-inner-icon="mdi-stairs-box"
                :items="levels"
                :label="`${$t('summer.level.name_ar')} *`"
                :error-messages="
                  v$.lecture_attendance.fk_level.$errors.map((e) => e.$message)
                "
                @update:modelValue="
                  (lecture_attendance.fk_subject = null),
                    getLevelSubjects(lecture_attendance.fk_level)
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                color="indigo"
                v-model="lecture_attendance.fk_subject"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-bookshelf"
                :items="level_subjects"
                :label="`${$t('subject.select')} *`"
                :error-messages="
                  v$.lecture_attendance.fk_subject.$errors.map((e) => e.$message)
                "
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                color="indigo"
                v-model="lecture_attendance.note"
                hide-details="auto"
                counter="250"
                rows="3"
                prepend-inner-icon="mdi-note-outline"
                :label="$t('globals.note')"
                :error-messages="
                  v$.lecture_attendance.note.$errors.map((e) => e.$message)
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="editDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>

        <!-- <VBtn class="mx-3" size="small" @click="cancelForm()">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-cancel" color="red" end></VIcon>
        </VBtn> -->
      </VCardActions>
    </v-card>
  </v-dialog>
  <!-- v-if="$checkPermissions('summer_center.view_lecturesummer')" -->
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  name: "LecturesList",
  components: {
    DataTable,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      console.log(this.user);
      console.log(this.$store.getters.getuser);

      await this.getSubjects();
      await this.getLevels();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);

      // const response = await this.axios(
      //   `${this.base_url}api/summer/lecture-by-subject-and-level`,
      //   {
      //     params: {
      //       search: search,
      //       page: page,
      //       page_size: per_page,
      //       level: this.fk_level,
      //       subject: this.fk_subject,
      //       sort_by: ordering,
      //     },
      //   }
      // );
      // this.lecture_attendance= response.data;
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      addDialog: false,
      saveDataLoading: false,
      editDataLoading: false,
      level_subjects: [],
      v$: useValidate(),
      addDialog: false,
      subjects: [],
      DataLoading: false,
      loading: false,
      user: null,
      sortBy: [{ key: "name", order: "asc" }],
      selected_lecture_attendance: null,
      fk_level: null,
      fk_subject: null,
      fk_empoylee: null,
      lecture_attendance: {
        name: "",
        note: "",
        fk_level: undefined,
        fk_subject: undefined,

        data_entry: undefined,
        updated_by: undefined,
        fk_year: this.current_year,
      },
      from_date: null,
      lectures_attendance: [],
      to_date: null,
      rules: {
        level: [(value) => !!value || this.$t("globals.required_field")],
      },
      selectedHead: [
        "name",
        "fk_level",
        "fk_subject",
        this.checkrole(["sys_admin", "gen_admin"]) ? "actions" : null,
      ],
      headers: [
        { title: this.$t("lecture.name"), key: "name" },
        { title: this.$t("summer.level.name_ar"), key: "fk_level" },

        { title: this.$t("subject.name"), key: "fk_subject" },

        { title: this.$t("globals.note"), key: "note", show: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      totalItems: null,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      page: 1,

      pagination: {},
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.summer.teachers,
      levels: (state) => state.summer.levels?.results,
      teacher_class: (state) => state.school.teacher_class,
      levels: (state) => state.summer.levels,
      current_year: (state) => state.current_year,
    }),
    classesName() {
      return this.$i18n.locale == "ar"
        ? "level_with_phase_name"
        : "level_with_phase_name";
    },
    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
    tableList() {
      var list = JSON.parse(JSON.stringify(this.lectures_attendance));
      return list;
    },
    class_teachers() {
      var teacher_data = this.teachers;
      if (this.fk_level) {
        var teacher_id = this.teacher_class.results
          .filter((item) => item.fk_level == this.fk_level)
          .map((item) => item.fk_empoylee);
        var teacher_unique = [...new Set(teacher_id)];
        teacher_data = [];
        teacher_unique.forEach((el) => {
          teacher_data.push({ id: el, name_ar: this.getTeacherName(el) });
        });
      }
      return teacher_data;
    },
  },

  validations() {
    return {
      lecture_attendance: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
        },
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },

        data_entry: {},
        updated_by: {},
      },
    };
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    getLevelSubjects(level) {
      try {
        const subjects = this.levels?.find((obj) => obj.id == level)?.subject;
        this.level_subjects = this.subjects
          .filter((sub) => subjects.includes(sub.id))
          ?.map((sub) => {
            return { id: sub.id, name: sub.name };
          });
      } catch {
        this.level_subjects = [];
      }
    },
    async getSubjects() {
      await this.axios(`${this.base_url}/api/summer/subject`).then((response) => {
        this.subjects = response.data?.results;
      });
    },
    resetForm() {
      this.is_update = false;
      this.v$.lecture_attendance.$reset();
      this.$refs.form.reset();
    },
    ...mapActions({
      getLevels: "summer/getLevels",
    }),
    submitLectureAttendance() {
      this.v$.$validate();
    },
    async saveItem() {
      this.submitLectureAttendance();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.lecture_attendance.data_entry = this.user;
        await this.axios
          .post(`${this.base_url}api/summer/lecture-summer`, this.lecture_attendance)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.cancelForm();
            this.saveDataLoading = false;

            this.getItems();
            this.lecture_attendance = response.data;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            this.saveDataLoading = false;
          });
      }
    },
    getLevelName(id) {
      const level = this.levels?.find((level) => level.id == id);
      return level ? level?.name_ar : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item ? item.name : "";
    },
    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name_ar : item.name_ar) : "";
    },

    editItem(item) {
      this.lecture_attendance = Object.assign({}, item);
      if (this.lecture_attendance.fk_level) {
        this.getLevelSubjects(this.lecture_attendance.fk_level);
      }
      this.addDialog = true;
      this.is_update = true;
    },
    async updateItem() {
      this.submitLectureAttendance();
      if (!this.v$.$error) {
        this.editDataLoading = true;
        this.lecture_attendance.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}api/summer/lecture-summer/${this.lecture_attendance.id}`,
            this.lecture_attendance
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.editDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.update"));
            this.editDataLoading = false;
          });
      }
    },

    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}api/summer/lecture-summer/${delete_id}`)
          .then((response) => (status = true))
          .catch((error) => (status = error));
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/lecture-by-subject-and-level`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          level: this.fk_level,
          subject: this.fk_subject,
          sort_by: ordering,
        },
      })
        .then((response) => {
          this.lectures_attendance = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          this.loading = false;
          // this.DataLoading = false;
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
          // setTimeout(() => {
          //   this.showAlert = false;
          // }, 1500);
        });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
/* #vDataTable span {
  font-size: 12px;
} */
</style>
