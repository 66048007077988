<template>
  <v-container class="ma-auto">
    <v-locale-provider :locale="locale">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" lg="8" md="10" xl="6">
          <v-card elevation="2" class="mt-10 rounded-xl">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-form
                      ref="form"
                      @keydown.enter="loginAction(username, password, academic_year)"
                    >
                      <v-card-text class="my-16" :class="{ 'mt-6 mb-6': breakPointXS }">
                        <h2
                          class="text-center text-h4"
                          style="font-family: 'Almarai' !important"
                        >
                          {{ $t("globals.login") }}
                        </h2>
                        <br />
                        <h4 class="text-center grey--text">
                          {{ $t("globals.login-account-summer") }}
                        </h4>
                        <v-row align="center" justify="center" class="mb-4 pb-2">
                          <v-col cols="12" sm="10">
                            <v-text-field
                              autofocus
                              v-model="username"
                              :label="$t('globals.username')"
                              :placeholder="$t('globals.username')"
                              dense
                              variant="outlined"
                              class="mt-10"
                              aria-autocomplete="off"
                              clearable
                              :disabled="ban_duration > 0"
                              @input="error ? (error = null) : null"
                              :error-messages="v$.username.$errors.map((e) => e.$message)"
                            >
                              <!-- :disabled="timerCount>0" -->
                              <template v-slot:prepend-inner>
                                <v-icon color="indigo">mdi-account</v-icon>
                              </template>
                            </v-text-field>
                            <!-- :disabled="timerCount>0" -->
                            <v-text-field
                              v-model="password"
                              :type="showPassword ? 'text' : 'password'"
                              variant="outlined"
                              color="blue"
                              clearable
                              :disabled="ban_duration > 0"
                              name="password"
                              id="password"
                              @input="error ? (error = null) : null"
                              autocomplete="new-password"
                              :placeholder="$t('globals.placeholder_password')"
                              dense
                              :label="$t('globals.password')"
                              :error-messages="v$.password.$errors.map((e) => e.$message)"
                            >
                              <template v-slot:prepend-inner>
                                <v-icon
                                  color="indigo"
                                  @click="showPassword = !showPassword"
                                  >{{ showPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                                >
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-locale-provider
                            locale="en"
                            :dir="locale == 'ar' ? 'rtl' : 'ltr'"
                          >
                            <v-col cols="12" sm="10">
                              <v-autocomplete
                                variant="outlined"
                                v-model="academic_year"
                                :items="years"
                                item-value="id"
                                item-title="name"
                                prepend-inner-icon="mdi-calendar"
                                label="اختر السنة الدراسية"
                                :disabled="ban_duration > 0"
                                @input="error ? (error = null) : null"
                                :error-messages="
                                  v$.academic_year.$errors.map((e) => e.$message)
                                "
                              >
                              </v-autocomplete>
                            </v-col>
                          </v-locale-provider>
                          <v-col cols="12" sm="10">
                            <v-row dense>
                              <!-- <v-col cols="12" sm="7">
                                <v-checkbox
                                  :label="$t('globals.remeber-me')"
                                  class="mt-n1"
                                  density="compact"
                                  hide-details=""
                                  :disabled="ban_duration>0"
                                ></v-checkbox>
                              </v-col> -->
                              <!-- <v-col cols="12" >
                                <captcha-login  ref="captcha"></captcha-login>
                              </v-col> -->
                              <v-col v-if="captchaRequired">
                                <!-- CAPTCHA Section (Only visible when required) -->
                                <div v-if="captchaRequired" class="d-flex align-center">
                                  <v-img
                                    :src="captchaImage"
                                    class="border rounded"
                                    style="height: 50px"
                                    alt="Captcha"
                                  ></v-img>
                                  <dir title="صورة اخرى">
                                    <v-icon
                                      @click="
                                        refreshCaptcha(), this.$refs.captchaInput.focus()
                                      "
                                      size="30"
                                      color="grey"
                                      class="border rounded ms-1"
                                      >mdi-refresh</v-icon
                                    >
                                  </dir>
                                </div>
                                <v-text-field
                                  ref="captchaInput"
                                  class="mt-2"
                                  v-model="captchaInput"
                                  @input="
                                    () => {
                                      captchaInput = captchaInput.toUpperCase();
                                    }
                                  "
                                  :placeholder="$t('globals.captcha-placeholder')"
                                />
                              </v-col>
                            </v-row>
                            <small class="text-error text-center my-2 d-block">
                              {{ error }}
                              <count-down-timer
                                @reset-timer="handelResetTimer"
                                v-if="ban_duration"
                                :banDuration="ban_duration"
                              ></count-down-timer>
                            </small>
                            <v-btn
                              :loading="loading"
                              tile
                              color="blue"
                              dark
                              block
                              :disabled="ban_duration > 0"
                              @click.prevent="
                                loginAction(username, password, academic_year)
                              "
                            >
                              {{ $t("globals.enter") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-form>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center justify-center bg-blue"
                    :style="
                      locale == 'ar'
                        ? 'border-radius: 0 25% 25% 0;'
                        : 'border-radius: 25% 0 0 25%;'
                    "
                  >
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-locale-provider>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import tokenService from "@/assets/js/tokenService.js";
import inactivityTimer from "@/assets/js/inactivityTimer.js";
import { mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import host from "./../../main";
import moment from "moment-hijri";
import CountDownTimer from "@/components/Globals/CountDownTimer.vue";
import CaptchaLogin from "@/components/Globals/CaptchaLogin.vue";

// import store from './store'
// ;
export default {
  components: { CountDownTimer, CaptchaLogin },
  data() {
    return {
      v$: useValidate(),
      resetTimer: false,
      academic_year: null,
      ban_duration: null,
      timer: null,
      showPassword: false,
      step: 1,
      overlay: false,
      years: [],
      year: null,
      username: undefined,
      loading: false,
      password: undefined,
      error: null,

      showCaptcha:false,
      recaptchaToken:"",
      captchaRequired: false,
      captchaKey: "",
      captchaImage: "",
      captchaInput: "",
    };
  },
  created() {
    this.ban_duration = localStorage.getItem("banData") || null;
    this.error = localStorage.getItem("message") || null;
    this.axios(`${this.base_url}api/all-years/`)
      .then((response) => {
        this.years = response.data;
        this.academic_year = this.years[this.years?.length - 1]?.id;
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          this.error = this.$t("alert.failure.connect");
        } else {
          if (error.response.status == 403) {
            this.error = this.$t("errors.user-logged-in");
          }
          if (error.response.status == 401) {
            this.error = this.$t("user.login_error");
          }
        }
      });
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        // email: helpers.withMessage(this.$t("errors.email"), email),
      },
      password: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
      academic_year: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
    };
  },
  computed: {
    timer() {
      return this.formatTime(this.timerCount);
    },
    banData(){
      return localStorage.getItem('banData')
    },
    ...mapGetters(["getdir"]),
    ...mapState({
      login_error: (state) => state.login_error,
    }),
    breakPointXS() {
      return this.$vuetify.display.xs;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async selectAcademicYear() {
      await this.axios
        .post(host() + "api/select-academic-year", {
          academic_year: this.academic_year,
        })
        .then((res) => {});
    },
    handelResetTimer(resetTimer) {
      if (resetTimer == true) {
        this.ban_duration = null;
        this.error = null;
        localStorage.removeItem("banData");
        localStorage.removeItem("message");
      }
    },
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.login_error = undefined;
    },
    formatTime(seconds) {
      let duration = moment.duration(seconds, "seconds");
      let formatedTime = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
      return formatedTime;
    },
    async loginAction(username, password, academic_year) {
      this.v$.$validate();
      this.loading = true;
      // Prepare login payload
      const payload = {
        username,
        password,
        academic_year,
      };
      // Add captcha if required
      if (this.captchaRequired) {
        payload.captcha_key = this.captchaKey;
        payload.captcha_value = this.captchaInput;
      }
      if (!this.v$.$error) {
        try {
          await this.axios
            .post(host() + "/api/token/", payload)
            .then((res) => {
              this.loading = false;
              if (
                res.data?.status &&
                (res.data?.status == 4000 || res.data?.status == 3000)
              )
                if (res.data?.data?.includes("role is disabled")) {
                  this.error =
                    "لا يمكن حاليا تسجيل الدخول بهذا الدور. الرجاء اعادة المحاولة لاحقا ";
                } else {
                  this.error = this.$t("user.login_error");
                }
              else {
                // this.$refs.captcha.clearCaptchaImage();
                this.$store.dispatch("setAcademicYear",this.academic_year);
                this.isDisabled = false;
                this.ban_duration = null;
                this.error = null;
                localStorage.removeItem("banData");
                localStorage.removeItem("message");
                this.$store.dispatch("setAuthenticated", res);
                
              }
            })
            .catch((error) => {
              if (error.message.includes("Network Error")) {
                this.error = this.$t("alert.failure.connect");
              }
              const status = error?.response?.status;
              switch (status) {
                case 401:
                  if(typeof(error.response.data.detail)==='string' && error.response.data.detail.includes('captcha_key')){
                      this.captchaInput=""
                      const regexStr = "ErrorDetail\\(string=\"([^\"]+)\",\\s*code=\"[^\"]+\"\\)";
                      const regex = new RegExp(regexStr,"g")
                      const jsonString = error.response.data.detail.replace(/'/g,'"').replace(regex,'"$1"')
                      const data = JSON.parse(jsonString)
                      this.captchaRequired = true;
                      this.captchaKey = data.captcha_key;
                      this.captchaImage = host() + data.captcha_image_url;
                      if (data.message.includes("required"))
                        this.error = this.$t("user.captcha-required");
                      else this.error = this.$t("user.captcha-invalid");
                  } else{
                        this.error = this.$t("user.login_error");
                        this.captchaRequired = false;
                  }
                  break;
                  case 403:
                    this.captchaRequired = false;
                    this.error =
                    "لا يمكن حاليا تسجيل الدخول بهذا الدور. الرجاء اعادة المحاولة لاحقا ";
                    break;
                case 429:
                  this.error = error.response.data.message;
                  this.ban_duration = error.response.data.ban_duration;
                  this.isDisabled = true;
                  localStorage.setItem("banData", this.ban_duration);
                  localStorage.setItem("message", this.error);
                  this.captchaRequired = false;
                  break;
                default:
                  break;
              }
            });
        } catch (error) {
          console.log(error,'--------------');
          this.loading = false;
          this.isDisabled = false;
          this.ban_duration = 0;
          localStorage.removeItem("banData");
          if (error.message.includes("Network Error")) {
            this.error = this.$t("alert.failure.connect");
          } else if (error.response && error.response.status === 429) {
            this.error = error.response.data.message;
            this.ban_duration = error.response.data.ban_duration;
            this.isDisabled = true;
            localStorage.setItem("banData", this.ban_duration);
            localStorage.setItem("message", this.error);
          } else {
            if (error.response.status == 403) {
              this.error = this.$t("user.login_error");
            } else if (error.response.status == 401) {
              this.error = this.$t("user.login_error");
            }
          }
          return 0;
        }
      }
      this.loading = false;
    },
    // async loginAction(username, password, academic_year) {
    //   let captchaValid = false;
    //   this.v$.$validate();
    //   this.loading = true;
    //   if(!this.v$.$error){
    //     captchaValid = await this.$refs.captcha.validateCaptcha();
    //   }
    //   // if (response.ok){
    //   //   alert("Login Successfull!");
    //   //   this.showCaptcha = false
    //   // }
    //   // else {
    //   //   this.showCaptcha = data.show_captcha;
    //   // }
    //   if (!this.v$.$error && captchaValid) {
    //     try {
    //       await this.axios
    //         .post(host() + "/api/token/", { username, password, academic_year })
    //         .then((res) => {
    //           this.loading = false;
    //           if (
    //             res.data?.status &&
    //             (res.data?.status == 4000 || res.data?.status == 3000)
    //           )
    //             if (res.data?.data?.includes("role is disabled")) {
    //               this.error =
    //                 "لا يمكن حاليا تسجيل الدخول بهذا الدور. الرجاء اعادة المحاولة لاحقا ";
    //             } else {
    //               this.error = this.$t("user.login_error");
    //             }
    //           else {
    //             this.$refs.captcha.clearCaptchaImage();
    //             this.$store.dispatch("setAcademicYear",this.academic_year);
    //             // tokenService.checkLastFetchTime();
    //             // inactivityTimer.initialize();
    //             this.isDisabled = false;
    //             this.ban_duration = null;
    //             this.error = null;
    //             localStorage.removeItem("banData");
    //             localStorage.removeItem("message");
    //             this.$store.dispatch("setAuthenticated", res);
                
    //           }
    //         })
    //         .catch((error) => {
    //           this.$refs.captcha.refreshCaptcha();
    //           if (error.message.includes("Network Error")) {
    //             this.error = this.$t("alert.failure.connect");
    //           }
    //           const status = error?.response?.status;
    //           switch (status) {
    //             case 401:
    //               this.error = this.$t("user.login_error");
    //               break;
    //             case 403:
    //               this.error =
    //                 "لا يمكن حاليا تسجيل الدخول بهذا الدور. الرجاء اعادة المحاولة لاحقا ";
    //               break;
    //             case 429:
    //               this.error = error.response.data.message;
    //               this.ban_duration = error.response.data.ban_duration;
    //               this.isDisabled = true;
    //               localStorage.setItem("banData", this.ban_duration);
    //               localStorage.setItem("message", this.error);
    //               break;
    //             default:
    //               break;
    //           }
    //         });
    //     } catch (error) {
    //       this.loading = false;
    //       this.isDisabled = false;
    //       this.ban_duration = 0;
    //       localStorage.removeItem("banData");
    //       if (error.message.includes("Network Error")) {
    //         this.error = this.$t("alert.failure.connect");
    //       } else if (error.response && error.response.status === 429) {
    //         this.error = error.response.data.message;
    //         this.ban_duration = error.response.data.ban_duration;
    //         this.isDisabled = true;
    //         localStorage.setItem("banData", this.ban_duration);
    //         localStorage.setItem("message", this.error);
    //       } else {
    //         if (error.response.status == 403) {
    //           this.error = this.$t("user.login_error");
    //         } else if (error.response.status == 401) {
    //           this.error = this.$t("user.login_error");
    //         }
    //       }
    //       return 0;
    //     }
    //   }
    //   this.loading = false;
    // },
  },
  watch: {
    ban_duration(val) {
      if (!val) {
        localStorage.removeItem("banData");
        localStorage.removeItem("message");
      }
    },
  },
};
</script>
<style></style>
