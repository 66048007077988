<template>
    <v-expansion-panels
      model-value="1"
      class="mb-6"
      v-if="(role==0|| role==1) &&!isexit"
    >
      <v-expansion-panel  value="1">
        <v-expansion-panel-title class="px-4">
          <div class="d-flex justify-space-between w-75">
            <div>
              <v-icon>mdi-tune</v-icon>
              <span class="mx-1">{{ $t("globals.select-criteria") }}</span>
            </div>
            <div class="pt-1">
              <span
                v-for="item in LocationBreadcrumb"
                :key="item"
                class="text-indigo mx-1"
                >{{ item }}</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="py-2">
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="role == 0 || role == 1"
            >
              <VAutocomplete
                :items="filterGovernorates(1)"
                v-model="fk_governorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                hide-details
                clearable
                :no-data-text="$t('governorate.not-found')"
                prepend-inner-icon="mdi-city"
                @update:modelValue="fk_directorate=null"
              ></VAutocomplete>
            </VCol>
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
            >
              <VAutocomplete
                :items="filterDirectorates(fk_governorate)"
                v-model="fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.directorate')"
                persistent-hint
                hide-details
                density="compact"
                clearable
                :no-data-text="$t('globals.governorate-first')"
                prepend-inner-icon="mdi-map-outline"
              ></VAutocomplete>
            </VCol>
            <v-col class="mt-1" cols="6" md="3" sm="6">
              <v-autocomplete
                color="indigo"
                density="compact"
                v-model="date"
                item-title="date"
                item-value="date"
                prepend-inner-icon="mdi-calendar-today"
                clearable
                hide-details
                :items="dates"
                :label="$t('summer.calendar.hijri-date')"
              />
            </v-col>
            <v-col cols="6" sm="2" class="d-flex align-center">
              <v-btn
                density="comfortable"
                append-icon="mdi-presentation"
                class="w-100"
                color="indigo"
                :loading="loading"
                @click="fetchData()"
              >
                <span>{{ $t("globals.show") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.VisitsCarriedOutDuringTheDay") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = false))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
        </div>
        <v-btn density="compact" class="mx-1" @click="isexit = !isexit">
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.VisitsCarriedOutDuringTheDay") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.gov_name_ar
                  : results.gov_name_en
              }}
                ( {{typeList[summer_type_id].type}} )
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
               <h4>{{ " هـ " }} {{ date }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item v-if="vis_header.length >= 1">
      <table class="report-t" >
            <thead>

              <tr>
                <th rowspan="2" style="width: 50px;">{{$t("globals.#")}}</th>

                <th rowspan="2" class="right-header">
                  {{ $t("summer.summer-camp") }}
                </th>
                <th rowspan="2" class="right-header">
                  {{ $t("reportpublic.governorate1") }}
                </th>
                <th rowspan="2" class="right-header">
                  {{ $t("globals.directorate") }}
                </th>
                <th :colspan="vis_header.length">
                  {{ $t("reportpublic.Authorities") }}
                </th>
                <th rowspan="2" class="right-header">
                  {{ $t("globals.total") }}
                </th>
              </tr>
              <tr>

                <th
                  v-for="(data, index) in vis_header"
                  :key="index"
                  class="right-header"
                >
                  {{ data.visitor_name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in results2.governorate_data"
                :key="index"
              >
                <td>{{index+1}}</td>
                <td>
                  {{ data.school_name }}
                </td>
                <td>
                  {{ data.governorate_name_ar }}
                </td>
                <td>
                  {{ data.directorate_name }}
                </td>
                <td
                  class="text-center"
                  v-for="(data2, index) in data.visitors_in_entity"
                  :key="index"
                  >
                  {{ data2.visitors_summer_count }}
                </td>
                <td  class="text-center">{{ data.total_visitors }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="4">{{ $t("reportpublic.GrandTotal") }}</th>
                <th
                  v-for="(
                    data2, index
                  ) in results2.total_visitors_in_entity"
                  :key="index"
                >
                  {{ data2.visitors }}
                </th>
                <th>
                  {{ results2.total_for_all_visitors }}
                </th>
              </tr>
            </tfoot>
      </table>
    </v-card-item>
    <v-row v-else justify="center" align-content="center" class="pa-4">
      <v-card class="w-100 pa-3 text-center">
        {{$t('globals.not-found')}}
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
;
import { mapState, mapActions ,mapGetters} from "vuex";

export default {
  async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.fetchWeek();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  async created(){
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getCalendar();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
      getDirectorate: "school/getDirectorate",
      getGovernorates: "school/getGovernorates",

    }),
    async getCalendar() {
      await this.axios
        .get(this.base_url + "api/summer/calendar")
        .then((response) => {
          if (response.data) {
            this.dates= response.data.results;
          }
        });
    },
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/daily-reports/summer-visits-carried-out-day`,
          {
            params:{
              date:this.date,
              fk_directorate:this.fk_directorate,
              fk_governorate:this.fk_governorate
            }
          }
        )
        .then((response) => {
          this.results2 = response.data.results;
          this.header = this.results2.governorate_data[0];
          this.vis_header = this.header ? this.header.visitors_in_entity : [];
          this.gover = response.data.results.governorate_data;           
          this.isexit=true
        })
        .catch((error) => {
            
        });
        this.loading=false
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  data() {
    return {
      fk_governorate:null,
      currnet_date_id: null,
      currnet_week_id: null,
      dates:[],
      summer_type_id: 0,
      typeList: [
        { id: 0, type: this.$t("globals.all") },
        { id: 1, type: this.$t("reportpublic.Open") },
        // { id: 2, type: this.$t("reportpublic.Close") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      isexit: false,
      results: [],
      size: null,
      data: [],
      date:null,
      vis_header: [],
      source: [],
      header: [],
      header2: [],
      gover: [],
      fk_directorate:null,
      lessonRecord: {},
    };
  },
  computed: {
    ...mapState({
      weeks: (state) => state.summer.weeks,
      role: (state) => state.role,

    }),
    ...mapGetters({
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterGovernorates: "school/getGovernoratesByCountry",

    }),
  },
  components: {
    ReportHeader,
  },
};
</script>
