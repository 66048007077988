<template>
<v-expansion-panels  >
  <v-expansion-panel   class="mb-2" >
    <v-expansion-panel-title class="px-4">
        <span>{{$t('globals.select-criteria')}}</span>
    </v-expansion-panel-title>
    <v-expansion-panel-text >
      <v-row class="py-2" >
        <v-col cols="12" md="5" sm="4">
          <v-autocomplete
            color="indigo"
            v-model="fk_level"
            density="compact"
            :item-title="classesName"
            item-value="id"
            prepend-inner-icon="mdi-stairs-box"
            clearable
            hide-details="auto"
            :items="classes"
            :label="$t('summer.level.name_ar')"
            @update:modelValue="fk_division = null"
          />
        </v-col>
        <v-col cols="12" md="5" sm="4">
          <v-autocomplete
            color="indigo"
            v-model="fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-shape-outline"
            clearable
            hide-details="auto"
            :items="getClassDivisions(fk_level)"
            :label="$t('division.select')"
          />
        </v-col>

        <v-col cols="4" md="1" class="mt-2 ">
          <v-btn
            @click="getStudenthDropout"
            class="bg-indigo"
            size="small"
          >
            <span class="text-white">
              {{ $t("globals.show") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</v-expansion-panels> 
  <v-card  class="justify-center" :loading="loading"  v-if="$checkPermissions('summer_center.view_levelstudentyear',['sys_admin','summer_admin','user']) ">
    <v-card-text class="mt-4 bp-0">
      <v-row  >
          <VCol cols="6" class="mb-2">
              <v-text-field
                color="indigo"
                v-model="search_student"
                @input="getStudenthDropout"
                density="compact"
                clearable
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                :label="$t('globals.student-search')"
              />
            </VCol>
        <v-col cols="12">
          <!-- <v-card class="pa-2 ma-auto" > -->
          <v-form ref="form">
            <v-card :disabled="loading">
              <v-table 
                density="compact"
                :height="students?.length>9?420:null"
                fixed-header
                >
                <thead>
                  <tr>
                    <th>{{ $t("globals.number") }}</th> 
                    <th>{{ $t("school.student_name") }}</th>
                    <th>{{ $t("globals.portability") }}</th>
                    <th>{{ $t("school.status_study") }}</th>
                    <th>
                      {{ $t("summer.dropout-reasons") }}
                    </th>
                    <th >{{ $t("summer.dropout-date") }}</th>
                    <!-- <th style="width:30%;">{{$t('globals.note')}}</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in students" :key="student">
                    <td>{{ index + 1 }}</td>
                    <td style="min-width:200px">{{ student.student_name }}</td>
                    <td style="min-width:100px">
                      <v-lazy :options="{'threshold':0.5}">
                        <v-select
                          v-model="student.is_moveable"
                          density="compact"
                          item-title="name"
                          item-value="is_moveable"
                          :no-data-text="$t('globals.not-found')"
                          :items="portability"
                          hide-details
                          variant="underlined"
                        >
                        </v-select>
                      </v-lazy>
                    </td>
                    <td  style="min-width:150px">
                      <v-lazy>
                        <v-select
                          v-model="student.study_mode"
                          density="compact"
                          item-title="name"
                          item-value="id"
                          :no-data-text="$t('globals.not-found')"
                          :items="study_status"
                          hide-details
                          variant="underlined"
                        >
                          <!-- :placeholder="$t('subject.select')" -->
                        </v-select>
                      </v-lazy>
                    </td>
                    <td  style="min-width:150px">
                      <v-lazy>
                        <v-select
                          v-model="student.drop_out_reason"
                          density="compact"
                          item-title="name"
                          clearable
                          item-value="id"
                          :no-data-text="$t('globals.not-found')"
                          :items="place_type"
                          hide-details="auto"
                          :rules="[
                            (value) =>
                              student.study_mode!==2 || !!value ||$t('globals.required_field'),
                          ]"
                          :disabled="
                            student.study_mode == 1 ||
                            student.study_mode == 3 ||
                            student.study_mode == null
                          "
                          :placeholder="$t('summer.dropout-reason')"
                          variant="underlined"
                        
                        >
                        </v-select>
                      </v-lazy>
                    </td>
                    <td>{{ student.drop_out_date }}</td>
                  </tr>
                </tbody>
              </v-table>
              <v-divider/>
              <div class="d-flex mt-1 "  id="pagination-bar" >
                <v-pagination  
                    v-model="page" 
                    :length="length"   
                    density="compact"
                    show-first-last-page
                    >
                </v-pagination>
                <div class="d-flex ">
                  <v-select 
                    v-model="perPage" 
                    class="pa-0" 
                    :items="itemsPerPage"  
                    item-value="value" 
                    item-title="text" 
                    density="compact" 
                    hide-details
                    variant="text"
                    >
                  </v-select>
                  <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{totalItems}} ] {{$t('globals.item')}}</span>
                </div>
              </div>
            </v-card>

            
          </v-form>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="exist" class="px-3">
      <v-btn
        :loading="loading"
        v-if="$checkPermissions('summer_center.change_levelstudentyear',['sys_admin','summer_admin','user'])"
        @click="saveStudentDropout"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        @click="cleanStudentDropoutForm"
        class="mx-3 bg-ccc"
        size="small"
      >
        <span>
          {{ $t("globals.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else-if="!loading"  class="pa-2 mt-2 text-center" >
    <span >
      {{ $t("globals.not-found-items") }}
    </span>
  </v-card>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "CorrectionDropout",
  props: {
    id: String,
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getClasses();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getStudenthDropout();
      await this.getStudyMode();
      await this.getDropOutReason();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      loading: false,
      schedules_data: [],
      v$: useValidate(),
      perPage:10,
      itemsPerPage: [
        {value:10,text:"10"},
        {value:25,text:"25"},
        {value:50,text:"50"},
        {value:100,text:"100"},
        {value:null,text:this.$t("globals.all")},
      ],
      pagination:{},
      page:1,
      length:null,
      totalItems:null,
      showAlert: false,
      update: false,
      user: null,
      exist: false,
      student: [],
      valid: false,
      is_moveable: false,
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      user: null,
      fk_level: undefined,
      fk_division: undefined,
      fk_status: [],
      fk_reason: [],
      class_Dropouts: [],
      Dropouts: [],
      fk_Dropout: undefined,
      studentDropout: {
        is_checked: false,
        fk_student: undefined,
        note: "",
      },
      rules: {
        title: [(value) => !!value || this.$t("globals.required_field")],
        division: [(value) => !!value || this.$t("globals.required_field")],
      },
      portability: [
        { name: "قابل للنقل", is_moveable: true },
        { name: "غير قابل للنقل", is_moveable: false },
      ],
      students: [],
      max_entry: [(value) => value == null || value.length < 250 || ""],
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      study_status: (state) => state.summer_globals.study_mode,
      place_type: (state) => state.summer_globals.drop_out_reason,
    }),
    totalPages(){
      return Math.ceil(this.students.length/this.perPage)
    },
    displayItems(){
      const start_index=(this.currentPage -1) * this.perPage
      const end_index= start_index+this.perPage
      return this.students.slice(start_index,end_index)
    },
    ...mapGetters({
      getDivisionName: "summer/getDivisionName",
      getClassDivisions: "summer/getClassDivisions",
    }),
    classesName() {
      return this.$i18n.locale == "ar" ? "level_with_phase_name" : "name_en";
    },
  },

  validations() {
    return {
      fk_level: {},
      fk_status: {},
      fk_reason: {},
    };
  },
  methods: {
    ...mapActions({
      getDivisions: "summer/getDivisions",
      getClasses: "summer/getLevels",
      getClassAssignHall: "summer/getClassAssignHall",
      getStudyMode: "summer_globals/getStudyMode",
      getDropOutReason: "summer_globals/getDropOutReason",
    }),
    async getStudenthDropout() {
        this.exist = true;
        this.loading=true
        await this.axios
          .get(`${this.base_url}api/summer/get-all-students-by-level-and-division`, {
            params: {
              level: this.fk_level,
              division: this.fk_division,
              page: this.page,
              page_size: this.perPage,
              search_txt:this.search_student
            },
          })
          .then((response) => {
            this.loading=false
            this.students = response.data.results;
            this.pagination=response.data.pagination
            this.length=this.pagination?.num_pages
            this.itemsPerPage[4]['value']=this.pagination?.count
            this.totalItems=this.pagination?.count
          }).catch(()=>{
            this.loading=false
          })
    },
    async saveStudentDropout() {
      this.loading = true;
      const { valid } = await this.$refs.form.validate();
      let students = [];
      students = this.students.map((item) => {
        return {
          student_id: item.fk_student,
          reason: item.drop_out_reason,
          drop_out_date: item.drop_out_date,
          state: item.study_mode,
          is_moveable: item.is_moveable,
        };
      });
      if (valid) {
        this.students.data_entry = this.user;
        this.axios
          .post(`${this.base_url}/api/summer/student-drop-out`, students)
          .then((response) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_saved"));
            this.getStudenthDropout();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.insert"));
            this.loading = false;
            try {
              if (
                error.response.data.study_mode[0].includes(
                  "This field may not be null."
                )
              ) {
                this.alert.message = this.$t("alert.failure.insert");
              } else {
                this.alert.message = this.$t("alert.failure.insert");
              }
            } catch (e) {
              this.alert.message = this.$t("alert.failure.insert");
            }
          });
      }else{


        this.loading=false
      }
    },
    cleanStudentDropoutForm() {
      this.v$.$reset();
      // this.exist = false;
      // this.students = [];
      // this.class_Dropouts = [];
      this.getStudenthDropout()
      this.fk_level = undefined;
      this.fk_division = undefined;
    },
  },
  watch:{
    perPage() {
      this.page=1
      this.getStudenthDropout();
      
    },
    page() {
      this.getStudenthDropout(this.page);
    },
  }
};
</script>
<style scoped>
#pagination-bar *{
  font-size: 0.95rem ;
}
</style>
