<template>
  <VCard
    :disabled="
      !$checkPermissions('summer_center.change_schoolsummer') ||
      !$checkPermissions('summer_center.add_schoolsummer')
    "
    v-if="
      $checkPermissions('summer_center.add_schoolsummer', [
        'sys_admin',
        'gen_admin',
        'gov_admin',
        'dir_admin',
        'summer_admin',
      ]) ||
      $checkPermissions('summer_center.view_schoolsummer', [
        'sys_admin',
        'gen_admin',
        'gov_admin',
        'dir_admin',
        'summer_admin',
      ])
    "
  >
    <v-card-text class="pb-0">
      <VForm @input="handleInput" ref="form">
        <v-locale-provider :locale="$i18n.locale">
          <v-card class="pa-1">
            <VTabs
              v-model="tab"
              color="blue"
              center-active
              show-arrows
              density="compact"
              slider-color="indigo"
            >
              <VTab v-for="(value, index) in tabs" :key="index" :value="index">
                {{ value }}
              </VTab>
            </VTabs>
          </v-card>
        </v-locale-provider>

        <v-window v-model="tab" class="my-4 py-6">
          <VForm ref="form1" v-model="validForms.form1">
            <v-window-item :value="0">
              <!-- <v-form ref="tab_zero"> -->
              <VRow>
                <VCol cols="12" md="12">

                <VRow>

                    <VCol cols="12" >
                      <VTextField
                        v-model="summerSchool.name_ar"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="`${$t('summer.close-school-name-ar')} *`"
                        :placeholder="$t('summer.close-school-name-ar-placeholder')"
                        density="compact"
                        counter="60"
                        :rules="place_name_rule"
                      >
                      </VTextField>
                    </VCol>

                    <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="countries"
                        v-model="summerSchool.country_id"
                        item-title="name_ar"
                        item-value="id"
                        :label="`${$t('globals.country')} *`"
                        persistent-hint
                        density="compact"
                        :rules="country_rule"
                        prepend-inner-icon="mdi-flag"
                        :disabled="role > 1"
                        @update:modelValue="
                          (summerSchool.governorate_id = null),
                            (summerSchool.fk_directorate = null),
                            (summerSchool.fk_village = null)
                            (summerSchool.name_ar = null)
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="filterGovernorates(summerSchool.country_id)"
                        v-model="summerSchool.governorate_id"
                        item-title="name_ar"
                        item-value="id"
                        :label="`${$t('globals.governorate')} *`"
                        persistent-hint
                        density="compact"
                        :rules="governorate_rule"
                        prepend-inner-icon="mdi-city"
                        :disabled="role > 1"
                        @update:modelValue="
                            (summerSchool.fk_directorate = null),
                            (summerSchool.fk_village = null)
                            (summerSchool.name_ar = null)
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="filterDirectorates(summerSchool.governorate_id)"
                        v-model="summerSchool.fk_directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="`${$t('globals.directorate')} *`"
                        :no-data-text="$t('globals.governorate-first')"
                        persistent-hint
                        density="compact"
                        :rules="directorate_rule"
                        :disabled="role > 2"
                        prepend-inner-icon="mdi-map-outline"
                        @update:modelValue="
                            (summerSchool.fk_village = null)
                            (summerSchool.name_ar = null)
                        "

                      ></VAutocomplete>
                    </VCol>

                <VCol cols="12" md="4">
                    <VAutocomplete
                      :items="filterVillages(summerSchool.fk_directorate)"
                      prepend-inner-icon="mdi-city"
                      v-model="summerSchool.fk_village"
                      item-title="name_ar"
                      :no-data-text="$t('globals.directorate-first')"
                      item-value="id"
                      :label="`${$t('school.village-name')} *`"
                      persistent-hint
                      density="compact"
                      @update:modelValue="
                            (summerSchool.name_ar = null)
                        "
                    ></VAutocomplete>
                  </VCol>

                  <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="placeTypesList"
                        v-model="summerSchool.place_type"
                        item-title="name"
                        item-value="id"
                        :label="`${$t('summer.place-type')} *`"
                        persistent-hint
                        density="compact"
                        :rules="place_type_rule"
                        prepend-inner-icon="mdi-format-list-bulleted-type"
                      ></VAutocomplete>
                  </VCol>


                <VCol cols="12" md="4" v-if="summerSchool.place_type == 2">
                    <VAutocomplete
                    :items="filterSchools(summerSchool.fk_village)"
                    clearable
                    prepend-inner-icon="mdi-abjad-arabic"
                    v-model="summerSchool.place_name"
                    item-title="name_ar"
                    item-value="name_ar"
                    :no-data-text="$t('globals.village-first')"
                    :label="`${$t('summer.place-name')} *`"
                    persistent-hint
                    density="compact"
                  ></VAutocomplete>
                </VCol>

                <VCol cols="12" md="4" v-if="summerSchool.place_type != 2" >
                    <VTextField
                      v-model="summerSchool.place_name"
                      prepend-inner-icon="mdi-abjad-arabic"
                      :label="`${$t('summer.place-name')} *`"
                      :placeholder="$t('summer.place-name-placeholder')"
                      density="compact"
                      counter="100"
                      :rules="place_name_rule"
                    >
                    </VTextField>
                </VCol>



                    <!-- <VCol cols="12" md="4">
                      <v-text-field
                        counter="50"
                        type="input"
                        v-model="summerSchool.address"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-map-marker"
                        :label="`${$t('summer.solitude')} *`"
                        :rules="address"
                      />
                    </VCol> -->


                   
                   
                  
                    <!-- <VCol cols="12" md="4">
                      <VTextField
                        v-model="summerSchool.name_ar"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="`${$t('summer.close-school-name-ar')} *`"
                        :placeholder="$t('summer.close-school-name-ar-placeholder')"
                        density="compact"
                        counter="30"
                        :rules="school_name_rule"
                      >
                      </VTextField>
                    </VCol> -->
                    <!-- <VCol cols="12" md="4">
                    <VTextField
                      v-model="summerSchool.name_en"
                      prepend-inner-icon="mdi-alpha-e"
                      :label="$t('summer.center_name_en')"
                      :placeholder="$t('summer.center_name_en_placeholder')"
                      density="compact"
                      :rules="school_name_en_rule"
                      counter="30"
                    >
                    </VTextField>
                  </VCol> -->
                    <VCol cols="12" md="4">
                      <DatetimePicker
                        v-model="summerSchool.date_of_establishment"
                        :max="today"
                        :calendar="'gregorg'"
                        :placeholder="$t('school.establish_date')"
                        :label="`${$t('school.establish_date')} *`"
                        :rules="establish_date_rule"
                      ></DatetimePicker>
                      <!-- @input="v$.summerSchool.establish_date_rule.$touch" -->
                      <!-- :errorMessages="
                        v$.summerSchool.establish_date_rule.$errors.map((e) => e.$message)
                      " -->
                    </VCol>

                    <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="shift_times_list"
                        v-model="summerSchool.shift_time"
                        item-title="name"
                        item-value="id"
                        :label="`${$t('summer.shift-time')} *`"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-calendar-clock-outline"
                        :rules="shift_time_rule"
                      ></VAutocomplete>
                    </VCol>

                    <!-- <VCol cols="12" xs="12" md="4">
                    <VRadioGroup
                      inline
                      :label="$t('school.students_type')"
                      v-model="summerSchool.students_type"
                      :rules="students_type_rule"
                    >
                      <VRadio
                        v-for="item in student_type"
                        :key="item"
                        :label="item.name"
                        :value="item.id"
                      ></VRadio>
                    </VRadioGroup>
                  </VCol> -->

                    <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="student_type"
                        v-model="summerSchool.students_type"
                        item-title="name"
                        item-value="id"
                        :label="`${$t('school.students_type')} *`"
                        persistent-hint
                        density="compact"
                        :rules="students_type_rule"
                      ></VAutocomplete>
                    </VCol>
                    <!-- <VCol cols="12" md="4">
                    <VRadioGroup
                      inline
                      :label="$t('summer.summer_type')"
                      v-model="summerSchool.school_type"
                      :rules="school_type_rule"
                    >
                      <VRadio
                        v-for="item in shoolTypes"
                        :key="item"
                        :label="item.name"
                        :value="item.id"
                      ></VRadio>
                    </VRadioGroup>
                  </VCol> -->
                    <VCol cols="12" md="4">
                      <VTextField
                        v-model="summerSchool.class_count"
                        prepend-inner-icon="mdi-numeric"
                        :label="`${$t('summer.class-count')} *`"
                        :placeholder="$t('summer.add-class-count')"
                        density="compact"
                        type="number"
                        :rules="class_count_rule"
                      >
                      </VTextField>
                    </VCol>

                    <VCol cols="12" md="4">
                      <!-- <v-checkbox
                      v-model="summerSchool.is_funded"
                      density="compact"
                      hide-details="auto"
                      :label="$t('summer.is-funded')"
                    >
                    </v-checkbox> -->
                      <VAutocomplete
                        :items="have_funded"
                        v-model="summerSchool.is_funded"
                        item-title="name"
                        item-value="key"
                        :label="$t('summer.is-funded')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi mdi-notebook-outline"
                      ></VAutocomplete>
                    </VCol>

                    <VCol cols="12" md="4" v-if="summerSchool.is_funded">
                      <VTextField
                        v-model="summerSchool.funded_source"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="`${$t('summer.funded-source')} *`"
                        :placeholder="$t('summer.funded-source')"
                        density="compact"
                        counter="30"
                        :rules="funded_source_rule"
                      >
                      </VTextField>
                    </VCol>

                    <!-- <VCol cols="12" md="3">
                    <v-checkbox
                      v-model="summerSchool.active"
                      density="compact"
                      hide-details="auto"
                      :label="$t('summer.is-active')"
                    >
                    </v-checkbox>
                  </VCol> -->

                    <VCol cols="12" md="4">
                      <VAutocomplete
                        :items="have_active"
                        v-model="summerSchool.active"
                        item-title="name"
                        item-value="key"
                        :label="`${$t('summer.is-active')} *`"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi mdi-notebook-outline"
                        :rules="have_active_rule"
                      ></VAutocomplete>
                    </VCol>

                    <VCol cols="12" md="4" v-if="summerSchool.active">
                      <VTextField
                        v-model="summerSchool.active_number"
                        prepend-inner-icon="mdi-numeric"
                        :label="`${$t('school.active_number')} *`"
                        :placeholder="$t('school.placeholder_active_number')"
                        density="compact"
                        type="number"
                        :rules="license_number_rule"
                      >
                      </VTextField>
                    </VCol>

                    <VCol cols="12" md="4" v-if="summerSchool.active">
                      <DatetimePicker
                        v-model="summerSchool.date_of_active"
                        :max="today"
                        :calendar="'gregorg'"
                        :placeholder="$t('school.active_date')"
                        :label="`${$t('school.active_date')} *`"
                        :rules="establish_date_rule"
                      ></DatetimePicker>
                    </VCol>

                    <VCol cols="12" class="pa-2 ma-0" md="12" lg="12">
                      <VTextarea
                        v-model="summerSchool.note"
                        clearable
                        :label="$t('globals.note')"
                        :rules="note_rule"
                        counter="250"
                        color="indigo"
                        no-resize
                        density="compact"
                        rows="2"
                        prepend-inner-icon="mdi-note-outline"
                      ></VTextarea>
                    </VCol>
                  </VRow>
                </VCol>

                <fieldset
                  class="d-lg-flex flex-sm-fill w-100 mx-3 mb-2 py-1 border rounded mt-4"
                >
                  <legend class="mx-2 px-2">{{ $t("school.contact_data") }}</legend>

                  <VCol cols="12"  class="px-3">
                    <VRow
                      v-for="(contact, index) in summerSchool.contacts"
                      :key="index"
                      :class="index > 0 ? 'mt-4' : 'mt-1'"
                    >
                      <VCol cols="12" md="6">
                        <VAutocomplete
                          :items="contact_type_list"
                          v-model="contact.contact_type"
                          item-title="name"
                          item-value="id"
                          :label="`${$t('school.contact_type')} *`"
                          persistent-hint
                          density="compact"
                          :rules="contact_type_rule"
                          :prepend-inner-icon="
                            contact.contact_type == 1
                              ? 'mdi mdi-phone'
                              : contact.contact_type == 2
                              ? 'mdi mdi-email'
                              : contact.contact_type == 3
                              ? 'mdi mdi-cellphone'
                              : contact.contact_type == 4
                              ? 'mdi mdi-facebook'
                              : contact.contact_type == 5
                              ? 'mdi mdi-whatsapp'
                              : contact.contact_type == 6
                              ? 'mdi mdi-telegram'
                              : ''
                          "
                        ></VAutocomplete>
                      </VCol>
                      <VCol cols="12" md="6">
                        <VTextField
                          v-model="contact.contact"
                          :label="`${$t('school.contact')} *`"
                          density="compact"
                          :rules="[
                            (value) => checkContactType(value, contact.contact_type),
                          ]"
                          :prepend-inner-icon="
                            contact.contact_type == 1
                              ? 'mdi mdi-phone'
                              : contact.contact_type == 2
                              ? 'mdi mdi-email'
                              : contact.contact_type == 3
                              ? 'mdi mdi-cellphone'
                              : contact.contact_type == 4
                              ? 'mdi mdi-facebook'
                              : contact.contact_type == 5
                              ? 'mdi mdi-whatsapp'
                              : contact.contact_type == 6
                              ? 'mdi mdi-telegram'
                              : ''
                          "
                        >
                        </VTextField>
                      </VCol>
                    </VRow>
                    <VRow elevation="4" class="mx-1 mb-2 mt-5">
                      <VBtn
                        variant="tonal"
                        icon
                        density="compact"
                        @click.prevent="addContact()"
                        @keydown.tab.exact="navigateByTabInput()"
                      >
                        <VIcon icon="mdi-plus-thick" color="green-darken-2"></VIcon>
                      </VBtn>
                      <VBtn
                        v-if="summerSchool.contacts && summerSchool.contacts.length > 1"
                        variant="tonal"
                        icon
                        density="compact"
                        class="mx-2"
                        @click.prevent="removeContact()"
                      >
                        <VIcon icon="mdi-minus-thick" color="red-darken-2"></VIcon>
                      </VBtn>
                    </VRow>
                  </VCol>
                </fieldset>


                <fieldset
                  class="d-lg-flex flex-sm-fill w-100 mx-3 mb-2 py-1 border rounded mt-4"
                >
                  <legend class="mx-2 px-2">الامكانيات المتوفرة</legend>
                  <v-checkbox
                    v-model="summerSchool.bedroom_is_available"
                    class="ma-0 pa-0"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.bedroom-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.yard_is_available"
                    class="ma-0 pa-0"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.yard-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.lecture_room_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.lecture-room-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.accoustics_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.accoustics-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.electricity_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.electricity-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.activity_tools_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.activity-tools-is-available')"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="summerSchool.clinic_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.clinic-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.store_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.store-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.kitchen_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.kitchen-is-available')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="summerSchool.tools_is_available"
                    density="compact"
                    hide-details="auto"
                    :label="$t('summer.tools-is-available')"
                  ></v-checkbox>
                </fieldset>


                <VCol cols="12" md="12">
                  <v-label class="text-center pa-2">
                    {{ this.$t("summer.summer_logo") }}
                  </v-label>
                  <ImageInput
                    :reset="image_reset_logo"
                    :image="summerSchool.logo"
                    :text="$t('summer.summer_logo')"
                    prepend-icon="mdi-pencil"
                    clearable
                    @ImageSelected="saveSchoolLogo"
                    @resetRequest="imageResetLogo"
                  ></ImageInput>
                </VCol>
              </VRow>
              <!-- </v-form> -->
            </v-window-item>
          </VForm>

          <VForm ref="form2" v-model="validForms.form2">
            <v-window-item :value="1">
              <!-- <v-form ref="tab_four"> -->
              <v-row>
                <VCol cols="12" md="4">
                  <VTextField
                    v-model="summerSchool.manager.name_ar"
                    :label="$t('summer.manager')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-account-tie"
                    :error-messages="
                      v$.summerSchool.manager.name_ar.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12" md="4">
                  <VAutocomplete
                    :items="genders"
                    v-model="summerSchool.manager.gender"
                    item-title="name"
                    item-value="id"
                    :label="$t('globals.gender')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-gender-male-female"
                    :error-messages="
                      v$.summerSchool.manager.gender.$errors.map((e) => e.$message)
                    "
                  ></VAutocomplete>
                </VCol>
              </v-row>
              <v-row>
                <VCol cols="12" md="4">
                  <VTextField
                    v-model="summerSchool.manager.phone_number"
                    :label="$t('summer.employee.mobile-number')"
                    persistent-hint
                    clearable
                    density="compact"
                    type="number"
                    prepend-inner-icon="mdi-cellphone-text"
                    :error-messages="
                      v$.summerSchool.manager.phone_number.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12" md="4">
                  <VAutocomplete
                    :items="countries"
                    v-model="summerSchool.manager.country_id"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('globals.country')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-flag"
                    :error-messages="
                      v$.summerSchool.manager.country_id.$errors.map((e) => e.$message)
                    "
                    @update:modelValue="
                      (summerSchool.manager.governorate_id = undefined),
                        (summerSchool.manager.fk_directorate = undefined)
                    "
                  ></VAutocomplete>
                </VCol>
              </v-row>
              <v-row>
                <VCol cols="12" md="4">
                  <VAutocomplete
                    :items="filterGovernorates(summerSchool.manager.country_id)"
                    v-model="summerSchool.manager.governorate_id"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('globals.governorate')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-city"
                    :error-messages="
                      v$.summerSchool.manager.governorate_id.$errors.map(
                        (e) => e.$message
                      )
                    "
                    @update:modelValue="summerSchool.manager.fk_directorate = undefined"
                  ></VAutocomplete>
                </VCol>
                <VCol cols="12" md="4">
                  <VAutocomplete
                    :items="filterDirectorates(summerSchool.manager.governorate_id)"
                    v-model="summerSchool.manager.fk_directorate"
                    item-title="name_ar"
                    :no-data-text="$t('globals.governorate-first')"
                    item-value="id"
                    :label="$t('globals.directorate')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-map-outline"
                    :error-messages="
                      v$.summerSchool.manager.fk_directorate.$errors.map(
                        (e) => e.$message
                      )
                    "
                  ></VAutocomplete>
                </VCol>
              </v-row>
              <v-row v-if="role < 4 && is_update == false">
                <v-col cols="6" md="4">
                  <VTextField
                    v-model="summerSchool.username"
                    :label="$t('user.username')"
                    :placeholder="$t('user.username')"
                    maxLength="50"
                    persistent-hint
                    clearable
                    density="compact"
                    autocomplete="new-username"
                    prepend-inner-icon="mdi-account"
                    :rules="summerSchool.password?.length > 0 ? school_username : ''"
                  ></VTextField>
                </v-col>
                <v-col cols="6" md="4">
                  <VTextField
                    v-model="summerSchool.password"
                    :label="$t('user.password')"
                    :placeholder="$t('user.password')"
                    persistent-hint
                    clearable
                    type="password"
                    maxLength="128"
                    autocomplete="new-password"
                    density="compact"
                    prepend-inner-icon="mdi-lock"
                    :rules="summerSchool.username?.length > 0 ? school_password : ''"
                  ></VTextField>
                </v-col>
              </v-row>
              <!-- </v-form> -->
            </v-window-item>
          </VForm>
          
         

          <VForm ref="form3" v-model="validForms.form3">
            <v-window-item :value="2">
              <!-- <v-form ref="tab_two"> -->
              <VRow>
                <VCol cols="12" md="8">
                  <VRow>
                    <VCol cols="12" md="6">
                      <VAutocomplete
                        :items="supervisors"
                        v-model="summerSchool.fk_supervisor"
                        item-title="name"
                        item-value="id"
                        :label="`${$t('summer.supervising-authority-name')} *`"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-account-tie"
                        :rules="fk_supervisor_name_rule"
                        multiple
                        chips
                        closable-chips
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip
                            class="mx-1"
                            style="font-size: 0.9rem"
                            v-bind="props"
                            :text="item.raw.name"
                          >
                          </v-chip>
                        </template>
                      </VAutocomplete>
                    </VCol>
                    <VCol cols="12" md="6">
                      <VAutocomplete
                        :items="have_license"
                        v-model="summerSchool.have_license"
                        item-title="name"
                        item-value="key"
                        :label="`${$t('summer.school-has-license')} *`"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi mdi-notebook-outline"
                        :rules="have_license_rule"
                        @update:model-value="
                          this.summerSchool.have_license == false
                            ? (this.summerSchool.license_number = null)
                            : ''
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol cols="12" md="6" v-if="summerSchool.have_license">
                      <VTextField
                        v-model="summerSchool.license_number"
                        prepend-inner-icon="mdi-numeric"
                        :label="`${$t('school.license_number')} *`"
                        :placeholder="$t('school.placeholder_license_number')"
                        density="compact"
                        type="number"
                        :rules="license_number_rule"
                      >
                      </VTextField>
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
                <VCol cols="12" md="12" v-if="summerSchool.have_license">
                  <v-label class="text-center pa-2">
                    {{ this.$t("school.license_image") }}
                  </v-label>
                  <ImageInput
                    :reset="image_reset"
                    :image="summerSchool.license_image"
                    :text="$t('school.placeholder_license_image')"
                    @ImageSelected="saveLicenseImage"
                    @resetRequest="imageReset"
                  ></ImageInput>
                </VCol>
              <!-- </v-form> -->
            </v-window-item>
          </VForm>

          <!-- <v-window-item :value="4"> -->
          <!-- <v-form ref="tab_three"> -->

          <!-- <VCol cols="12" md="4">
                <VAutocomplete
                  :items="contact_type_list"
                  v-model="contact.contact_type"
                  item-title="name"
                  item-value="id"
                  :label="$t('school.contact_type')"
                  persistent-hint
                  density="compact"
                  :rules="contact_type_rule"
                  :prepend-inner-icon="
                    contact.contact_type == 1
                      ? 'mdi mdi-phone'
                      : contact.contact_type == 2
                      ? 'mdi mdi-email'
                      : contact.contact_type == 3
                      ? 'mdi mdi-cellphone'
                      : contact.contact_type == 4
                      ? 'mdi mdi-facebook'
                      : contact.contact_type == 5
                      ? 'mdi mdi-whatsapp'
                      : contact.contact_type == 6
                      ? 'mdi mdi-telegram'
                      : ''
                  "
                ></VAutocomplete>
              </VCol>
              <VCol cols="12" md="4">
                <VTextField
                  v-model="contact.contact"
                  :label="$t('school.contact')"
                  density="compact"
                  :rules="[(value) => checkContactType(value, contact.contact_type)]"
                  :prepend-inner-icon="
                    contact.contact_type == 1
                      ? 'mdi mdi-phone'
                      : contact.contact_type == 2
                      ? 'mdi mdi-email'
                      : contact.contact_type == 3
                      ? 'mdi mdi-cellphone'
                      : contact.contact_type == 4
                      ? 'mdi mdi-facebook'
                      : contact.contact_type == 5
                      ? 'mdi mdi-whatsapp'
                      : contact.contact_type == 6
                      ? 'mdi mdi-telegram'
                      : ''
                  "
                >
                </VTextField>
              </VCol>
            
            <VRow class="mt-4 mx-16 d-flex flex-wrap" elevation="4">
              <VBtn
                variant="elevated"
                size="small"
                elevation="4"
                color="grey-lighten-2"
                class="ma-3"
                @click.prevent="addContact()"
              >
                <VIcon icon="mdi-plus" color="grey-darken-2"></VIcon>
              </VBtn>
              <VBtn
                v-if="summerSchool.contacts && summerSchool.contacts.length > 1"
                variant="elevated"
                size="small"
                elevation="4"
                color="grey-lighten-2"
                class="ma-3"
                @click.prevent="removeContact()"
              >
                <VIcon icon="mdi-minus" color="grey-darken-2"></VIcon>
              </VBtn>
            </VRow> -->
          <!-- </v-form> -->
          <!-- </v-window-item> -->
        </v-window>
      </VForm>
    </v-card-text>
    <VCardActions class="px-3">
      <VBtn
        class="bg-indigo"
        v-if="
          !is_update &&
          $checkPermissions('summer_center.add_schoolsummer', [
            'sys_admin',
            'gen_admin',
            'gov_admin',
            'dir_admin',
          ])
        "
        @click.prevent="saveSummer()"
        size="small"
        :loading="saveDataLoading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          is_update &&
          $checkPermissions('summer_center.change_schoolsummer', [
            'sys_admin',
            'summer_admin',
            'gen_admin',
            'gov_admin',
            'dir_admin',
          ])
        "
        class="bg-success"
        @click.prevent="updateSummer()"
        size="small"
        :loading="editDataLoading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>

      <VBtn class="mx-3" size="small" @click="resetWindow(tab)">
        {{ $t("globals.page_clear") }}
        <VIcon icon="mdi-broom" color="red" end></VIcon>
      </VBtn>

      <VBtn class="ma-3" size="small" @click="resetForm()" v-if="!is_update">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import { required, helpers, maxLength, minLength, email } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";


export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      v$: useValidate(),
      max_date: undefined,
      date_now: undefined,

      summerInfo: "null",
      saveDataLoading: false,
      editDataLoading: false,
      manager_data: {},
      image: require("@/assets/avatar.jpg"),
      shift_times_list: [],
      evaluation_list: [],
      summer_schools: [],
      groups_list: [],
      // place_type: [],
      have_license: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],

      have_active: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],
      have_funded: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],

      is_garden: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],
      summerSchool: {
        contacts: [{}],
        username: null,
        password: null,
        groups: null,
        governorate: null,
        directorate: null,
        school_type: 2,
        fk_summer: null,
        manager: {
          name_ar: null,
          gender: null,
          phone_number: null,
          country: null,
          governorate: null,
          fk_directorate: null,
        },
      },
      is_update: false,
      image_reset: 0,
      image_reset_logo: 0,
      disable_add: false,
      updated_id: undefined,

      active_tab: 0,
      validForms: {
        form1: false,
        form2: false,
        form3: false,
        // form4: false,
      },
      tab: undefined,
      tabs: [
        this.$t("school.main_data"),
        this.$t("summer.manager"),
        // this.$t("school.location_data"),
        this.$t("summer.supervising-authority"),
      ],
      evaluation_rule: [(value) => !!value || this.$t("globals.required_field")],
      fk_supervisor_name_rule: [
        (value) => {
          if (value == null || value.length < 1) return this.$t("globals.required_field");
          return 1;
        },
      ],
      school_username: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          !value ||
          value.length <= 64 ||
          `${this.$t("globals.biggest_number_of_character")} 64`,
      ],
      school_password: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          !value ||
          value.length <= 100 ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          !value ||
          value.length >= 8 ||
          `${this.$t("globals.lowest_number_of_character")} 8`,
      ],
      required_rule: [(value) => !!value || this.$t("globals.required_field")],
      school_name_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A0-9 ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 60) ||
          `${this.$t("globals.biggest_number_of_character")} 60`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],

      funded_source_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A0-9 ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 150) ||
          `${this.$t("globals.biggest_number_of_character")} 150`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],

      school_name_en_rule: [
        (value) =>
          !value ||
          /^[A-Za-z0-9- ]+$/.test(value) ||
          this.$t("globals.must_be_letters_en"),
        (value) =>
          (value && value.length <= 30) ||
          !value ||
          `${this.$t("globals.biggest_number_of_character")} 30`,
        (value) =>
          (value && value.length >= 3) ||
          !value ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      ministry_name_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      education_office_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      headmaster_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      address: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          (value && value.length <= 50) ||
          `${this.$t("globals.biggest_number_of_character")} 50`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      place_solitude_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      place_type_rule: [(value) => !!value || this.$t("globals.required_field")],
      place_name_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          value.length <= 100 || `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          value.length >= 3 || `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      coordinates_rule: [
        (value) =>
          !value ||
          /^[A-Za-z  0-9]+$/.test(value) ||
          this.$t("globals.must_be_letters_and_numbers_en"),
        (value) =>
          !value ||
          value.length <= 100 ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          !value ||
          value.length >= 3 ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      license_number_rule: [
        (value) => {
          if (value == null || value == undefined)
            return this.$t("globals.required_field");
          if (value.length < 3)
            return `${this.$t("globals.lowest_number_of_character")} 3`;
          if (value.length >= 15) {
            return `${this.$t("globals.biggest_number_of_character")} 14`;
          }
          return true;
        },
        (value) => /^[0-9]+$/.test(value) || this.$t("globals.must_be_numbers"),
      ],
      class_count_rule: [
        (value) => {
          if (value == null || value == undefined)
            return this.$t("globals.required_field");
          if (value.length > 2) {
            return `${this.$t("globals.biggest_number_of_character")} 99`;
          }
          return true;
        },
        (value) => /^[0-9]+$/.test(value) || this.$t("globals.must_be_numbers"),
      ],
      country_rule: [(value) => !!value || this.$t("globals.required_field")],
      governorate_rule: [(value) => !!value || this.$t("globals.required_field")],
      directorate_rule: [(value) => !!value || this.$t("globals.required_field")],
      license_image_rule: [(value) => !!value || this.$t("globals.required_field")],
      establish_date_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) => {
          return new Date(value) < this.today || "لا يمكن اختيار تاريخ مستقبلي";
        },
      ],
      school_type_rule: [(value) => !!value || this.$t("globals.required_field")],
      students_type_rule: [(value) => !!value || this.$t("globals.required_field")],
      school_logo_rule: [(value) => !!value || this.$t("globals.required_field")],
      is_garden_rule: [
        (value) => value == true || value == false || this.$t("globals.required_field"),
      ],
      shift_time_rule: [(value) => !!value || this.$t("globals.required_field")],
      have_license_rule: [
        (value) => value == true || value == false || this.$t("globals.required_field"),
      ],

      have_active_rule: [
        (value) => value == true || value == false || this.$t("globals.required_field"),
      ],
      note_rule: [
        (value) =>
          value == undefined ||
          value.length <= 250 ||
          `${this.$t("globals.biggest_number_of_character")} 250`,
      ],
      contact_type_rule: [(value) => !!value || this.$t("globals.required_field")],
      rules: {
        governorate: [(value) => !!value || this.$t("globals.required_field")],
        directorate: [(value) => !!value || this.$t("globals.required_field")],
        accommodation: [(value) => !!value || this.$t("globals.required_field")],
        fk_summer: [(value) => !!value || this.$t("globals.required_field")],
      },

      // To make alert messages for unsaved data in forms
      has_unsaved_data: false,
      today: this.getCurrentDate(),
    };
  },

  watch: {
    summerSchool: {
      // handler() {
      //   this.has_unsaved_data = true;
      // },
      deep: true,
    },
  },

  components: {
    ImageInput,
    DatetimePicker,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getShiftTimesList();
      await this.getEvaluationList();
      await this.getStudentType();
      await this.getContactTypes();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getVillages();
      await this.getSchools();

      await this.getSupervisors();
      await this.getPlaceTypesList();
      await this.getShoolTypes();
      await this.getGenders();
      await this.axios.get(`${this.base_url}groups/`).then((response) => {
        this.groups_list = response.data;
      });

      if (this.role == 2) {
        this.fk_governorate = this.user_governorate;
      }
      if (this.role == 3) {
        this.fk_directorate = this.user_directorate;
        // this.getSummerSchools()
      }
      if (this.role == 3) {
        this.getDirectorateData();
      }

      this.summerInfo = this.user_school_summer;
      if (this.summerInfo != "null" && (this.role == 4 || this.role == 5)) {
        this.is_update = true;
        await this.getSummerSchoolDataById(this.summerInfo);
      }
      if (this.id) {
        this.is_update = true;
        await this.getSummerSchoolDataById(this.id);
      } else {
        this.summerSchool.manager.country_id = this.countries[0].id;
        this.summerSchool.country_id = this.countries[0].id;
        if (this.user_governorate) {
          this.summerSchool.governorate_id = this.user_governorate;
        }
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      contact_type_list: (state) => state.summer_globals.contact_type,
      student_type: (state) => state.summer_globals.student_type,
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      contact: (state) => state.school.contact,
      user: (state) => state.User,
      summerSchoolData: (state) => state.summer.summerSchoolData,
      supervisors: (state) => state.summer.supervisors,
      placeTypesList: (state) => state.summer.placeTypesList,
      shoolTypes: (state) => state.summer.shoolTypes,
      genders: (state) => state.summer.genders,
      role: (state) => state.role,
      user_school_summer: (state) => state.user_school_summer,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterVillages: "school/getVillageByDirectorates",
      filterSchools: "school/getSchoolByVillages",

    }),
  },
  methods: {
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getVillages: "school/getVillages",
      getSchools:"school/getSchools",

      getContact: "school/getContact",
      getContactTypes: "summer_globals/getContactType",
      getStudentType: "summer_globals/getStudentType",
      getSupervisors: "summer/getSupervisors",
      getPlaceTypesList: "summer/getPlaceTypesList",
      getGenders: "summer/getGenders",
      getShoolTypes: "summer/getShoolTypes",
      getEmployees: "summer/getEmployees",
    }),

    // async validateAndSubmit(){
    //   let invalidTab = null;
    //   // validate each form and determine witch form has the error
    //   for (let i=1;i<=4;i++){
    //     const form = this.$refs[`form${i}`];
    //     const isValid = await form.validate();

    //     if(!isValid && invalidTab === null ){
    //       invalidTab = i-1;
    //     }
    //     if(invalidTab !== null){
    //       this.active_tab = invalidTab
    //     }else{
    //       alert("Submitted Successfully")
    //     }
    //   }

    // },

    getCurrentDate() {
      const now = new Date();
      return now;
    },

    handleInput() {
      this.has_unsaved_data = true;
    },
    handleBeforeUnload(event) {
      if (this.has_unsaved_data) {
        event.preventDefault();
        event.returnValue =
          "هناك معلومات لم تقم بحفظها في هذا الشاشة؛ هل انت متاكد من أنك تريد المغادرة؟";
      }
    },
    confirmNavigation() {
      return window.confirm(
        "هناك معلومات لم تقم بحفظها في هذا الشاشة؛ هل انت متاكد من أنك تريد المغادرة؟"
      );
    },
    // async getSummerSchools(){
    //   await this.axios.get(`${this.base_url}api/summer/school-select-list`, {
    //   params:{
    //     directorate:this.summerSchool.directorate,
    //     governorate:this.summerSchool.governorate_id
    //   }
    //   }).then(response=>{
    //       this.summer_schools=response.data
    //   })
    // },
    getSummerSchoolDateOfEstablishment(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.summerSchool.date_of_establishment = date;
    },
    getDirectorateData() {
      this.axios(`${this.base_url}directorate/data/`).then((response) => {
        this.summerSchool.country_id = response.data.country;
        this.summerSchool.governorate_id = response.data.governorate;
        this.summerSchool.fk_directorate = response.data.directorate;
      });
    },

    async getSummerSchoolDataById(id) {
      let summerSchoolData = (
        await this.axios.get(`${this.base_url}api/summer/school/${id}`)
      ).data;
      this.summerSchool = summerSchoolData.summer_data;
      let list = summerSchoolData.contact_data.map((element) => ({
        contact_type: element.type,
        contact: element.summer_contact,
      }));
      this.summerSchool.contacts = list;
      let summer = { ...this.summerSchool };
      if (summer.logo) {
        summer.logo = this.base_url + this.summerSchool.logo;
      }
      if (summer.license_image) {
        summer.license_image = this.base_url + this.summerSchool.license_image;
      }
      this.summerSchool = summer;
    },
    async getShiftTimesList() {
      this.shift_times_list = (
        await this.axios.get(this.base_url + "api/summer/choices/shift-time")
      ).data;
    },
    async getEvaluationList() {
      this.evaluation_list = (
        await this.axios.get(this.base_url + "api/summer/choices/evaluation")
      ).data;
    },
    addContact() {
      this.summerSchool.contacts.push({ contact_type: undefined, contact: undefined });
    },
    removeContact() {
      if (this.summerSchool.contacts.length > 1) this.summerSchool.contacts.pop();
    },
    addSupervisor() {
      this.summerSchool.fk_supervisor.push({ type: undefined, name: undefined });
    },
    removeSupervisor() {
      if (this.summerSchool.fk_supervisor.length > 1)
        this.summerSchool.fk_supervisor.pop();
    },

    resetWindow(windowNumber) {
      if (windowNumber === 0) {
        this.$refs.form1.reset();
      } else if (windowNumber === 1) {
        this.$refs.form2.reset();
      } else if (windowNumber === 2) {
        this.$refs.form3.reset();
      } 
      // else if (windowNumber === 3) {
      //   this.$refs.form4.reset();
      // }
    },

    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.summerSchool = {
        contacts: [{}],
        manager: {},
        logo: "",
        license_image: "",
        school_type: 2,
      };
      this.image_reset++;
      this.image_reset_logo++;
      this.v$.$reset();
      this.tab = 0;
      if (this.role == 3) this.getDirectorateData();
      if (this.role == 2) {
        this.summerSchool.governorate_id = this.user_governorate;
        this.summerSchool.manager.governorate_id = this.user_governorate;
        this.summerSchool.country_id = 1;
        this.summerSchool.manager.country_id = 1;
      }
    },
    checkContactType(value, type) {
      if (value == undefined || value.length == 0)
        return this.$t("globals.required_field");
      let number = /^\d+$/;
      if (type == 1) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 8) return `${this.$t("globals.max_numbers")} 8`;
        if (value.length < 6) return `${this.$t("globals.min_numbers")} 6`;
      }
      if (type == 2) {
        let email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.test(value)) return this.$t("errors.email");
        if (value.length > 100) return this.$t("globals.max_characters");
      }
      if (type == 3) {
        let number = /^7\d*$/;
        if (!number.test(value)) return this.$t("globals.must-begin-with-7");
        if (value.length > 14) return `${this.$t("globals.max_numbers")} 14`;
        if (value.length < 9) return `${this.$t("globals.min_numbers")} 9`;
      }
      if (type == 4) {
        if (value.length > 100) return this.$t("globals.max_characters");
      }
      if (type == 5) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 14) return this.$t("globals.max_numbers");
        // if (value.length < 7) return this.$t("globals.min_numbers");
      }
      if (type == 6) {
        let telegram_regex = /^[a-zA-Z0-9_]{5,32}$/;
        if (!telegram_regex.test(value)) return this.$t("globals.must_be_telegram");
      }

      return true;
    },
    saveLicenseImage(event) {
      this.summerSchool.license_image = event;
    },
    imageReset(event) {
      this.image_reset = event;
    },
    saveSchoolLogo(event) {
      this.summerSchool.logo = event;
    },
    imageResetLogo(event) {
      this.image_reset_logo = event;
    },

    async saveSummer() {
      // let invalidTab = null;
      // for (let i = 1; i <= 4; i++) {
      //   // await this.$refs[`form${i}`].validate();
      //   const form = this.$refs[`form${i}`];
      //   const { valid } = await form.validate();

      //   if (!valid && invalidTab === null) {
      //     invalidTab = i - 1;
      //   }
      // }
      // console.log(invalidTab);
      // if (invalidTab !== null) {
      //   this.tab = invalidTab;
      // }

      // await this.$refs.form2.validate();
      // await this.$refs.form3.validate();
      // await this.$refs.form4.validate();

      for (let i=1;i<=3;i++){
        
        await this.$refs[`form${i}`].validate();
        // if(valid === false)
        //   {
        //     this.tab = i -1
        //     return
        //     }

      }
      console.log("Shehab")
      const  {valid}  = await this.$refs.form1.validate();



      if (
        this.summerSchool.manager.name_ar ||
        this.summerSchool.manager.gender ||
        this.summerSchool.manager.phone_number
      ) {
        this.v$.summerSchool.manager.$validate();
      }
      console.log(valid && !this.v$.summerSchool.manager.$error);
       
      if (valid && !this.v$.summerSchool.manager.$error) {

        this.saveDataLoading = true;
        let form = new FormData();
        this.summerSchool.data_entry = this.user;
        form.append("logo", this.summerSchool.logo);
        form.append("license_image", this.summerSchool.license_image);
        form.append("data", JSON.stringify(this.summerSchool));
        let result = await this.axios
          .post(this.base_url + "api/summer/school", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data == 1) {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } else {
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.resetForm();
            }
            this.has_unsaved_data = false;
            setTimeout(() => {
              this.$router.push({ name: "summer-camps-list" });
            }, 1500);
            this.saveDataLoading = false;
          })
          .catch((error) => {
            this.disable_add = false;
            this.saveDataLoading = false;
            let error_message = null;
            try {
              if (error.response) {
                error_message = error.response.data;
              }
              if (
                error_message &&
                error_message["non_field_errors"] &&
                error_message["non_field_errors"].includes("name_ar, fk_directorate")
              ) {
                this.$emit("errorAlert", this.$t("summer.employee.unique"));
              } else if (error?.response?.data?.includes("School admin")) {
                this.$emit("errorAlert", "حساب مدير الدورة المدرسية موجود بالفعل");
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      }
    },

    async updateSummer() {
      if (
        this.summerSchool.manager.name_ar ||
        this.summerSchool.manager.gender ||
        this.summerSchool.manager.phone_number
      ) {
        this.v$.summerSchool.manager.$validate();
      }
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.v$.summerSchool.manager.$error) {

        this.editDataLoading = true;
        this.summerSchool.updated_by = this.user;
        if (!(this.summerSchool.license_image instanceof File)) {
          delete this.summerSchool.license_image;
        }

        if (!(this.summerSchool.logo instanceof File)) {
          delete this.summerSchool.logo;
        }
        let types_contact = [];
        let summer_contacts = [];
        this.summerSchool.contacts.forEach((element) => {
          types_contact.push(element.contact_type);
          summer_contacts.push(element.contact);
        });
        delete this.summerSchool.types_contact;
        delete this.summerSchool.summer_contacts;
        this.summerSchool.types_contact = types_contact;
        this.summerSchool.summer_contacts = summer_contacts;
        if (this.image_reset) this.summerSchool.licenseImageDelete = true;
        else {
          if (this.summerSchool.licenseImageDelete)
            delete this.summerSchool.licenseImageDelete;
        }
        if (this.image_reset_logo) this.summerSchool.logoDelete = true;
        else {
          if (this.summerSchool.logoDelete) delete this.summerSchool.logoDelete;
        }

        let result = await this.axios
          .put(`${this.base_url}api/summer/school/${this.id}`, this.summerSchool, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.editDataLoading = false;
            if (response.data == -1) {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } else {
              this.$emit("successAlert", this.$t("globals.data_updated"));
              this.has_unsaved_data = false;
              setTimeout(() => {
                this.$router.push({ name: "summer-camps-list" });
              }, 1500);
            }
          })
          .catch((error) => {
            this.editDataLoading = false;
            let error_message = null;
            try {
              error_message = error.response.data;
              if (
                error_message &&
                error_message["non_field_errors"].includes(
                  "الحقول name_ar, fk_directorate يجب أن تشكل مجموعة فريدة."
                )
              )
                this.$emit("errorAlert", "حساب مدير الدورة المدرسية موجود بالفعل");
              else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
            } catch (error) {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      }
    },
  },
  validations() {
    return {
      summerSchool: {
        manager: {
          name_ar: {
            maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
          gender: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },

          phone_number: {
            required: helpers.withMessage(this.$t("errors.required"), required),
            startWithSeven: helpers.withMessage(
              this.$t("globals.must-begin-with-7"),
              (value) => /^7\d*$/.test(value)
            ),

            maxLength: helpers.withMessage(
              this.$t("globals.max_numbers") + " 13",
              maxLength(13)
            ),
            minLength: helpers.withMessage(
              this.$t("globals.lowest_number_of_character") + "9",
              minLength(9)
            ),
          },

          country_id: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
          governorate_id: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
          fk_directorate: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
        },
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    if (this.has_unsaved_data) {
      const shouldLeave = this.confirmNavigation();
      if (shouldLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  mounted() {

    // setTimeout(() => {
    //   this.tab = 3;
    // }, 100);

    // setTimeout(() => {
    //   this.tab = 2;
    // }, 100);

    // setTimeout(() => {
    //   this.tab = 1;
    // }, 100);

    // setTimeout(() => {
    //   this.tab = 0;
    // }, 100);


    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>
<style scoped>
img {
  max-width: 270px;
}
</style>
