<template>
  <DataTable
    v-if="$checkPermissions('summer_center.change_commitee')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_commitee', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_commitee', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      $checkPermissions('summer_center.add_commitee', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12" md="6">
              <VAutocomplete
                :items="commitees_show"
                v-model="committee.type_show"
                prepend-inner-icon="mdi-account-tie-outline"
                item-title="name"
                item-value="id"
                autofocus
                :label="`${$t('summer.show-of-commit')} *`"
                persistent-hint
                :rules="rules.required_rule"
              ></VAutocomplete>
            </VCol>


            <VCol cols="12" md="6">
              <VAutocomplete
                :items="commitees_type_mode"
                v-model="committee.type"
                prepend-inner-icon="mdi-account-tie-outline"
                item-title="name"
                item-value="id"
                :label="`${$t('summer.type-of-commit')} *`"
                persistent-hint
                :rules="rules.required_rule"
              ></VAutocomplete>
            </VCol>


            <VCol cols="12" md="6">
              <VTextField
                v-model="committee.name"
                prepend-inner-icon="mdi-account-group-outline"
                :label="`${$t('school.committee_name')} *`"
                :placeholder="$t('school.committee_name')"
                density="compact"
                type="text"
                clearable
                :rules="rules.required_rule"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
              <VTextField
                v-model="committee.purpose"
                prepend-inner-icon="mdi-account-multiple-outline"
                :label="`${$t('summer.purpose-of-commit')} *`"
                :placeholder="$t('summer.purpose-of-commit')"
                density="compact"
                type="text"
                clearable
                :rules="rules.required_rule"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="committee.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                :label="$t('globals.note')"
                counter
                no-resize
                density="compact"
                rows="2"
                :rules="rules.note"
              >
              </VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

import { required, helpers, maxLength } from "@vuelidate/validators";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      committees: [],
      saveDataLoading: false,
      editDataLoading: false,
      del_loading: false,
      rules: {
        required_rule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
        phone_number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[0-9+]+$/.test(value) || this.$t("globals.must_be_numbers"),
          (value) => /^7\d*$/.test(value) || this.$t("globals.must-begin-with-7"),
        ],
      },

      v$: useValidate(),
      committee: {
        // name_ar: undefined,
        // name_en: undefined,
        // degree_max: undefined,
        // degree_min: undefined,

        // note: undefined,
        // data_entry: undefined,
        // updated_by: undefined,
        // id: undefined,
      },
      // committeeType: [
      //   {
      //     name: "اجتماعي",
      //     id: 1,
      //   },
      //   {
      //     name: "ثقافي",
      //     id: 2,
      //   },
      //   {
      //     name: "ثقافي",
      //     id: 2,
      //   },
      // ],
      is_update: false,
      del_dialog: false,
      estimate_del_id: undefined,

      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("school.committee_name"), key: "name" },
        { title: this.$t("summer.purpose-of-commit"), key: "purpose" },
        { title: this.$t("summer.type-of-commit"), key: "committee_type" },
        { title: this.$t("summer.show-of-commit"), key: "committee_type_show" },
        {
          title: this.$t("globals.note"),
          key: "note",
          sortable: false,
          show: false,
        },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      name_ar_rule: [(value) => !!value || this.$t("globals.required_field")],
      name_en_rule: [(value) => !!value || this.$t("globals.required_field")],
      pagination: {},
    };
  },
  validations() {
    return {
      committee: {
        name_ar: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        name_en: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },

        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getCommiteesTypeMode()
      await this.getUserRole()
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    ...mapState({
      user: (state) => state.User,
      commitees_type_mode: (state) => state.summer_globals.commitees_type_mode,
      commitees_show: (state) => state.summer_globals.commitees_show,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      if (this.committees) {
        return JSON.parse(JSON.stringify(this.committees));
      }
    },
    
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      await this.axios
        .get(this.base_url + "api/summer/committee", {
          params: {
            search: search,
            page: page,
            page_size: per_page,
            sort_by: ordering,
          },
        })
        .then((response) => {
          if (response.data) {
            this.committees = response.data.results;
            this.pagination = response.data.pagination;
          }
        });
    },
    ...mapActions({
      getCommiteesTypeMode: "summer_globals/getCommiteesTypeMode",
      getUserRole: "summer_globals/getUserRole",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.v$.committee.$reset();
      this.is_update = false;
      this.committee.id = undefined;
      this.updated_id = undefined;
    },
    editItem(data) {
      this.committee = Object.assign({}, data);
      this.addDialog = true;
      this.is_update = true;
    },
    async saveItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveDataLoading = true;
        this.committee.data_entry = this.user;

        let result = await this.axios
          .post(this.base_url + "api/summer/committee", this.committee)
          .then((response) => {
            this.saveDataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;

            if (error.response.data.name) {
              this.$emit(
                "errorAlert",
                this.$t("globals.error_in_repeted_data")
              );
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        // this.committee.data_entry = this.user;
        this.committee.updated_by = this.user;
        let result = await this.axios.put(`${this.base_url}/api/summer/committee/${this.committee.id}`,this.committee)
          .then((responce) => {
            this.editDataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.editDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios.delete(`${this.base_url}/api/summer/committee/${delete_id}`)
          .then(() =>status = true)
          .catch((error) => status = error);
        return status
      }
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
