<template>
  <DataTable
    v-if="$checkPermissions('globaltable.view_task')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="$checkPermissions('globaltable.delete_task',['sys_admin','gen_admin'])"
    :edit_perm="$checkPermissions('globaltable.change_task',['sys_admin','gen_admin'])"
    :add_perm="$checkPermissions('globaltable.add_task',['sys_admin','gen_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
   <v-card flat >
      <v-card-text class="pb-0">
        <VForm ref="form"  @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-2">
            <VCol  cols="12" >
                <VTextField
                  v-model="item.name"
                  autofocus
                  :label="`${$t('summer.task')} *`"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-clipboard-check-outline"
                  :rules="rules.title"
                ></VTextField>
            </VCol>
            <VCol  cols="12" >
                <VTextarea
                  v-model="item.note"
                  color="indigo"
                  clearable
                  :label="$t('globals.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  prepend-inner-icon="mdi-note-outline"
                  :rules="rules.note"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="mx-4"
      >
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading: false,
      deleteDataLoading: false,
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      item: {},
      sortBy: [
        {
          key: "title",
          order: "asc",
        },
      ],
      rules: {
        title: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.task"), key: "name" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.item = {};
      this.addDialog = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(item) {
      this.item = Object.assign({},item);
      this.addDialog = true
      this.is_update = true;
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.item.data_entry = this.user;
        let result = await this.axios.post(this.base_url + "api/task/", this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.item = {};
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.resetForm();
          });
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.item.updated_by = this.user;
        let result = await this.axios.put(`${this.base_url}api/task/${this.item.id}/`, this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status ;
        this.delete_id = delete_id;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/task/${delete_id}/`, {
          })
          .then((response) => {
            status = true
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            status = error
            this.force_delete=false
            this.deleteDataLoading = false;
          });
          return status
        }
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/task/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
