<template>
    <v-card  v-if="$checkPermissions('summer_center.view_teachersubjectsummer',['sys_admin','summer_admin','user'])"> 
        <v-card-text>
            <!-- ##########    data Table Server ########### -->
            <!-- ######## Header Of Data Table Server ####### -->
            <div class="d-flex justify-space-between mb-2"  v-if="$checkPermissions('summer_center.view_teachersubjectsummer',['sys_admin','summer_admin','user'])">
                <div class="d-flex" style="flex:10;">
                    <v-menu
                    :close-on-content-click="false">
                        <template
                            v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                variant="outlined"
                                color="#ccc"
                                size="small">
                                <v-icon style="color:#5e7e96;" class="me-2">mdi-eye-settings-outline</v-icon>
                                <v-icon style="color:#5e7e96;" >mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-checkbox v-for="header in headers" :key="header.key"
                                    :label="header.title"
                                    :value="header.key"
                                    color="indigo"
                                    v-model="selectedHead"
                                    density="compact"
                                    hide-details
                                >
                                </v-checkbox>
                            </v-card-text>
                        </v-card>
                        
                    </v-menu>

                    <v-text-field 
                        color="indigo"
                        v-model="txt_search"
                        style="border-radius:30px;border:1px solid #ccc; color:black;height:30px; width:90%;"
                        class=" search " 
                        hide-details 
                        prepend-inner-icon="mdi-magnify" 
                        single-line
                        :placeholder="$t('globals.search-here')" 
                        @input="getLecturesAttendance(page=1)"
                        density="compact"
                        variant="text"
                        >
                    </v-text-field>
                </div>
                <div class="d-flex  justify-end" style="flex:1;" >
                    <VBtn v-if="$checkPermissions('summer_center.add_teachersubjectsummer',['sys_admin','summer_admin','user'])" class="bg-success" @click="addLectureAttendane" size="small">
                        <span class="text-white">{{$t('globals.create')}}</span>
                        <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
                    </VBtn>
                </div>
            </div>
            <!-- ######## End Header Of Data Table Server ####### -->
            <div >
                <v-data-table-server
                    :headers="selectedHeaders"
                    :items="tableList"
                    item-key="id"
                    fixed-footer
                    density="compact"  
                    class="elevation-1"
                    id="vDataTable"
                    v-model:sort-by="sortBy"
                >   
                    <template v-slot:column.checkbox="{ column }">
                        <v-checkbox 
                        hide-details
                        density="compact">
                        </v-checkbox>
                    </template>
                    <template v-slot:item="{item}">
                        <tr class="row-hover">
                            <td v-for="(val,key) in item.columns" :key="key">
                                <v-checkbox 
                                    v-if="key=='checkbox'"
                                    hide-details
                                    density="compact">
                                </v-checkbox>
                                <span v-else> {{val}}</span>
                                <span v-if="key==='actions'">
                                    
                                    <v-btn variant="text"  v-if="$checkPermissions('summer_center.change_teachersubjectsummer',['sys_admin','summer_admin','user'])" class="me-1 v-btn-icon" size="small" @click="editLectureAttendance(item.raw)" >
                                        <v-icon color="warnning">mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    <v-btn variant="text"  v-if="$checkPermissions('summer_center.delete_teachersubjectsummer',['sys_admin','summer_admin','user'])" size="small"  class="v-btn-icon" @click="selected_lecture_attendance=item.raw.id,dialog=true" >
                                        <v-icon color="error">mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:bottom >

                        <div class="d-flex "  id="pagination-bar">
                            <v-pagination  
                                v-model="page" 
                                :length="length"   
                                density="compact"
                                show-first-last-page
                                >
                            </v-pagination>
                            <div class="d-flex ">
                                <v-select 
                                    v-model="perPage" 
                                    class="pa-0" 
                                    :items="itemsPerPage"  
                                    item-value="value" 
                                    item-title="text" 
                                    density="compact" 
                                    hide-details
                                    variant="text"
                                    >
                                </v-select>
                                <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{totalItems}} ] {{$t('globals.item')}}</span>
                            </div>
                        </div>
                    </template>
                    
                </v-data-table-server>
            </div>
        </v-card-text>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <delete-dialog
      v-model="dialog"
      :loading="deleteloading"
      @confirm-delete="deleteLectureAttendance(selected_lecture_attendance)"
    />
    <!-- ############### end Dialog Delete  ################### -->
</template>
<script>
import useValidate from '@vuelidate/core'
import {mapState,mapActions,mapGetters} from 'vuex'
import {required,helpers,maxLength,numeric} from '@vuelidate/validators'
export default{
    name:"LecturesList",
    async created(){
        try {
            await this.$store.commit('updateLoadingValue',true)
            this.user=this.$store.getters.getuser
            await this.getClasses()
            await this.getDivisions()
            await this.getAllTeachers()   
            await this.getClassAssignHall();
            await this.getLecturesAttendance()
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
            
        }

    },
    data(){
        return{
            v$: useValidate(),
            txt_search:null,
            dialog:false,
            addDialog:false,
            overlay:false,
            update:false,
            alert:false,
            deleteloading:false,
            user:null,
            sortBy:[
                { key:'fk_level__name_ar',order:'asc'},
                { key:'fk_subject__name',order:'asc'},
                { key:'fk_empoylee__name_ar',order:'asc'},
                { key:'fk_division__name',order:'asc'}
            ],
            alert:{
                color:undefined,
                icon:undefined,
                title:undefined,
                message:undefined
            },
            selectedHead:['phase_level_name','fk_division','subject_name','teacher_name','year_name','note','actions'],
            selected_lecture_attendance:null,
            fk_level:null,
            fk_subject:null,
            fk_empoylee:null,
            fk_division:null,
            from_date:null,
            lectures_attendance:[],
            to_date:null,
            headers:[
                {title:this.$t('summer.level.name_ar'),key:"phase_level_name"},
                {title:this.$t('division.name'),key:"fk_division"},
                {title:this.$t('subject.name'),key:"subject_name"},
                {title:this.$t('teacher.name'),key:"teacher_name"},
                {title: this.$t('year.name'), key: "year_name"},
                {title:this.$t('globals.note'),key:"note"},
                {title:this.$t('globals.actions'),key:"actions", sortable: false}
            ],
            perPage:10,
            length: 0,
            itemsPerPage: [
                {value:10,text:"10"},
                {value:25,text:"25"},
                {value:50,text:"50"},
                {value:100,text:"100"},
                {value:1000000,text:this.$t("globals.all")},
            ],
            page:1,
            totalItems:null,
            pagination: {},
            class_divisions_hall: [],
        }
    },
    computed:{
        
        ...mapState({
            teachers:(state)=>state.summer.teachers,
            classes:(state)=>state.summer.levels,
            divisions:(state)=>state.summer.divisions,
            // subjects:(state)=>state.summer.subjects,
            teacher_class:(state)=>state.school.teacher_class,   
        }),
        ...mapGetters({
           getClassDivisions: "summer/getClassDivisions",
        }),
        order_data(){
            try {
                return this.sortBy[0].order=='desc'?`-${this.sortBy[0].key}`:this.sortBy[0].key
            } catch (error) {
                return 'id'
            }
        },
        class_divisions() {
            if (this.fk_level) {
            return this.getClassDivisions(this.fk_level);
            }
        },
        classesName() {
            return this.$i18n.locale == "ar" ? "level_with_phase_name" : "name_en";
        },
        tableList(){
            var list =JSON.parse(JSON.stringify(this.lectures_attendance))
            list.forEach(element => {
                let fk_level=element.fk_level
                let fk_empoylee=element.fk_empoylee
                let fk_subject=element.fk_subject
                let fk_division=element.fk_division
                element.fk_level=this.getClassName(fk_level)
                element.fk_empoylee=this.getTeacherName(fk_empoylee)
                element.fk_division=this.getDivisionName(fk_division)
                
            });
            return list
        },
        class_teachers(){
            var teacher_data=this.teachers
            if(this.fk_level){
                var teacher_id=this.teacher_class.results.filter(item=>item.fk_level==this.fk_level).map(item=>item.fk_empoylee)
                var teacher_unique=[...new Set(teacher_id)]
                teacher_data=[]
                teacher_unique.forEach(el=>{
                    teacher_data.push({id:el,name_ar:this.getTeacherName(el)})
                })
            }
            return teacher_data
        },
        selectedHeaders(){
            return this.headers.filter(header=>
            this.selectedHead.includes(header.key))
        }
    },

    validations(){
        return{
            lecture_attendance:{
                name: {
                    required:helpers.withMessage(this.$t('errors.required'),required),
                    maxLength:helpers.withMessage(this.$t('errors.max-entry'),maxLength(100)),
                },
                lecture_date: {
                    required:helpers.withMessage(this.$t('errors.required'),required),
                },
                note: {
                    maxLength:helpers.withMessage(this.$t('errors.max-entry'),maxLength(250)),
                },
                fk_level: {
                    required:helpers.withMessage(this.$t('errors.required'),required),

                },
                fk_subject: {
                    required:helpers.withMessage(this.$t('errors.required'),required),

                },
                fk_empoylee: {
                    required:helpers.withMessage(this.$t('errors.required'),required),

                },
                data_entry: {},
                updated_by: {}
            },
        }
    },
    methods:{
        ...mapActions({
            getClasses:"summer/getLevels",
            // getSubjects:"summer/getSubjects",
            getAllTeachers:"summer/getAllTeachers",
            getTeacherClass: "school/getTeacherClass",
            getDivisions: "summer/getDivisions",
            getClassAssignHall: "summer/getClassAssignHall",
        }),
        addLectureAttendane(){
            this.$router.push({name:"assign-teacher_add"})
        },
        getClassName(id){
            var item=this.classes.find(item=>item.id===id)
            return item?(this.$i18n.locale=='ar'?item.name_ar:item.name_en):''
        },
        getSubjectName(id){
            var item=this.subjects.find(item=>item.id===id)
            return item?(this.$i18n.locale=='ar'?item.name_ar:item.name_en):''
        },
        getTeacherName(id){
            var item=this.teachers.find(item=>item.id===id)
            return item?(this.$i18n.locale=='ar'?item.name_ar:item.name_en):''
        },
        getDivisionName(id){
            var item=this.divisions.find(item=>item.id===id)
            return item?(this.$i18n.locale=='ar'?item.name:item.name):''
        },
        
        editLectureAttendance(item){
            this.$router.push(`${item.id}/edit/`)
        },
        
        async deleteLectureAttendance(id){
                  this.deleteloading = true;
            await this.axios.delete(`${this.base_url}api/summer/teacher-subject-summer/${id}`)
                .then(response=>{
                    this.$emit("successAlert", this.$t("globals.data_deleted"));
                    this.deleteloading = false;
                    this.dialog=false
                    this.getLecturesAttendance()
                })
        },
        async getLecturesAttendance(page=1){
            await this.axios(`${this.base_url}api/summer/teacher-subject-summer`,{
                params: {
                    search:this.txt_search,
                    page:page,
                    page_size:this.perPage,   
                    sort_by: this.order_data,           
                },                      
            }).then((response)=>{
                this.lectures_attendance=response.data.results
                this.pagination = response.data.pagination
                this.length = response.data.pagination.num_pages
                this.totalItems = response.data.pagination.count
            })

        }
    },
    watch:{
        perPage(){
            this.getLecturesAttendance()
        },
        page(){
            this.getLecturesAttendance(this.page) 
        },
        order_data(){
            this.getLecturesAttendance(this.page)
        }
    }
    
    
}
</script>

<style scoped>
.row-hover:hover{
    background: #d4d3d3;
}

</style>