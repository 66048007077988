<template>
  <v-card
    class="justify-center"
    v-if="
      $checkPermissions('summer_center.view_teachersubjectsummer', [
        'sys_admin',
        'summer_admin',
        'user',
      ])
    "
  >
    <v-card-text class="pb-0">
      <v-form ref="form" @submit.prevent="saveLectureAttendance">
        <v-row class="mt-2">
          <v-col cols="12" md="5" sm="6">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_level"
              density="compact"
              :item-title="classesName"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              clearable
              :items="levels"
              :label="`${$t('summer.level.name_ar')} *`"
              :error-messages="
                v$.lecture_attendance.fk_level.$errors.map((e) => e.$message)
              "
              @update:modelValue="
                (lecture_attendance.fk_subject = null),
                  (lecture_attendance.fk_division = null),
                  getSubjects()
              "
            />
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_division"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-shape-outline"
              clearable
              :items="class_divisions"
              :label="`${$t('division.select')} *`"
              :error-messages="
                v$.lecture_attendance.fk_division.$errors.map((e) => e.$message)
              "
            />
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_subject"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              :items="subjects"
              @update:modelValue="lecture_attendance.fk_employee = null"
              :label="`${$t('subject.select')} *`"
              :error-messages="
                v$.lecture_attendance.fk_subject.$errors.map((e) => e.$message)
              "
            />
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_employee"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-account-tie"
              clearable
              :items="teachers"
              :label="`${$t('teacher.select')} *`"
              :error-messages="
                v$.lecture_attendance.fk_employee.$errors.map((e) => e.$message)
              "
            />
          </v-col>
          <v-col cols="12" md="10">
            <v-textarea
              color="indigo"
              v-model="lecture_attendance.note"
              hide-details="auto"
              density="compact"
              counter="250"
              clearable
              rows="3"
              prepend-inner-icon="mdi-note-outline"
              :label="$t('globals.note')"
              :error-messages="v$.lecture_attendance.note.$errors.map((e) => e.$message)"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-3">
      <v-btn
        v-if="
          !update &&
          $checkPermissions('summer_center.add_teachersubjectsummer', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        @click="saveLectureAttendance"
        class="bg-indigo"
        size="small"
        :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="
          update &&
          $checkPermissions('summer_center.change_teachersubjectsummer', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        @click="updateLectureAttendance(selected_lecture_attendance)"
        class="bg-success"
        size="small"
        :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="cleanLectureAttendanceForm" class="mx-3 bg-ccc" size="small">
        <span>
          {{ $t("globals.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "LectureAttendance",
  props: {
    id: Number,
  },
  async mounted() {
    if (this.id) {
      await this.axios
        .get(`${this.base_url}api/summer/teacher-subject-summer/${this.id}`)
        .then((response) => {
          this.lecture_attendance = response.data.results;
          this.getTeachers();
          this.update = true;
        })
        .catch((error) => {
          this.showAlert = true;
          this.alert.color = "error";
          this.alert.message = this.$t("alert.not-found.message");
          this.alert.title = this.$t("alert.not-found.title");
          this.alert.icon = "alert-circle-outline";
          setTimeout(() => {
            this.showAlert = false;
          }, 1500);
        });
    }
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getLevels();

      // this.fk_year = this.current_year;
      // console.log(this.current_year,'aaaaaaaaaaaaaaa')

      await this.getTeachers();
      await this.getDivisions();
      await this.getSubjects();
      await this.getClassAssignHall();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      teachers: [],
      subjects: [],
      txt_search: null,
      uniqeYearMessage: null,
      uniqeYearMMessage: null,
      loading: false,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,
      saveDataLoading: false,
      editDataLoading: false,
      user: null,
      selected_lecture_attendance: null,
      lecture_attendance: {
        note: "",
        fk_level: undefined,
        fk_subject: undefined,
        fk_employee: undefined,
        fk_division: undefined,
        data_entry: undefined,
        updated_by: undefined,
        fk_year: this.current_year,
      },

      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      lectures_attendance: (state) => state.school.lectures_attendance,
      levels: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      current_year: (state) => state.current_year,
    }),
    ...mapGetters({
      getDivisionName: "summer/getDivisionName",
      getClassDivisions: "summer/getClassDivisions",
    }),

    class_divisions() {
      if (this.lecture_attendance.fk_level) {
        return this.getClassDivisions(this.lecture_attendance.fk_level);
      }
    },
    classesName() {
      return this.$i18n.locale == "ar" ? "level_with_phase_name" : "name_en";
    },
    teachersName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    subjectsName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
  },

  validations() {
    return {
      lecture_attendance: {
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_employee: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_division: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",

      getDivisions: "summer/getDivisions",
      getClassAssignHall: "summer/getClassAssignHall",
    }),
    async getTeachers() {
      let teachers = await this.axios.get(this.base_url + "api/summer/get-teacher");
      this.teachers = teachers.data?.filter((teacher) => teacher.state);
    },
    async getSubjects() {
      if (this.lecture_attendance.fk_level != null) {
        let subjects = await this.axios.get(
          this.base_url + "api/summer/subjects-by-level",
          {
            params: {
              level: this.lecture_attendance.fk_level,
            },
          }
        );
        this.subjects = subjects.data;
      }
    },
    selectLectureAttendance(item) {
      this.selected_lecture_attendance = item.id;
      var temp_lecture = this.lectures_attendance.find(
        (element) => element.id == item.id
      );
      delete temp_lecture.id;
      this.lecture_attendance = Object.assign({}, temp_lecture);
      this.update = true;
    },
    submitLectureAttendance() {
      this.v$.$validate();
    },
    async saveLectureAttendance() {
      this.submitLectureAttendance();
      if (!this.v$.$error) {
        this.loading = true;
        this.lecture_attendance.data_entry = this.user;
        this.axios
          .post(
            `${this.base_url}api/summer/teacher-subject-summer`,
            this.lecture_attendance
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.loading = false;
            this.cleanLectureAttendanceForm();
          })
          .catch((error) => {
            this.loading = false;
            try {
              if (
                error.response.data.non_field_errors[0].includes(
                  "fk_subject, fk_level, fk_division, fk_summer, fk_year"
                )
              ) {
                this.$emit("errorAlert", this.$t("alert.failure.unique"));
              } else {
                this.$emit("errorAlert", this.$t("alert.failure.insert"));
              }
            } catch (e) {
              this.$emit("errorAlert", this.$t("alert.failure.insert"));
            }
          });
      }
    },
    cleanLectureAttendanceForm() {
      if (this.update) {
        this.update = false;
        this.$router.push({ name: "assign-teacher_add" });
      }
      this.v$.lecture_attendance.$reset();
      this.$refs.form.reset();
    },
    async updateLectureAttendance() {
      this.submitLectureAttendance();
      if (!this.v$.$error) {
        this.loading = true;
        this.lecture_attendance.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}api/summer/teacher-subject-summer/${this.id}`,
            this.lecture_attendance
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.loading = false;
            setTimeout(() => {
              this.cleanLectureAttendanceForm();
              this.$router.push({ name: "assign-teacher_list" });
            }, 1500);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
