<template>

  <VCard class="mb-2">
    <v-card-text>
      <VForm
        ref="form"
        v-if="
          $checkPermissions('summer_center.add_commitee', [
            'sys_admin',
            'summer_admin',
            'user',
            'gov_admin',
            'dir_admin',
          ])
        "
      >
        <VRow class="mt-2">
          <VCol xs="12" md="4">
            <VAutocomplete
              prepend-inner-icon="mdi-account-group-outline"
              :items="committee"
              v-model="membercomit.fk_commitee"
              item-title="name"
              item-value="id"
              :label="`${$t('school.committee_name')} *`"
              type="data"
              persistent-hint
              density="compact"
              hide-details="auto"
              :rules="rules.required_rule"
            ></VAutocomplete>
          </VCol>
        </VRow>
        <VCard
          class="pa-1 pt-0 mt-4 elevation-1"
          flat
          v-for="doc in commitfileds"
          :key="doc"
        >
          <VRow class="mt-2">
            <VCol cols="6" md="3" class="mt-1">
              <VTextField
                v-model="doc.name"
                prepend-inner-icon="mdi-account-circle-outline"
                :label="`${$t('summer.member_name')} *`"
                :placeholder="$t('summer.member_name')"
                density="compact"
                clearable
                hide-details="auto"
                :rules="rules.required_rule"
              >
              </VTextField>
            </VCol>
            <VCol cols="6" md="3" class="mt-1">
              <VTextField
                v-model="doc.adjective"
                prepend-inner-icon="mdi-account-outline"
                :label="`${$t('summer.member_adject')} *`"
                :placeholder="$t('summer.member_adject')"
                density="compact"
                clearable
                hide-details="auto"
                :rules="rules.required_rule"
              >
              </VTextField>
            </VCol>
            <VCol cols="6" md="3" class="mt-1">
              <VTextField
                v-model="doc.entity"
                prepend-inner-icon="mdi-map-marker-outline"
                :label="`${$t('summer.member_entity')} *`"
                :placeholder="$t('summer.member_entity')"
                density="compact"
                clearable
                hide-details="auto"
                :rules="rules.required_rule"
              >
              </VTextField>
            </VCol>
            <VCol cols="6" md="3" class="mt-1">
              <VTextField
                v-model="doc.phone_number"
                prepend-inner-icon="mdi-cellphone-text"
                :label="`${$t('summer.member_phonenumber')} *`"
                :placeholder="$t('summer.member_phonenumber')"
                density="compact"
                clearable
                type="number"
                hide-details="auto"
                :rules="rules.phone_number"
              >
              </VTextField>
            </VCol>

            <VCol cols="6" md="3" class="mt-1">
              <VTextField
                v-model="doc.id_card"
                prepend-inner-icon="mdi-card-account-details"
                :label="`${$t('globals.identity-id')} *`"
                :placeholder="$t('globals.add-identity-id')"
                density="compact"
                clearable
                type="number"
                hide-details="auto"
                :rules="rules.id_card"
              >
              </VTextField>
            </VCol>

            <VCol cols="12" class="mt-1">
              <VTextarea
                prepend-inner-icon="mdi-note-outline"
                v-model="doc.note"
                clearable
                color="indigo"
                :label="$t('globals.description')"
                :placeholder="$t('summer.enter_note')"
                :rules="directorate_comments_rule"
                counter="250"
                density="compact"
                hide-details="auto"
                rows="2"
              ></VTextarea>
            </VCol>
          </VRow>
        </VCard>
        <VRow class="mt-2 px-3">
          <VBtn
            v-if="!is_update_state"
            variant="elevated"
            size="small"
            elevation="4"
            density="compact"
            icon
            @click.prevent="addDefucltis()"
          >
            <v-tooltip activator="parent" location="top">
              {{ $t("summer.add-sup-auth") }}</v-tooltip
            >
            <VIcon icon="mdi-plus-thick" color="success"></VIcon>
          </VBtn>
          <VBtn
            v-if="!is_update_state"
            variant="elevated"
            size="small"
            elevation="4"
            icon
            density="compact"
            class="mx-1"
            @click.prevent="removeDefcultis()"
          >
            <v-tooltip activator="parent" location="top">
              {{ $t("summer.remove-sup-auth") }}</v-tooltip
            >
            <VIcon icon="mdi-minus-thick" color="error"></VIcon>
          </VBtn>
        </VRow>
      </VForm>
    </v-card-text>
    <VCardActions
      class="mx-1"
      v-if="
        $checkPermissions('summer_center.add_commitee', [
          'sys_admin',
          'summer_admin',
          'user',
          'gov_admin',
          'dir_admin',
        ])
      "
    >
      <VBtn
        :loading="loading"
        v-if="!is_update && $checkPermissions('summer_center.add_commitee')"
        class="bg-indigo"
        @click="savesloutionmembercomit()"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>

      <VBtn
        :loading="loading"
        v-if="
          is_update &&
          $checkPermissions('summer_center.add_commitee', [
            'sys_admin',
            'summer_admin',
            'user',
            'gov_admin',
            'dir_admin',
          ])
        "
        class="bg-green"
        @click="updatesloutionmembercomit()"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>

      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ######## Header Of Data Table  ####### -->
  </VCard>
  <delete-dialog
    v-model="del_dialog"
    :loading="deleteProgress"
    @confirm-delete="deletesloutionmembercomit()"
  />
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      is_update_state: false,
      deleteProgress: false,
      param: undefined,
      rules: {
        required_rule: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
        phone_number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => /^[0-9+]+$/.test(value) || this.$t("globals.must_be_numbers"),
          (value) => /^7\d*$/.test(value) || this.$t("globals.must-begin-with-7"),
          (value) => value.length <= 9 || `${this.$t("globals.max_numbers")} 9`,
        ],

        id_card: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => /^[0-9+]+$/.test(value) || this.$t("globals.must_be_numbers"),
          (value) => {
            if(String(value)?.length<=16) return true;
            return `${this.$t("globals.max_numbers")} 16`
          }
        ],
      },
      v$: useValidate(),
      commitfileds: [
        {
          name: null,
          adjective: null,
          entity: null,
          phone_number: null,
          id_card:null,
          note: null,
        },
      ],
      commitlist: [],
      commitlist2: [],

      membercomit: {
        name_ar: undefined,
        name_en: undefined,
        country: undefined,
        id_card:undefined,
        fk_governorate: undefined,
        note: undefined,
        data_entry: undefined,
      },
      doc: [
        {
          name: undefined,
          adject: undefined,
          phonenumber: undefined,
          entity: undefined,
          id_card:undefined,
        },
      ],
      docs: {
        solution: [],
      },

      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,

      txt_search: undefined,
    };
  },
  components: { Datetime },

  computed: {
    ...mapState({
      committee: (state) => state.summer.committee,

      user: (state) => state.User,
    }),

    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  validations() {
    return {
      membercomit: {
        name_ar: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          arabicOnly: helpers.withMessage(this.$t("globals.must_be_letters"), arabicOnly),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        name_en: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          englishOnly: helpers.withMessage(
            this.$t("globals.must_be_letters_en"),
            englishOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        country: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        fk_governorate: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getCommittee();

      if (this.id != undefined) {
        this.is_update_state = true;
        await this.axios
          .get(`${this.base_url}/api/summer/committee-member/${this.id}`)
          .then((response) => {
            this.membercomit = response.data.results;

            for (let i = 0; i < this.commitfileds.length; i++) {
              this.commitfileds[i].name = this.membercomit.name;
              this.commitfileds[i].adjective = this.membercomit.adjective;
              this.commitfileds[i].entity = this.membercomit.entity;
              this.commitfileds[i].phone_number = this.membercomit.phone_number;
              this.commitfileds[i].id_card = this.membercomit.id_card;
              this.commitfileds[i].note = this.membercomit.note;
            }
          });

        this.is_update = true;
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getCommittee: "summer/getCommittee",
    }),
    addDefucltis() {
      this.commitfileds.push({
        difficulty: undefined,
        solution: undefined,
      });
    },
    removeDefcultis() {
      if (this.commitfileds.length >= 2) {
        this.commitfileds.pop();
      }
    },

    resetForm() {
      this.commitlist = [];
      this.$refs.form.reset();
      this.v$.membercomit.$reset();
      this.is_update = false;
      this.updated_id = undefined;
    },
    getStartWeek(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.membercomit.statrt_week = date;
    },
    editItem(data) {
      for (const key in data) this.membercomit[key] = data[key];
      this.is_update = true;
      this.updated_id = data.id;
      let country = this.countries.find((con) => con.name_ar == data.country);
      this.membercomit.country = country.id;
      let governorate = this.governorates.find(
        (gov) => gov.name_ar == data.fk_governorate
      );
      this.membercomit.fk_governorate = governorate.id;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async savesloutionmembercomit() {
      this.commitfileds.forEach((element) => {
        this.commitlist.push(element.name);
      });
      this.commitfileds.forEach((element) => {
        this.commitlist2.push(element.adjective);
      });

      const { valid } = await this.$refs.form.validate();
      if (valid) {
        (this.loading = true), (this.membercomit.data_entry = this.user);

        this.param = {
          fk_commitee: this.membercomit.fk_commitee,
          memberassign: this.commitfileds,
        };

        let result = await this.axios
          .post(this.base_url + "api/summer/committee-member", this.param)
          .then((responce) => {
            (this.loading = false),
              this.$emit("successAlert", this.$t("globals.data_added"));

            this.resetForm();
          })
          .catch((error) => {
            this.loading = false;

            if (error.response.data[0].name) {
              this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });

        setTimeout(() => {}, 2000);
      }
    },
    async updatesloutionmembercomit() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.membercomit.updated_by = this.user;
        for (let i = 0; i < this.commitfileds.length; i++) {
          this.membercomit.name = this.commitfileds[i].name;
          this.membercomit.adjective = this.commitfileds[i].adjective;
          this.membercomit.entity = this.commitfileds[i].entity;
          this.membercomit.phone_number = this.commitfileds[i].phone_number;
          this.membercomit.adjective = this.commitfileds[i].adjective;
          this.membercomit.id_card = this.commitfileds[i].id_card;
        }
        let result = await this.axios
          .put(`${this.base_url}/api/summer/committee-member/${this.membercomit.id}`,this.membercomit)
          .then((responce) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
             setTimeout(() => {
              this.$router.push({ name: "membercommit_list" });
            }, 1500);
          })
          .catch((error) => {
            (this.loading = false),
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.resetForm();
       
      }
    },

    async deletesloutionmembercomit() {
      if (this.delete_id != undefined) {
        this.deleteProgress = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/committee-member/${this.delete_id}`)
          .then((responce) => {
            this.deleteProgress = false;
            this.$emit("successAlert", this.$t("globals.data_deleted"));
          })
          .catch((error) => {
            this.deleteProgress = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.del_dialog = false;
        this.delete_id = undefined;
      }
    },
  },
};
</script>
<style></style>
