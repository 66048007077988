<template>
  <DataTable
    v-if="$checkPermissions('globaltable.view_permitanddocument')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('globaltable.delete_permitanddocument', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      $checkPermissions('globaltable.change_permitanddocument', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      $checkPermissions('globaltable.add_permitanddocument', ['sys_admin', 'gen_admin'])
    "
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextField
                v-model="TypeDirectorateLicensesRecord.name_ar"
                :label="`${$t('summer.open-summer.type-permit-and-document')} *`"
                persistent-hint
                autofocus
                prepend-inner-icon="mdi-tag"
                :rules="rules.name_ar"
              ></VTextField>
            </VCol>
            <VCol cols="12">
              <VTextField
                v-model="TypeDirectorateLicensesRecord.field_order"
                :label="`${$t('summer.open-summer.field-order')} *`"
                no-resize
                type="number"
                hide-details="auto"
                :rules="rules.field_order"
                prepend-inner-icon="mdi-sort"
              ></VTextField>
            </VCol>
            <VCol cols="12">
              <VAutocomplete
                :items="school_type"
                v-model="TypeDirectorateLicensesRecord.school_type"
                class="mt-5"
                item-title="name"
                item-value="id"
                :label="$t('school.school_type')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-school-outline"
              ></VAutocomplete>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="editDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      allowFirstOrdering:false,
      school_type: [],
      addDialog: false,
      typeActivities: [],
      saveDataLoading: false,
      editDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      TypeDirectorateLicensesRecords: [],
      TypeDirectorateLicensesRecord: {},

      rules: {
        name_ar: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        field_order: [
          (value) => {
            if (value === null || value === "") return True;
            return /^\d{1,2}$/.test(value) || "حقل اجباري ويجب أن لا يزيد عن رقمين";
          },
        ],
      },
      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      headers: [
        {
          title: this.$t("summer.open-summer.type-permit-and-document"),
          key: "name_ar",
          sortable: false,
        },
        { title: this.$t("summer.open-summer.field-order"), key: "field_order",sortable:true },
        {
          title: this.$t("school.school_type"),
          key: "school_type_name",
          sortable: false,
        },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.TypeDirectorateLicensesRecords) {
        return JSON.parse(JSON.stringify(this.TypeDirectorateLicensesRecords));
      }
    },
    
  },
  async created() {
    try {
      await this.getSchoolType();
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async getSchoolType() {
      await this.axios(`${this.base_url}choices/school-type`).then((response) => {
        this.school_type = response.data;
      });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.TypeDirectorateLicensesRecord = {};
      this.is_update = false;
      this.addDialog = false;
      this.updated_id = undefined;
    },
    editItem(TypeDirectorateLicensesRecord) {
      this.TypeDirectorateLicensesRecord = Object.assign(
        {},
        TypeDirectorateLicensesRecord
      );
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.TypeDirectorateLicensesRecord.data_entry = this.user;
        let result = await this.axios
          .post(
            this.base_url + "permit-documents-types/",
            this.TypeDirectorateLicensesRecord
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.TypeDirectorateLicensesRecord = {};
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.TypeDirectorateLicensesRecord.updated_by = this.user;
        let result = await this.axios
          .put(
            this.base_url +
              "permit-documents-types/" +
              this.TypeDirectorateLicensesRecord.id+'/',
            this.TypeDirectorateLicensesRecord
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            this.editDataLoading = false;
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(this.base_url + "permit-documents-types/" + delete_id+'/')
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      await this.axios(`${this.base_url}/permit-documents-types/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.TypeDirectorateLicensesRecords = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped></style>
