<template>
  <v-form @submit.prevent>
    <v-card class="pa-2" v-if="!exist">
      <h3 class="text-grey-darken-2 px-2">
        {{ $t("globals.select-criteria") }}
      </h3>
      <v-card-text>
        <v-row class="mt-2">
          <v-col
            cols="6"
            md="3"
            v-if="role==1 || role ==0|| role==9"
          >
            <v-autocomplete
              v-model="fk_governorate"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-city"
              :items="filterGovernorates(1)"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              clearable
              @update:modelValue="
                (fk_directorate = undefined)
              "
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-autocomplete
              v-model="fk_directorate"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-map-outline"
              :items="filterDirectorates(fk_governorate)"
              :no-data-text="$t('globals.governorate-first')"
              :label="$t('globals.directorate')"
              persistent-hint
              density="compact"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" >
            <v-autocomplete
              color="indigo"
              v-model="school_type"
              density="compact"
              :clearable="false"
              item-title="type"
              item-value="id"
              prepend-inner-icon="mdi-calendar-week"
              :items="typeList"
              :label="$t('summer.summer_type')"
            />
          </v-col>
          <v-col cols="6" md="3" >
            <VAutocomplete
              :items="[{id:true,val:$t('summer.funded')},{id:false,val:$t('summer.not-funded')},]"
              v-model="is_funded"
              item-title="val"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('summer.fundation')"
              density="compact"
              prepend-inner-icon="mdi-currency-usd"
            ></VAutocomplete>
          </v-col>
          <v-col class="mt-1" cols="6" md="3">
            <v-btn
              type="submit"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              :loading="dataLoading"
              @click="fetchData()"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
  <v-card id="report" v-if="exist">
    <ReportHeader >
      <template v-slot:header-title>
        <div class="d-flex justify-center py-4">
          <div class="text-h5" style="font-family:'Almarai' !important">
            {{ $t("summer.report.feeding-report") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mx-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
         <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="exportToExcel()"
          >
            <v-icon color="green-darken-2">mdi-file-excel</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("export.excel") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable()"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            class="mx-1"
            @click="exist = false"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("summer.report.feeding-report") }}
            </v-sheet>
          </v-col>
          <v-col cols="auto">
            <v-sheet :class="`chart-label-arrow-${this.$i18n.locale} text-center`">
              <h4></h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet color="black" height="6" width="6" style="border-radius: 50%">
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    
    <v-card-item class="px-5">
          <div class="d-flex justify-space-around my-1">
            <span >{{$t('globals.date')}} : {{results.date}}</span>
            <span >{{$t('summer.school_summer_type')}} : {{typeList?.find(el=>el.id==school_type)?.type??$t('globals.all')}}</span>
            <span v-if="fk_governorate">{{$t('globals.governorate')}} : {{results.governorate}}</span>
            <span v-if="fk_directorate">{{$t('globals.directorate')}} : {{results.directorate}}</span>
          </div>
          <table border="1"  class="report-t" v-if="results.results?.length>0">
            <thead>
              <tr>
                <th rowspan="2">
                  {{$t('globals.#')}}
                </th>
                <th rowspan="2">
                  {{ $t("globals.summer-centre") }}
                </th>
                <th rowspan="2" v-if="fk_governorate==null">
                  {{ $t("globals.governorate") }}
                </th>
                <th rowspan="2" v-if="fk_directorate==null">
                  {{ $t("globals.directorate") }}
                </th>
                <th colspan="3">
                  {{$t('globals.present-students')}}
                </th>
                <th colspan="3">
                  {{$t('globals.present-employees')}}
                </th>
              </tr>
              <tr>
                <th>
                  <small>
                    {{$t('globals.indoor-accomodation')}}                 
                  </small>
                </th>
                <th>
                  <small>
                    {{$t('globals.outdoor-accomodation')}}
                  </small>
                </th>
                <th>
                  <small>
                    {{$t('globals.total')}}
                  </small>
                </th>
                <th>
                  <small>
                    {{$t('globals.indoor-accomodation')}}                 
                  </small>
                </th>
                <th>
                  <small>
                    {{$t('globals.outdoor-accomodation')}}
                  </small>
                </th>
                <th>
                  <small>
                    {{$t('globals.total')}}
                  </small>
                </th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(school,index) in results.results" :key="school">
                <td>
                  {{ index+1}}
                </td>
                <td>{{school.school_name}}</td>
                <td v-if="fk_governorate==null">{{school.governorate}}</td>
                <td v-if="fk_directorate==null">{{school.directorate}}</td>
                <td class="text-center">{{school.students_indoor_housing}}</td>
                <td class="text-center">{{school.students_outdoor_housing}}</td>
                <td class="text-center">{{school.students_total}}</td>
                <td class="text-center">{{school.employees_indoor_housing}}</td>
                <td class="text-center">{{school.employees_outdoor_housing}}</td>
                <td class="text-center">{{school.employee_total}}</td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                  <th  :colspan="colspan" class="">{{ $t("reportpublic.GrandTotal") }}</th>
                  <th>{{results.totals.total_students_indoor_housing}}</th>
                  <th>{{results.totals.total_students_outdoor_housing}}</th>
                  <th>{{results.totals.total_students}}</th>

                  <th>{{results.totals.total_employees_indoor_housing}}</th>
                  <th>{{results.totals.total_employees_outdoor_housing}}</th>
                  <th>{{results.totals.total_employees}}</th>
                </tr>
            </tfoot>
          </table>
          <v-card  v-else  class="pa-2 my-2 text-center" >
            <span >
              {{ $t("globals.not-found-items") }}
            </span>
          </v-card>
      <!-- </v-container> -->
    </v-card-item>
  </v-card>
</template>
<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates()
      await this.getDirectorate()
      if(this.role==2||this.role==8){
        this.fk_governorate = this.user_governorate
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
    
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate"
    }),
    async fetchData() {
      this.dataLoading=true
      await  this.axios
        .get(`${this.base_url}public-reports/daily-reports/feeding-report`, {
          params:{
            governorate: this.fk_governorate,
            directorate: this.fk_directorate,
            school_type: this.school_type,
            is_funded : this.is_funded
          }
        })
        .then((response) => {
          this.results = response.data;
          if(this.fk_directorate)this.colspan=2
          else if(this.fk_governorate)this.colspan=3
          else this.colspan=4

          this.exist=true
          this.dataLoading=false
        }).catch(error=>{
          this.dataLoading=false

        })
    },
    exportToExcel() {
      this.$ExportToExcel(this.headers,this.results.results,this.$t('summer.report.feeding-report'))
    },
    
    async printTable() {
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: [],
      typeList: [
        { id: 0, type: this.$t("globals.all") },
        { id: 1, type: this.$t("reportpublic.Open") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      school_type:0,
      fk_governorate:null,
      fk_directorate:null,
      is_funded:null,
      exist:false,
      dataLoading:false,
      headers: [
        { header: this.$t("globals.summer-centre"), key: "school_name" },
        { header: this.$t("globals.governorate"), key: "governorate" },
        { header: this.$t("globals.directorate"), key: "directorate" },
        { header: `${this.$t("globals.present-students")} ( ${this.$t('globals.indoor-accomodation')} )`, key: "students_indoor_housing" },
        { header: `${this.$t("globals.present-students")} ( ${this.$t('globals.outdoor-accomodation')} )`, key: "students_outdoor_housing" },
        { header: `${this.$t("globals.present-employees")} ( ${this.$t('globals.indoor-accomodation')} )`, key: "employees_indoor_housing" },
        { header: `${this.$t("globals.present-employees")} ( ${this.$t('globals.outdoor-accomodation')} )`, key: "employees_outdoor_housing" },
      ]
    };
  },
  computed: {
    ...mapState({
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user_governorate:(state)=>state.user_governorate,
      role: (state) => state.role,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    
  },
  components: {
    ReportHeader,
  },
};
</script>
<style scoped>
.report-tables{
  border-style: solid;
  border-color: #649add;
  width: 100%;
  border-collapse: collapse;
}
.report-tables th{
  padding-top:5px ;
  padding-bottom:5px ;
}
.report-tables td{
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>