<template>
  <VCard class="justify-center">
    <v-card-text>
      <VForm ref="form">
        <VRow class="mt-4">
          <VCol cols="12" md="8">
            <VRow >
              <VCol  cols="12">
                  <VTextField
                    v-model="parent.name"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="`${$t('school.student_parent_name_ar')} *`"
                    :placeholder="$t('school.student_parent_name_placeholder')"
                    density="compact"
                    clearable
                    :rules="parent_name_rule"
                  >
                  </VTextField>
              </VCol>
            </VRow>
            <VRow >
              <VCol  cols="12" md="4">
                  <VAutocomplete
                    v-model="parent.identity_type"
                    prepend-inner-icon="mdi-card-account-details"
                    item-value="id"
                    item-title="name"
                    :items="identify_type"
                    :label="`${$t('school.parent_id')} *`"
                    persistent-hint
                    density="compact"
                    clearable
                    :rules="parent_identity_type"
                  ></VAutocomplete>
              </VCol>
              <VCol  cols="12" md="4">
                  <VTextField
                    v-model="parent.identity_number"
                    prepend-inner-icon="mdi-numeric"
                    :label="`${$t('school.parent_id_no')} *`"
                    :placeholder="$t('school.parent_id_no_placeholder')"
                    persistent-hint
                    density="compact"
                    clearable
                    type="number"
                    :rules="parent.identity_type?parent_id_no_rule:''"
                  ></VTextField>
              </VCol>
              <VCol  cols="12" md="4">
                  <VTextField
                    v-model="parent.job"
                    prepend-inner-icon="mdi-account-hard-hat"
                    :label="$t('school.parent_job')"
                    :placeholder="$t('school.parent_job_placeholder')"
                    density="compact"
                    clearable
                    :rules="parent_job_rule"
                  ></VTextField>
              </VCol>
            </VRow>
            <VRow >
              <VCol cols="12" md="4">
                  <VTextField
                    v-model="parent.phone_number"
                    prepend-inner-icon="mdi-cellphone-text"
                    :label="$t('school.parent_phone')"
                    :placeholder="$t('globals.phone_placeholder')"
                    density="compact"
                    clearable
                    :rules="parent_phone_rule"
                  ></VTextField>
              </VCol>
              <VCol  cols="12" md="4">
                  <VTextField
                    v-model="parent.home_number"
                    prepend-inner-icon="mdi-phone-classic"
                    :label="$t('school.parent_landline')"
                    :placeholder="$t('school.parent_landline_placeholder')"
                    density="compact"
                    clearable
                    type="number"
                    :rules="parent_home_number_rule"
                  ></VTextField>
              </VCol>
              <VCol  cols="12" sm="6" md="4">
                  <VTextField
                    v-model="parent.address"
                    prepend-inner-icon="mdi-map-marker"
                    :label="$t('school.parent_address')"
                    :placeholder="$t('school.parent_address_placeholder')"
                    density="compact"
                    clearable
                    :rules="address_rule"
                  ></VTextField>
              </VCol>
            </VRow>
            <VRow > </VRow>
            <VRow >
              <VCol cols="12" >
                  <VTextarea
                    v-model="parent.note"
                    prepend-inner-icon="mdi-note"
                    clearable
                    :label="$t('school.notes')"
                    :placeholder="$t('school.notes_placeholder')"
                    :rules="notes_rule"
                    counter
                    no-resize
                    density="compact"
                    rows="2"
                  ></VTextarea>
              </VCol>
            </VRow>
          </VCol>
          <VCol  cols="12" md="3">
            <ImageInput
              :reset="image_reset"
              :image="parent.identity_image"
              :text="$t('school.parent_id_image')"
              @ImageSelected="saveIdImage"
              @resetRequest="imageReset"
            ></ImageInput>
          </VCol>
        </VRow>
        
      </VForm>
    </v-card-text>
    <v-card-actions class="px-3">
      <VBtn
          v-if="!is_update && $checkPermissions('summer_center.add_parentsummer',['sys_admin','summer_admin','user'])"
          variant="elevated"
          size="small"
          elevation="4"
          color="blue-darken-4"
          class="bg-indigo"
          @click.prevent="saveParent()"
          :disabled="is_save"
          :loading="saveDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="is_update && $checkPermissions('summer_center.change_parentsummer',['sys_admin','summer_admin','user'])"
          class="bg-success"
          @click.prevent="updateParent(parent.id)"
          size="small"
          :loading="editDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click.prevent="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
    </v-card-actions>
  </VCard>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  ></Alert>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default {
  name: "ParentView",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getIdentifyType();
      if (this.id) {
        await this.getParent(this.id);
        this.is_update=true
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      // v$: useValidate(),
      image_reset: 0,
      saveDataLoading: false,
      editDataLoading: false,
      is_save: false,
      image: require("@/assets/avatar.jpg"),
      parent: {},
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      data_excist: true,
      updated_id: undefined,

      parent_name_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      parent_identity_type:[
        (value)=> !!value || this.$t("globals.required_field"),
      ],
      parent_id_rule: [(value) => !!value || this.$t("globals.required_field")],
      parent_id_no_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) => /^[0-9]+$/.test(value) || this.$t("globals.must_be_numbers"),
        (value) =>
          (value && value.length <= 13) ||
          `${this.$t("globals.biggest_number_of_character")} 13`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      parent_job_rule: [
        (value) =>!value||(value && value.length <= 100) ||`${this.$t("globals.biggest_number_of_character")} 100`,
      ],
      address_rule: [
        (value) =>!value || (value && value.length <= 150) ||`${this.$t("globals.biggest_number_of_character")} 150`,
      ],
      parent_phone_rule: [
        (value) => !value ||(value&& /^[0-9+]+$/.test(value)) || this.$t("globals.must_be_numbers"),
        (value) => /^7\d*$/.test(value) || this.$t("globals.must-begin-with-7"),
        
        (value) =>
          !value || (value && value.length <= 14) ||
          `${this.$t("globals.biggest_number_of_character")} 14`,
        (value) =>
          !value || (value && value.length >= 9) ||
          `${this.$t("globals.lowest_number_of_character")} 9`,
      ],
      parent_home_number_rule: [
        (value) =>
          value == undefined ||
          value == "" ||
          /^[0-9]+$/.test(value) ||
          this.$t("globals.must_be_numbers"),
        (value) =>
          value == undefined ||
          value == "" ||
          value.length <= 8 ||
          `${this.$t("globals.biggest_number_of_character")} 8`,
        (value) =>
          value == undefined ||
          value == "" ||
          value.length >= 6 ||
          `${this.$t("globals.lowest_number_of_character")} 6`,
      ],
      parent_id_image_rule: [
        // (value) => !!value || this.$t('globals.required_field'),
      ],
      notes_rule: [
        (value) =>
          value == undefined ||
          value.length <= 250 ||
          `${this.$t("globals.biggest_number_of_character")} 250`,
      ],
    };
  },
  components: {
    ImageInput,
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      identify_type: (state) => state.summer_globals.identify_type,
    }),
  },
  methods: {
    imageReset(event) {
      this.image_reset = event;
    },
    getParent(id) {
      this.axios
        .get(`${this.base_url}/api/summer/parent/${id}/`)
        .then((response) => {
          this.parent = response.data;
          this.parent.identity_number = this.parent.identity_number.toString();
          this.parent.identity_image =
            this.parent.identity_image != null &&
            this.parent.identity_image.search(this.base_url) == -1
              ? this.base_url + this.parent.identity_image
              : this.parent.identity_image;
        });
    },
    ...mapActions({
      getIdentifyType: "summer_globals/getIdentifyType",
    }),
    resetForm() {
      this.$refs.form.reset();
      // this.parent.identity_image = undefined;
      this.parent = {};
      this.is_save = false;
      this.image_reset++;
    },
    saveIdImage(event) {
      this.parent.identity_image = event;
    },

    async saveParent() {
      const { valid } = await this.$refs.form.validate();
      this.parent.data_entry = this.user;
      if (valid) {
        this.saveDataLoading = true;
        this.is_save = true;
        let result = this.axios
          .post(this.base_url + "api/summer/parent/", this.parent, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.saveDataLoading = false;

            // setTimeout(() => {
            //   this.$router.push({name:'parents-list'})
            //   this.resetForm();
            // }, 1000);
          })
          .catch((error) => {
            this.saveDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.is_save = false;
          });
      }
    },
    async updateParent(id) {
      const { valid } = await this.$refs.form.validate();
      this.parent.updated_by = this.user;
      if (valid) {
        this.editDataLoading = true;
        if (this.image_reset) this.parent.imageDelete = true;
        else {
          if (this.parent.imageDelete) delete this.parent.imageDelete;
        }
        if (typeof this.parent.identity_image == "string")
          this.parent.identity_image = undefined;
        this.axios
          .put(`${this.base_url}/api/summer/parent/${id}/`, this.parent, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            setTimeout(() => {
              this.$router.push({ name: "parents-list" });
            }, 1500);
          })
          .catch((error) => {
            this.$emit("errorAlert", error);
            this.editDataLoading = false;
          });
      }
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
<style scoped>

img {
  /* max-height: 150px; */
  max-width: 270px;
}
</style>
