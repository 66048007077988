<template>
  <DataTable
    v-if="$checkPermissions('summer_center.view_calendar')"
    :create="
      () => {
        addDialog = true;
      }
    "
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="false"
    :edit_perm="false"
    :add_perm="
      $checkPermissions('summer_center.add_calendar', ['sys_admin', 'gen_admin'])
    "
  >
    <template v-slot:item.is_holiday="{ item }">
      <v-icon v-if="item.raw.is_holiday" color="green-darken-2">mdi-check-circle</v-icon>
      <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <v-alert density="compact" closable type="info" color="indigo"
          >لا يمكن إنشاء أكثر من تقويم دراسي لنفس السنة الدراسية, يمكنك فقط التعديل على
          هذا التقويم بعد حذف البيانات المرتبطة بهذا التقويم (تحضير الطلاب - تحضير
          الموظفين - تسليم المناهج - توصيات الأنشطة - الزيارات المنفذة - درجات الطلاب -
          المهام الادارية المنفذة)
        </v-alert>
        <v-form ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="12">
              <v-text-field
                style="min-width: 220px"
                v-model="calendar.start_date"
                density="compact"
                :placeholder="`${$t('globals.start-date')} *`"
                clearable
                prepend-inner-icon="mdi-calendar-clock-outline"
                id="start-date"
                :rules="start_date_rule"
                readonly
              >
              </v-text-field>
              <Datetime
                type="date"
                element="start-date"
                :color="'#0747a6'"
                @input="(e) => getPermissionTime(e)"
                :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                :placeholder="`${$t('globals.start-date')} *`"
                :calendar="'hijri'"
              ></Datetime>
            </VCol>
            <VCol cols="12">
              <VTextField
                v-model="calendar.week_count"
                prepend-inner-icon="mdi-numeric"
                :label="`${$t('summer.calendar.week-count')} *`"
                density="compact"
                type="number"
                clearable
                :rules="count_rule"
              >
              </VTextField>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
import moment from "moment";
import DataTable from "@/components/Globals/DataTable.vue";
import "moment-hijri";
export default {
  components: { Datetime, DataTable },
  data() {
    return {
      v$: useValidate(),
      calendar: {
        start_date: undefined,
        end_date: undefined,
      },
      addDialog: false,
      loading: false,
      calendars: [],
      is_update: false,
      update_dialog: false,
      saturday_dialog: false,
      start_date_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        // (value)=>this.calendar.end_date==undefined || value <= this.calendar.end_date|| this.$t('errors.end-date'),
        // (value)=>this.calendar.end_date==undefined || value != this.calendar.end_date|| this.$t('errors.start-equal-end-date'),
      ],
      // end_date_rule:[
      //     (value) => !!value || this.$t('globals.required_field'),
      //     (value)=>this.calendar.start_date==undefined || value >= this.calendar.start_date || this.$t('errors.start-date'),
      //     (value)=>this.calendar.start_date==undefined || value != this.calendar.start_date || this.$t('errors.start-equal-end-date'),
      // ],
      count_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) => (!!value && /^\d+$/.test(value)) || this.$t("globals.must_be_numbers"),
        (value) =>
          (!!value && parseInt(value) < 13) || this.$t("globals.max_numbers_value"),
        (value) =>
          (!!value && parseInt(value) > 0) || this.$t("globals.min_numbers_value"),
      ],

      headers: [
        { title: this.$t("day.day"), key: "day" },
        { title: this.$t("summer.calendar.hijri-date"), key: "date" },
        { title: this.$t("summer.calendar.gregorian-date"), key: "date_m" },
        { title: this.$t("day.day_off"), key: "is_holiday" },
        { title: this.$t("globals.note"), key: "note", show: false },
        // { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      supervisor: {},
    };
  },
  async created() {
    console.log("asdfasdf");
    console.log("User from store ",this.user)
    // log(this.$store.state.User,'--------')
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.user = this.$store.getters.getuser;


      await this.$store.commit("updateLoadingValue", false);
    } catch {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      // current_year: (state) => state.current_year,
    }),

    tableList() {
      let list = [];
      this.calendars.forEach((element) => {
        list.push({
          day: element.day,
          date: element.date,
          date_m: element.date_m,
          is_holiday: element.is_holiday,
          note: element.note,
        });
      });
      return list;
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
      // this.v$.calendar.$reset()
      //   this.is_update = false;
      this.calendar.id = undefined;
      this.updated_id = undefined;
      this.calendar.is_holiday = false;
    },

    getPermissionTime(e) {
      let date = e;
      let start_day = moment(e).format("dd");

      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.calendar.start_date = date;
      if (start_day != "ث") this.saturday_dialog = true;
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.calendar.data_entry = this.user;
        // this.calendar.fk_year = this.current_year;
        await this.axios
          .post(this.base_url + "/api/summer/calendar", this.calendar)
          .then((responce) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_added"));
            // this.resetForm()
            this.getItems();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data == "day_count")
              this.$emit("errorAlert", this.$t("summer.calendar.day-init"));
            // else if (error.response.data == "current_year")
            //   this.$emit("errorAlert", this.$t("summer.calendar.year-error"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
      console.log(this.calendar);
    },
    async checkValidation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.update_dialog = true;
      }
    },
    async updateItem() {
      this.update_dialog = false;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.calendar.updated_by = this.user;
        // this.calendar.fk_year = this.current_year;
        await this.axios
          .put(`${this.base_url}/api/summer/calendar/1`, this.calendar)
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            if (error.response.data == "calender_used")
              this.$emit("errorAlert", this.$t("summer.calendar.calendar-update-error"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      await this.axios
        .get(this.base_url + "/api/summer/calendar", {
          params: {
            page: page,
            page_size: per_page,
            sort_by: ordering,
            search: search,
          },
        })
        .then((response) => {
          if (response.data) this.calendars = response.data.results;
          if (this.calendars.length > 0) this.is_update = true;
          this.pagination = response.data.pagination;
        });
    },
  },
  watch: {},
};
</script>
