<template>
  <DataTable
    v-if="$checkPermissions('summer_center.add_schedulesummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_schedulesummer', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_schedulesummer', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      $checkPermissions('summer_center.add_schedulesummer', ['sys_admin', 'gen_admin'])
    "
  >
    <template v-slot:item.period="{ item }">
      {{ item.raw.period ? "الفترة الصباحية" : "الفترة المسائية" }}
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12" md="6">
              <VTextField
                v-model="schedule.quotas_start_time"
                :label="`${$t('school.schedule_start_time')} *`"
                density="compact"
                type="time"
                clearable
                :error-messages="
                  v$.schedule.quotas_start_time.$errors.map((e) => e.$message)
                "
                ref="startWeek"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="6">
              <VTextField
                v-model="schedule.quotas_end_time"
                :label="`${$t('school.schedule_end_time')} *`"
                density="compact"
                type="time"
                clearable
                :error-messages="
                  v$.schedule.quotas_end_time.$errors.map((e) => e.$message)
                "
                ref="endWeek"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="6">
              <VCardItem class="pa-0">
                <VTextField
                  clearable
                  v-model="schedule.quotas"
                  prepend-inner-icon="mdi-format-text"
                  :label="`${$t('school.schedule_quotas')} *`"
                  :placeholder="$t('school.placeholder_schedule_quotas')"
                  density="compact"
                  type="text"
                  :error-messages="v$.schedule.quotas.$errors.map((e) => e.$message)"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol cols="12" md="6">
              <VCardItem class="pa-0">
                <VRadioGroup inline v-model="schedule.period">
                  <VRadio :label="$t('school.morning_period')" :value="true"></VRadio>
                  <VRadio :label="$t('school.evening_period')" :value="false"></VRadio>
                </VRadioGroup>
              </VCardItem>
            </VCol>
            <VCol cols="12">
              <VTextarea
                prepend-inner-icon="mdi-note-outline"
                v-model="schedule.note"
                clearable
                :label="$t('globals.note')"
                counter="250"
                no-resize
                hide-details="auto"
                density="compact"
                rows="2"
                color="indigo"
                :error-messages="v$.schedule.note.$errors.map((e) => e.$message)"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="editDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

import { required, helpers, maxLength } from "@vuelidate/validators";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      saveDataLoading: false,
      editDataLoading: false,
      rules: {
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      schedules: [],
      loading: false,
      del_loading: false,
      v$: useValidate(),
      schedule: {
        quotas: "",
        quotas_start_time: undefined,
        quotas_end_time: undefined,
        period: true,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_update: false,
      del_dialog: false,
      schedule_del_id: undefined,

      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("school.schedule_quotas"), key: "quotas" },
        { title: this.$t("school.schedule_period"), key: "period" },
        {
          title: this.$t("school.schedule_start_time"),
          key: "quotas_start_time",
        },
        { title: this.$t("school.schedule_end_time"), key: "quotas_end_time" },
        { title: this.$t("globals.note"), key: "note", sortable: false, show: false },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ],
      selectedHead: [
        "quotas",
        "period",
        "quotas_start_time",
        "quotas_end_time",
        "note",
        this.checkrole(["sys_admin", "gen_admin"]) ? "actions" : null,
      ],
      txt_search: undefined,
      // start_time_rule: [
      //   // (value)=> value < '08:00' && this.schedule.period == true && this.$t('school.schedule_timing_error4'),
      //   (value) =>
      //     this.schedule.quotas_end_time == undefined ||
      //     value < this.schedule.quotas_end_time ||
      //     this.$t("school.schedule_timing_error"),
      //   (value) => this.checksTime(value) || this.$t("school.schedule_timing_error2"),
      // ],
      // end_time_rule: [
      //   // (value)=> this.schedule.quotas_end_time > '13:30' && this.schedule.period == true &&  this.$t('school.schedule_timing_error3'),
      //   (value) =>
      //     this.schedule.quotas_start_time == undefined ||
      //     value > this.schedule.quotas_start_time ||
      //     this.$t("school.schedule_timing_error"),
      //   (value) => this.checkTime(value) || this.$t("school.schedule_timing_error2"),
      // ],
      quotas: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A A-Za-z]+$/.test(value) ||
          this.$t("globals.must_be_full_letter"),
      ],

      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      sortBy: [
        {
          key: "id",
          order: "asc",
        },
      ],
    };
  },

  validations() {
    return {
      schedule: {
        period: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        quotas: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        quotas_start_time: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          value: helpers.withMessage(this.returnPeriodTitle, (value) => {
            if (value.split(":")[0] <= 11 && this.schedule.period == false) return false;
            if (value.split(":")[0] > 11 && this.schedule.period) return false;
            return true;
          }),
          minValue: helpers.withMessage(
            "وقت البداية يجب يكون اقل من وقت النهاية",
            (value) => {
              if (this.schedule.quotas_end_time !== undefined)
                return value < this.schedule.quotas_end_time;
              return true;
            }
          ),
          interfere: helpers.withMessage(
            this.$t("school.schedule_timing_error2"),
            (value) => {
              let valid = true;
              this.schedules.forEach((element) => {
                if (
                  value > element.quotas_start_time &&
                  value + ":00" < element.quotas_end_time &&
                  element.id != this.schedule.id
                ) {
                  valid = false;
                }
              });
              return valid;
            }
          ),
        },
        quotas_end_time: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          value: helpers.withMessage(this.returnPeriodTitle, (value) => {
            if (value.split(":")[0] <= 11 && this.schedule.period == false) return false;
            if (value.split(":")[0] > 11 && this.schedule.period) return false;
            return true;
          }),
          minValue: helpers.withMessage(
            "وقت النهاية يجب يكون اكبر من وقت البداية",
            (value) => {
              if (this.schedule.quotas_start_time !== undefined)
                return value > this.schedule.quotas_start_time;
              return true;
            }
          ),
          interfere: helpers.withMessage(
            this.$t("school.schedule_timing_error2"),
            (value) => {
              let valid = true;
              this.schedules.forEach((element) => {
                if (
                  value > element.quotas_start_time &&
                  value < element.quotas_end_time &&
                  element.id != this.schedule.id
                )
                  valid = false;
              });
              return valid;
            }
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    // ...mapState({
    //   user: (state) => state.User,
    // }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.schedules) {
        return JSON.parse(JSON.stringify(this.schedules));
      }
    },
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    returnPeriodTitle() {
      return this.schedule.period ? "الفتره صباحية" : "الفتره مسائية";
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      await this.axios
        .get(this.base_url + "api/summer/schedule-summer", {
          params: {
            search: search,
            page: page,
            page_size: per_page,
            sort_by: ordering,
          },
        })
        .then((response) => {
          if (response.data) {
            this.schedules = response.data.results;
            this.pagination = response.data.pagination;
          }
        });
    },
    ...mapActions({}),
    resetForm() {
      this.schedule = { period: true };
      this.v$.schedule.$reset();
      this.addDialog = false;
      this.is_update = false;
    },
    editItem(data) {
      this.schedule = Object.assign({}, data);
      this.addDialog = true;
      this.schedule.data_entry = undefined;
      this.schedule.updated_by = undefined;
      this.is_update = true;
    },
    checkTime(value) {
      let result = true;
      this.schedules.forEach((element) => {
        if (
          element.quotas_start_time <= value &&
          element.quotas_end_time.slice(0, -3) >= value &&
          element.id != this.schedule.id
        )
          result = false;
      });
      return result;
    },
    checksTime(value) {
      let result = true;
      this.schedules.forEach((element) => {
        if (
          element.quotas_start_time < value &&
          element.quotas_end_time.slice(0, -3) > value &&
          element.id != this.schedule.id
        )
          result = false;
      });
      return result;
    },
    async saveItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.v$.schedule.$error) {
        this.saveDataLoading = true;
        this.schedule.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/schedule-summer", this.schedule)
          .then((responce) => {
            this.saveDataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.v$.schedule.$error && this.schedule.id != undefined) {
        this.editDataLoading = true;
        this.schedule.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/api/summer/schedule-summer/${this.schedule.id}`,
            this.schedule
          )
          .then((responce) => {
            this.editDataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.editDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/schedule-summer/${delete_id}`)
          .then((responce) => (status = true))
          .catch((error) => (status = error));
        return status;
      }
    },
  },
  watch: {
    "schedule.quotas_start_time": function () {
      if (this.$refs.endWeek?.value) this.$refs.endWeek.validate();
    },
    "schedule.quotas_end_time": function () {
      this.$refs.startWeek?.validate();
    },
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
