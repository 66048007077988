<template>
  <VCard
    v-if="role == 0 || role == 1"
    class="justify-center"
  >
    <v-card-title class="mx-1">
      <v-locale-provider :locale="$i18n.locale">
        <v-card  elevation="0" class="pb-1 border border-b-lg border-indigo">
          <VTabs
            v-model="tab"
            slider-color="indigo"
            center-active
            show-arrows
            density="compact"
            color="blue"
          >
            <VTab v-for="(value, index) in tabs" :key="index" :value="index" >
              {{ value }}
            </VTab>
          </VTabs>
        </v-card>
      </v-locale-provider>
    </v-card-title>
    <VForm ref="form">
      <v-window v-model="tab" class="my-2">
        <v-window-item :value="0">
          <VRow class="mx-1 mb-1">
            <v-col cols="12">
              <v-card  elevation="0">
                <v-row class="mt-4 mb-2 ">
                  <VCol
                    class="mt-1"
                    cols="6"
                    md="3"
                    sm="4"
                    v-if="role == 0 || role == 1"
                  >
                    <VAutocomplete
                      :items="filterGovernorates(1)"
                      autofocus
                      v-model="fk_governorate"
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('globals.governorate')"
                      persistent-hint
                      density="compact"
                      hide-details
                      clearable
                      :no-data-text="$t('governorate.not-found')"
                      prepend-inner-icon="mdi-city"
                      @update:modelValue="school_statistics.fk_directorate = null"
                    ></VAutocomplete>
                  </VCol>
                  <VCol
                    class="mt-1"
                    cols="6"
                    md="3"
                    sm="4"
                    v-if="role >= 0 && role <= 2"
                  >
                    <VAutocomplete
                      :items="filterDirectorates(fk_governorate)"
                      v-model="school_statistics.fk_directorate"
                      item-title="name_ar"
                      item-value="id"
                      :label="`${$t('globals.directorate')} *`"
                      persistent-hint
                      hide-details="auto"
                      density="compact"
                      clearable
                      :rules="directorate_rule"
                      :no-data-text="$t('directorate.not-found')"
                      prepend-inner-icon="mdi-map-outline"
                    ></VAutocomplete>
                  </VCol>
                </v-row>
              </v-card>
            </v-col>
            <VCol
              cols="12"
              v-if="school_statistics?.permit_documents?.length > 0"
            >
              <v-card flat>
                <v-card-title>
                  {{ $t("summer.records-licenses") }}
                </v-card-title>
                <VRow class="ma-1">
                  <v-col
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="(
                      permit_document, index
                    ) in school_statistics?.permit_documents"
                    :key="permit_document"
                  >
                    <v-text-field
                      @input="
                        (e) => {
                          permit_document.count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      :rules="maxStatisticsValue"
                      :label="`${permit_document?.name_ar} *`"
                      prepend-inner-icon="mdi-file-outline"
                    >
                    </v-text-field>
                  </v-col>
                </VRow>
              </v-card>
            </VCol>
            <VCol
              cols="12"
              class="mt-3"
              v-if="school_statistics?.levels_curriculum?.length > 0"
            >
              <v-card flat>
                <v-card-title>
                  {{ $t("summer.curriculums") }}
                </v-card-title>
                <VRow class="ma-1">
                  <VCol
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="level in school_statistics?.levels_curriculum"
                    :key="level"
                  >
                    <VTextField
                      @input="
                        (e) => {
                          level.book_count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      
                      prepend-inner-icon="mdi-stairs"
                      :label="`${level?.name_ar} *`"
                      density="compact"
                      :rules="maxStatisticsValue"
                    >
                    </VTextField>
                  </VCol>
                </VRow>
              </v-card>
            </VCol>
            <VCol
              cols="12"
              class="mt-3"
              v-if="school_statistics?.sport_supplies?.length > 0"
            >
              <v-card flat>
                <v-card-title>{{
                  $t("summer.sports-supplies")
                }}</v-card-title>
                <VRow class="ma-1">
                  <VCol
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="supply in school_statistics?.sport_supplies"
                    :key="supply"
                  >
                    <VTextField
                      @input="
                        (e) => {
                          supply.count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      
                      :label="`${supply?.name_ar} *`"
                      density="compact"
                      prepend-inner-icon="mdi-soccer-field"
                      :rules="maxStatisticsValue"
                    ></VTextField>
                  </VCol>
                </VRow>
              </v-card>
            </VCol>
            <VCol cols="12" class="mt-3">
              <v-card flat>
                <v-card-title> {{$t('globals.cards')}} </v-card-title>
                <VRow class="ma-1">
                  <VCol
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="(
                      level_phase, index
                    ) in school_statistics?.levels_cards"
                    :key="level_phase"
                  >
                    <VTextField
                      @input="
                        (e) => {
                          level_phase.card_count = parseInt(e.target.value);
                        }
                      "
                      @keydown.tab="
                        index == school_statistics?.levels_cards?.length - 1
                          ? navigateByTabInput()
                          : true
                      "
                      prepend-inner-icon="mdi-card-account-details-outline"
                      type="number"
                      
                      :rules="maxStatisticsValue"
                      :label="`${level_phase.name} *`"
                      density="compact"
                    ></VTextField>
                    <!-- <VTextField
                      v-else
                      @input="(e)=>{level_phase.count=parseInt(e.target.value)}"
                      prepend-inner-icon="mdi-card-account-details-outline"
                      type="number"
                      
                      :rules="maxStatisticsValue"
                      :label="level_phase.name"
                      density="compact"
                    ></VTextField> -->
                  </VCol>
                </VRow>
              </v-card>
            </VCol>
          </VRow>
        </v-window-item>

        <v-window-item :value="1">
          <VRow class="mx-1 my-1">
            <VCol
              cols="12"
              class="mt-1"
              v-if="
                school_statistics?.typically_summer_certificate?.length > 0
              "
            >
              <v-card flat>
                <v-card-title>
                  {{ $t("summer.typical-schools") }}
                </v-card-title>
                <VRow class="ma-1">
                  <v-col
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="(
                      certificate, index
                    ) in school_statistics?.typically_summer_certificate"
                    :key="certificate"
                  >
                    <v-text-field
                      @input="
                        (e) => {
                          certificate.count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      
                      :autofocus="index == 0 ? true : false"
                      :label="`${certificate?.name_ar} *`"
                      :rules="maxStatisticsValue"
                      prepend-inner-icon="mdi-certificate"
                    >
                    </v-text-field>
                  </v-col>
                </VRow>
              </v-card>
            </VCol>
            <VCol
              cols="12"
              class="mt-3"
              v-if="school_statistics?.open_summer_certificates?.length > 0"
            >
              <v-card flat>
                <v-card-title>
                  {{ $t("summer.open-schools") }}
                </v-card-title>
                <VRow class="ma-1">
                  <v-col
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="certificate in school_statistics?.open_summer_certificates"
                    :key="certificate"
                  >
                    <v-text-field
                      @input="
                        (e) => {
                          certificate.count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      
                      :label="`${certificate?.name_ar} *`"
                      prepend-inner-icon="mdi-certificate"
                      :rules="maxStatisticsValue"
                    >
                    </v-text-field>
                  </v-col>
                </VRow>
              </v-card>
            </VCol>
            <VCol
              cols="12"
              class="mt-3"
              v-if="school_statistics?.collaborators?.length > 0"
            >
              <v-card flat>
                <v-card-title>
                  {{ $t("summer.collaborator-certificates") }}
                </v-card-title>
                <VRow class="ma-1">
                  <v-col
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="certificate in school_statistics?.collaborators"
                    :key="certificate"
                  >
                    <v-text-field
                      @input="
                        (e) => {
                          certificate.count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      
                      :label="`${certificate?.name_ar} *`"
                      :rules="maxStatisticsValue"
                      prepend-inner-icon="mdi-certificate"
                    >
                    </v-text-field>
                  </v-col>
                </VRow>
              </v-card>
            </VCol>
            <VCol
              cols="12"
              class="mt-3 mb-2"
              v-if="school_statistics?.gifts?.length > 0"
            >
              <v-card flat>
                <v-card-title>{{ $t("summer.gifts-prizes") }}</v-card-title>
                <VRow class="ma-1">
                  <VCol
                    cols="6"
                    md="3"
                    sm="4"
                    v-for="gift in school_statistics?.gifts"
                    :key="gift"
                  >
                    <VTextField
                      @input="
                        (e) => {
                          gift.count = parseInt(e.target.value);
                        }
                      "
                      type="number"
                      
                      :label="`${gift?.name_ar} *`"
                      prepend-inner-icon="mdi-gift-outline"
                      :rules="maxStatisticsValue"
                    >
                    </VTextField>
                  </VCol>
                </VRow>
              </v-card>
            </VCol>
          </VRow>
        </v-window-item>
      </v-window>
    </VForm>
    <VCardActions class="mx-3" style="min-height:40px">
      <VBtnSave
        :loading="saveDataLoading"
        @click="saveSuppliesGiftsData"
      >
          {{ $t("globals.add") }}
      </VBtnSave>
      <VBtn
        :loading="in_progress"
        v-if="
          is_update && $checkPermissions('summer_center.change_studentsummer')
        "
        class="bg-success"
        @click="updateStudent"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
  maxValue,
  minLength,
  integer,
} from "@vuelidate/validators";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      v$: useValidate(),
      tab: 0,
      school_statistics: {
        fk_directorate:null
      },
      saveDataLoading:false,
      fk_governorate: null,
      maxStatisticsValue: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) => (value&&value>=0) || this.$t("errors.greater-than-zero"),
        (value) =>
          (value && (value?.length <= 9 || value <= 999999999)) ||
          `${this.$t("globals.max_numbers_value")}: 999999999`,
      ],
      directorate_rule:[
        (value) => !!value || this.$t("globals.required_field"),
      ],
    };
  },
  validations() {
    return {};
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      await this.getSuppliesGiftsData();
      await this.getGovernorates();
      await this.getDirectorate();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    
    tabs() {
      return [
        this.$t("summer.supply-dispensing-plan"),
        this.$t("summer.distribute-plan"),
      ];
    },
  },
  methods: {

    resetForm() {
      this.$refs.form.reset();
      // this.school_statistics = "";
      this.is_update = false;
      // this.statistic.is_found = false;
      this.updated_id = undefined;
    },

    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    
    navigateByTabInput() {
      this.tab = Math.min(this.tabs?.length - 1, this.tab + 1);
    },
    async getSuppliesGiftsData() {
      await this.axios
        .get(`${this.base_url}api/summer/supplies-gifts`, {
          params: {
            id: this.id,
          },
        })
        .then((response) => {
          this.school_statistics = response.data;
        });
    },
    async saveSuppliesGiftsData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.school_statistics.data_entry = this.user;
        this.saveDataLoading = true;
        await this.axios
          .post(`${this.base_url}api/summer/supplies-gifts`,this.school_statistics)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading=false;
          }).catch(err=>{
            if(err.response.data.includes('unique')){
              this.$emit("errorAlert",this.$t("alert.failure.unique"));
            }else{
              this.$emit("errorAlert",this.$t("globals.error_in_data"));
            }
            this.saveDataLoading=false;
          })
      }
    },
  },
};
</script>
<style scoped>
.v-field--active *:not(input){
  font-size: 8.5px !important;
}
</style>