<template>
  <v-expansion-panels v-if="role < 4" class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol cols="6" md="3" v-if="role < 2">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="filter.governorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              :label="$t('globals.governorate')"
              density="compact"
              clearable
              prepend-inner-icon="mdi-city"
              @update:modelValue="filter.directorate = null"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" v-if="role <= 2 || role == 9 || role == 8">
            <VAutocomplete
              :no-data-text="$t('directorate.not-found')"

              :items="filterDirectorates(filter.governorate)"
              v-model="filter.directorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('globals.directorate')"
              density="compact"
              prepend-inner-icon="mdi-map-outline"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="2" class="mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              :loading="loading"
              @click="getSchoolSummerOpen((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard>
    <v-card>
      <v-card-text>
        <v-row class="mt-1 mb-2">
          <v-col cols="4">
            <v-text-search
              v-model="txt_search"
              class="search"
              @input="
                excelData?.length == 0 ? getSchoolSummerOpen((page = 1)) : null
              "
            >
            </v-text-search>
          </v-col>
          <v-col cols="8" class="text-end">
            <v-btn-group class="ms-2">
              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props">
                    <v-icon class="me-2">mdi-eye-outline</v-icon>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-checkbox
                      v-for="header in headers"
                      :key="header.key"
                      :label="header.title"
                      :value="header.key"
                      color="indigo"
                      v-model="selectedHead"
                      density="compact"
                      hide-details
                    >
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-btn @click="exportToExcel" :loading="exportProgress">
                <span>{{ $t("export.export") }} </span>
                <v-icon size="large" color="green-darken-2" end
                  >mdi-file-excel</v-icon
                >
              </v-btn>
              <VBtn
                v-if="role == 0 "
                @click="handelImportBtn"
                :loading="importProgress"
              >
                <span>{{ $t("import.import") }}</span>
                <VIcon color="green-darken-2" end>mdi-file-excel</VIcon>
              </VBtn>

              <VBtn @click="$router.push({ name: 'summer_school_open' })">
                <span>{{ $t("globals.create") }}</span>
                <VIcon icon="mdi-plus-thick" color="success" end></VIcon>
              </VBtn>
            </v-btn-group>
          </v-col>
        </v-row>

        <input
          v-show="false"
          type="file"
          ref="excelInput"
          @click="resetExcelFileSelected"
          @change="importExcelFile"
          accept=".xlsx,.xls"
        />
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-card
          v-if="excelData?.length == 0"
          :loading="loading"
          :disabled="loading"
        >
          <v-data-table-server
            :headers="selectedHeaders"
            :items="schools_summer"
            fixed-footer
            fixed-header
            :height="schools_summer?.length > 10 ? 500 : null"
            :search="txt_search"
            density="compact"
            class="elevation-1"
            v-model:sort-by="sortBy"
            item-value="name"
            item-key="id"
          >
            <!-- :height="schools_summer?.length > 9 ? 400 : null" -->
            <template v-slot:item="{ item }">
              <tr class="row-hover">
                <td v-for="(val, key) in item.columns" :key="key">
                  <span
                    v-if="key === 'actions'"
                    style="min-width: 100px !important"
                  >
                    <dir :title="$t('globals.review')" class="d-inline">
                      <v-btn
                        variant="text"
                        class="me-1 v-btn-icon"
                        size="large"
                        @click="reviewSchoolSummerOpen(item.raw)"
                      >
                        <v-icon color="grey">mdi-text-box-search</v-icon>
                      </v-btn>
                    </dir>
                    <v-btn
                      variant="text"
                      class="me-1 v-btn-icon"
                      size="large"
                      v-if="
                        $checkPermissions(
                          'opensummercenter.change_schoolsummeropen'
                        )
                      "
                      @click="editSchoolSummerOpen(item.raw)"
                    >
                      <v-icon color="warning">mdi-pencil-outline</v-icon>
                    </v-btn>

                    <v-btn
                      variant="text"
                      size="small"
                      class="v-btn-icon"
                      @click="deleteItem(item.raw)"
                      v-if="
                        $checkPermissions(
                          'opensummercenter.delete_schoolsummeropen'
                        )
                      "
                    >
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </span>
                  <span v-else>
                    {{ val }}
                  </span>
                </td>
              </tr>
            </template>
            <template v-slot:bottom>
              <div class="d-flex" id="pagination-bar">
                <v-pagination
                  v-model="page"
                  :length="pagination?.num_pages"
                  density="compact"
                  show-first-last-page
                >
                </v-pagination>
                <div class="d-flex">
                  <v-select
                    v-model="perPage"
                    class="pa-0"
                    :items="itemsPerPage"
                    item-value="value"
                    item-title="text"
                    density="compact"
                    hide-details
                    variant="text"
                  >
                  </v-select>
                  <span class="mt-2 px-2"
                    >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                    {{ pagination?.count }} ] {{ $t("globals.item") }}</span
                  >
                </div>
              </div>
            </template>
          </v-data-table-server>
        </v-card>
        <div v-else>
          <v-card>
            <v-locale-provider :locale="$i18n.locale">
              <v-data-table
                :items="excelData"
                :headers="importTableHeaders"
                density="compact"
                :search="txt_search"
                fixed-header
                :height="excelData?.length > 9 ? 400 : null"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td v-for="(value, key) in item.columns" :key="key">
                      <v-lazy options="{'threshold':0.2}">
                        {{ value }}
                      </v-lazy>
                    </td>
                  </tr>
                </template>
                <template v-slot:footer.prepend
                  ><span class="me-2">{{
                    $t("globals.per-page")
                  }}</span></template
                >
              </v-data-table>
            </v-locale-provider>
          </v-card>
          <v-btn
            block
            @click="saveImportedOpenSchools"
            :loading="saveImportRecordsProgress"
            class="mt-2"
            color="blue-darken-2"
          >
            <v-icon size="17" class="me-2"
              >mdi-database-arrow-up-outline</v-icon
            >
            <span class="text-white"
              >{{ $t("import.import-records-to-db") }}
              <span class="mx-2">{{ excelData?.length }}</span>
              {{ $t("globals.record") }}</span
            >
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </VCard>
  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">{{
          $t("globals.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <delete-dialog
    v-model="del_dialog"
    :loading="deleteDataLoading"
    @confirm-delete="deleteSchoolSummerOpen()"
  ></delete-dialog>
  <v-dialog v-model="excel_sheet_dialog" max-width="500" max-height="700" persistent >
    <v-card
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="text-main-color"
      prepend-icon=""
    >
      <v-card-title class="py-4 bg-indigo text-h6" ><v-icon class="me-2 " size="20">mdi-file-excel-outline</v-icon>{{$t('globals.select-excel-sheet')}}</v-card-title>
      <v-card-text>
        <p>{{$t('globals.there-are-many-sheets')}}</p>
        <v-radio-group v-model="excel_sheet_id" color="indigo">
          <v-card 
            class=" mt-2 border rounded d-flex align-center px-1"
            v-for="(sheet, index) in excel_sheets"
            :key="index"
            elevation="0"
          >
            <v-radio
              class="py-2 "
              :label="sheet.name"
              :value="sheet.id"
            ></v-radio>
            <span class="text-disabled"> {{$t('globals.record')}} ({{sheet.rowCount}})</span>
          </v-card>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-btn class="bg-indigo" size="small" :disabled="!excel_sheet_id"  @click="(selectExcelSheet())">{{$t('import.import')}}</v-btn>
        <v-btn @click="excel_sheet_dialog=false" size="small">{{$t('globals.cancel')}}</v-btn> 
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Exceljs from "exceljs";

import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
export default {
  data() {
    return {
      selectSheetLoading:false,
      excel_sheets: [],
      excel_sheet_id: null,
      excel_sheet_dialog: false,
      filter: {
        governorate: undefined,
        directorate: undefined,
      },
      importProgress: false,
      excelData: [],
      exportProgress: false,
      saveImportRecordsProgress: false,
      schools_summer: [],
      protected_records: false,
      data_message: [],
      deleteDataLoading: false,
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      alert: true,
      loading: false,
      v$: useValidate(),
      country: {},
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      importTableHeaders: [
        { title: this.$t("globals.#"), key: "index" },
        { title: this.$t("summer.summer-camp"), key: "name_ar" },
        { title: this.$t("summer.serial-number"), key: "serial_number" },
        { title: this.$t("globals.directorate"), key: "fk_directorate" },
        { title: this.$t("globals.sub-district"), key: "village_name" },
        { title: this.$t("globals.village"), key: "address" },
        { title: this.$t("summer.place-type"), key: "place_type" },
        { title: this.$t("summer.place-name"), key: "place_name" },
        { title: this.$t("school.students_type"), key: "students_type" },
        { title: this.$t("globals.period"), key: "shift_time" },
      ],
      headers: [
        { title: this.$t("summer.summer-camp"), key: "name_ar" },
        { title: this.$t("summer.serial-number"), key: "serial_number" },
        {
          title: this.$t("summer.manager"),
          key: "manager_name",
          sortable: false,
        },
        {
          title: this.$t("globals.phone"),
          key: "manager_phone",
          sortable: false,
        },
        {
          title: this.$t("globals.governorate"),
          key: "governorate_name",
          sortable: false,
        },
        {
          title: this.$t("globals.directorate"),
          key: "directorate_name",
          sortable: false,
        },
        {
          title: this.$t("school.students_type"),
          key: "students_type",
          sortable: false,
        },
        {
          title: this.$t("globals.period"),
          key: "shift_time",
          sortable: false,
        },
        { title: this.$t("summer.place-name"), key: "place_name" },
        { title: this.$t("globals.sub-district"), key: "address2" },
        { title: this.$t("globals.village"), key: "address" },
        {
          title: this.$t("summer.students-total"),
          key: "total_students",
          sortable: false,
        },
        {
          title: this.$t("summer.employees-total"),
          key: "total_employees",
          sortable: false,
        },
        {
          title: this.$t("summer.activities-total"),
          key: "total_activities",
          sortable: false,
        },
        {
          title: this.$t("summer.tasks-total"),
          key: "total_tasks",
          sortable: false,
        },
        {
          title: this.$t("summer.visits-total"),
          key: "total_visits",
          sortable: false,
        },
        {
          title: this.$t("globals.review-data"),
          key: "error_fields_names",
          sortable: false,
        },
        {
          title: this.$t("user.username"),
          key: "added_by",
          sortable: false,
        },
      ],
      selectedHead: [
        "name_ar",
        "serial_number",
        "manager_name",
        "governorate_name",
        "directorate_name",
        "address",
        "address2",
        "actions",
        "added_by",
      ],
      showImg_dialog: false,
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      placeTypesList: (state) => state.summer.placeTypesList,
      evaluations: (state) => state.summer.evaluations,
      studentTypes: (state) => state.summer.studentTypes,
      shiftTimes: (state) => state.summer.shiftTimes,
      shoolTypes: (state) => state.summer.shoolTypes,
      supervisors: (state) => state.summer.supervisors,
      countries: (state) => state.school.countries,
      user: (state) => state.User,
    }),

    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      getGovernorateName: "school/getGovernorateName",
      getDirectorateName: "school/getDirectorateName",
    }),
    fileName(){
      const title = " بيانات الدورات المدرسية المفتوحة"
      var str = ''
      if(this.filter?.governorate){
        str = ` ${this.$t("globals.for-governorate")} ( ${this.getGovernorateName(this.filter?.governorate)}  )`
      }else{
        str = ` ( ${this.$t("globals.for-all-governorates") })`
      }
      if(this.filter?.directorate){
        str+=` ${this.$t("globals.for-directorate") } (   ${this.getDirectorateName(this.filter?.directorate) } )`
      }else{
        str+=` (  ${this.$t("globals.for-all-directorates") } )`
      }
      return `${title} ${str}`

    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    
  },

  async created() {
    
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (
        this.checkrole([
          "sys_admin",
          "gen_admin",
          "dir_admin",
          "gov_admin",
          "summer_admin",
        ])
      )
        this.headers.push({
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        });
      await this.getSchoolSummerOpen();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getPlaceTypesList();
      await this.getEvaluations();
      await this.getStudentTypes();
      await this.getShiftTimes();
      await this.getShoolTypes();
      await this.getSupervisors();
      if ((this.role == 2 || this.role == 3)) {
        this.getGovernorateData();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    getBooleanValue(txt) {
      if (typeof txt === "string") {
        return ["yes", "نعم", "عم", "نع", "1","تم"].includes(txt?.trim().toLowerCase())?1:0;
      }
      return txt==1?1:0;
    },
  
    getIntegerValue(val) {
      if (typeof val == "string") {
        return parseInt(val?.replace(/\D/g,''))||0;
      }
      return val?val:0;
      
    },
    handelImportBtn() {
      this.$refs.excelInput.click();
    },
    async saveImportedOpenSchools() {
      this.saveImportRecordsProgress = true;
      await this.axios
        .post(
          `${this.base_url}/api/summer/import-school-open`,
          this.excelData,
          {
            timeout: 1800000,
          }
        )
        .then((resp) => {
          this.saveImportRecordsProgress = false;
          this.$emit("successAlert", this.$t("alert.success.save"));
          this.getSchoolSummerOpen();
          this.excelData = [];
        })
        .catch((err) => {
          this.saveImportRecordsProgress = false;
          this.$emit("errorAlert", this.$t("alert.failure.save"));
        });
    },
    resetExcelFileSelected() {
      this.$refs.excelInput.value = "";
    },
    async fillDataTable(sheet) {
      try {
          if (sheet) {
            sheet?.eachRow((row, rowIndex) => {
              if (rowIndex > 5) {
                this.excelData.push({
                  index: row.getCell(1).value,
                  fk_directorate: row.getCell(2).value,
                  serial_number: row.getCell(3).value,
                  study_start: row.getCell(4).value,
                  address2: row.getCell(5).value,
                  address: row.getCell(6).value,
                  name_ar: row.getCell(7).value,
                  manager: row.getCell(8).value,
                  phone: row.getCell(9).value,
                  shift_time: row.getCell(10).value,
                  place_type: row.getCell(11).value,
                  place_name: row.getCell(12).value,
                  students_type: row.getCell(13).value,
                  shortage_of_worker: this.getIntegerValue(row.getCell(17).value),
                  class_count: this.getIntegerValue(row.getCell(19).value),
                  yard_is_available: this.getBooleanValue(row.getCell(20).value),
                  lecture_room_is_available: this.getBooleanValue(
                    row.getCell(21).value
                  ),
                  accoustics_is_available: this.getBooleanValue(
                    row.getCell(22).value
                  ),
                  electricity_is_available: this.getBooleanValue(
                    row.getCell(23).value
                  ),
                  activity_tools_is_available: this.getBooleanValue(
                    row.getCell(24).value
                  ),
                  statistics: [
                    {
                      fk_level: sheet.getCell("Y1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(25).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(26).value),
                      male_students_count: this.getIntegerValue(row.getCell(27).value),
                      female_students_count: this.getIntegerValue(row.getCell(28).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(29).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(30).value),
                      received_books: this.getIntegerValue(row.getCell(31).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(32).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(33).value),
                      overbooks: this.getIntegerValue(row.getCell(34).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(35).value),
                    },
                    {
                      fk_level: sheet.getCell("AJ1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(36).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(37).value),
                      male_students_count: this.getIntegerValue(row.getCell(38).value),
                      female_students_count: this.getIntegerValue(row.getCell(39).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(40).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(41).value),
                      received_books: this.getIntegerValue(row.getCell(42).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(43).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(44).value),
                      overbooks: this.getIntegerValue(row.getCell(45).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(46).value),
                    },
                    {
                      fk_level: sheet.getCell("AU1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(47).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(48).value),
                      male_students_count: this.getIntegerValue(row.getCell(49).value),
                      female_students_count: this.getIntegerValue(row.getCell(50).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(51).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(52).value),
                      received_books: this.getIntegerValue(row.getCell(53).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(54).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(55).value),
                      overbooks: this.getIntegerValue(row.getCell(56).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(57).value),
                    },
                    {
                      fk_level: sheet.getCell("BF1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(58).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(59).value),
                      male_students_count: this.getIntegerValue(row.getCell(60).value),
                      female_students_count: this.getIntegerValue(row.getCell(61).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(62).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(63).value),
                      received_books: this.getIntegerValue(row.getCell(64).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(65).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(66).value),
                      overbooks: this.getIntegerValue(row.getCell(67).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(68).value),
                    },
                    {
                      fk_level: sheet.getCell("BQ1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(69).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(70).value),
                      male_students_count: this.getIntegerValue(row.getCell(71).value),
                      female_students_count: this.getIntegerValue(row.getCell(72).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(73).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(74).value),
                      received_books: this.getIntegerValue(row.getCell(75).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(76).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(77).value),
                      overbooks: this.getIntegerValue(row.getCell(78).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(79).value),
                    },
                    {
                      fk_level: sheet.getCell("CB1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(80).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(81).value),
                      male_students_count: this.getIntegerValue(row.getCell(82).value),
                      female_students_count: this.getIntegerValue(row.getCell(83).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(84).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(85).value),
                      received_books: this.getIntegerValue(row.getCell(86).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(87).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(88).value),
                      overbooks: this.getIntegerValue(row.getCell(89).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(90).value),
                    },
                    {
                      fk_level: sheet.getCell("CM1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(91).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(92).value),
                      male_students_count: this.getIntegerValue(row.getCell(93).value),
                      female_students_count: this.getIntegerValue(row.getCell(94).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(95).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(96).value),
                      received_books: this.getIntegerValue(row.getCell(97).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(98).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(99).value),
                      overbooks: this.getIntegerValue(row.getCell(100).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(101).value),
                    },
                    {
                      fk_level: sheet.getCell("CX1").value,
                      pervious_registars_male: this.getIntegerValue(row.getCell(102).value),
                      pervious_registars_female: this.getIntegerValue(row.getCell(103).value),
                      male_students_count: this.getIntegerValue(row.getCell(104).value),
                      female_students_count: this.getIntegerValue(row.getCell(105).value),
                      regular_registars_male: this.getIntegerValue(row.getCell(106).value),
                      regular_registars_female: this.getIntegerValue(row.getCell(107).value),
                      received_books: this.getIntegerValue(row.getCell(108).value),
                      expendable_for_students_books: this.getIntegerValue(row.getCell(109).value),
                      expendable_for_teachers_books: this.getIntegerValue(row.getCell(110).value),
                      overbooks: this.getIntegerValue(row.getCell(111).value),
                      lecturelog_number: this.getIntegerValue(row.getCell(112).value),
                    },
                  ],
                  tasks: [
                    {
                      typetaskmangement: 10,
                      status: this.getBooleanValue(row.getCell(124).value),
                    },
                    {
                      typetaskmangement: 11,
                      status: this.getBooleanValue(row.getCell(125).value),
                    },
                    {
                      typetaskmangement: 1,
                      status: this.getBooleanValue(row.getCell(126).value),
                    },
                    {
                      typetaskmangement: 2,
                      status: this.getBooleanValue(row.getCell(127).value),
                    },
                    {
                      typetaskmangement: 3,
                      status: this.getBooleanValue(row.getCell(128).value),
                    },
                    {
                      typetaskmangement: 4,
                      status: this.getBooleanValue(row.getCell(129).value),
                    },
                    {
                      typetaskmangement: 5,
                      status: this.getBooleanValue(row.getCell(130).value),
                    },
                    {
                      typetaskmangement: 6,
                      status: this.getBooleanValue(row.getCell(131).value),
                    },
                    {
                      typetaskmangement: 7,
                      status: this.getBooleanValue(row.getCell(132).value),
                    },
                    {
                      typetaskmangement: 8,
                      status: this.getBooleanValue(row.getCell(133).value),
                    },
                    {
                      typetaskmangement: 9,
                      status: this.getBooleanValue(row.getCell(134).value),
                    },
                  ],
                  activities: [
                    {
                      fk_activity_summer: 28,
                      activity_count: this.getIntegerValue(row.getCell(135).value),
                      name: "أداء الصلوات جماعة في المسجد",
                    },
                    {
                      fk_activity_summer: 27,
                      activity_count: this.getIntegerValue(row.getCell(136).value),
                      name: "تطبيق برنامج رجال الله جماعيا",
                    },
                    {
                      fk_activity_summer: 26,
                      activity_count: this.getIntegerValue(row.getCell(137).value),
                      name: "تنفيذ حلقات تعليم القرآن الكريم",
                    },
                    {
                      fk_activity_summer: 61,
                      activity_count: this.getIntegerValue(row.getCell(138).value),
                      name: "إقامة الفعاليات الخاصة بالمناسبات",
                    },
                    {
                      fk_activity_summer: 57,
                      activity_count: this.getIntegerValue(row.getCell(139).value),
                      name: "تنفيذ نشاط إحسان جماعيا",
                    },
                    {
                      fk_activity_summer: 53,
                      activity_count: this.getIntegerValue(row.getCell(140).value),
                      name: "تفعيل صندوق الإنفاق",
                    },
                    {
                      fk_activity_summer: 49,
                      activity_count: this.getIntegerValue(row.getCell(141).value),
                      name: "عيادة جريح اومريض أواسير أوصلة رحم",
                    },
                    {
                      fk_activity_summer: 50,
                      activity_count: this.getIntegerValue(row.getCell(142).value),
                      name: "زيارة طلاب مدارس صيفية أخرى",
                    },
                    {
                      fk_activity_summer: 25,
                      activity_count: this.getIntegerValue(row.getCell(143).value),
                      name: "الرسائل والهدايابين طلاب المستوى",
                    },
                    {
                      fk_activity_summer: 69,
                      activity_count: this.getIntegerValue(row.getCell(144).value),
                      name: "طابور الصباح",
                    },
                    {
                      fk_activity_summer: 70,
                      activity_count: this.getIntegerValue(row.getCell(145).value),
                      name: "إذاعات مدرسية",
                    },
                    {
                      fk_activity_summer: 43,
                      activity_count: this.getIntegerValue(row.getCell(146).value),
                      name: "مسابقات القران الكريم",
                    },
                    {
                      fk_activity_summer: 37,
                      activity_count: this.getIntegerValue(row.getCell(147).value),
                      name: "أمسيات",
                    },
                    {
                      fk_activity_summer: 31,
                      activity_count: this.getIntegerValue(row.getCell(148).value),
                      name: "مشاهدة أفلام وثائقية أو علمية",
                    },
                    {
                      fk_activity_summer: 2,
                      activity_count: this.getIntegerValue(row.getCell(149).value),
                      name: "اقامة محاضرات حية",
                    },
                    {
                      fk_activity_summer: 46,
                      activity_count: this.getIntegerValue(row.getCell(150).value),
                      name: "مشاهدة محاضرات مسجلة",
                    },
                    {
                      fk_activity_summer: 54,
                      activity_count: this.getIntegerValue(row.getCell(151).value),
                      name: "القراءة الحرة",
                    },
                    {
                      fk_activity_summer: 44,
                      activity_count: this.getIntegerValue(row.getCell(152).value),
                      name: "زيارة رياض الشهداء",
                    },
                    {
                      fk_activity_summer: 30,
                      activity_count: this.getIntegerValue(row.getCell(153).value),
                      name: "رسم وخط",
                    },
                    {
                      fk_activity_summer: 38,
                      activity_count: this.getIntegerValue(row.getCell(154).value),
                      name: "انشاد وبرع",
                    },
                    {
                      fk_activity_summer: 33,
                      activity_count: this.getIntegerValue(row.getCell(155).value),
                      name: "تمثيل مسرحي",
                    },
                    {
                      fk_activity_summer: 56,
                      activity_count: this.getIntegerValue(row.getCell(156).value),
                      name: "كتابة وتأليف قصص أو شعر",
                    },
                    {
                      fk_activity_summer: 34,
                      activity_count: this.getIntegerValue(row.getCell(157).value),
                      name: "التصنيع الغذائي",
                    },
                    {
                      fk_activity_summer: 55,
                      activity_count: this.getIntegerValue(row.getCell(158).value),
                      name: "اشغال يدوية",
                    },
                    {
                      fk_activity_summer: 48,
                      activity_count: this.getIntegerValue(row.getCell(159).value),
                      name: "نجارة أو ميكانيك",
                    },
                    {
                      fk_activity_summer: 32,
                      activity_count: this.getIntegerValue(row.getCell(160).value),
                      name: "تعليم خطابة",
                    },
                    {
                      fk_activity_summer: 60,
                      activity_count: this.getIntegerValue(row.getCell(161).value),
                      name: "ابتكارات وعمل المجسمات",
                    },
                    {
                      fk_activity_summer: 41,
                      activity_count: this.getIntegerValue(row.getCell(162).value),
                      name: "أنشطة زراعية",
                    },
                    {
                      fk_activity_summer: 63,
                      activity_count: this.getIntegerValue(row.getCell(163).value),
                      name: "أنشطة رياضية",
                    },
                    {
                      fk_activity_summer: 52,
                      activity_count: this.getIntegerValue(row.getCell(164).value),
                      name: "رحلات ترفيهية للحدائق",
                    },
                    {
                      fk_activity_summer: 45,
                      activity_count: this.getIntegerValue(row.getCell(165).value),
                      name: "زيارة المعالم التاريخية",
                    },
                    {
                      fk_activity_summer: 51,
                      activity_count: this.getIntegerValue(row.getCell(166).value),
                      name: "رحلات تخييم في الطبيعة",
                    },
                    {
                      fk_activity_summer: 40,
                      activity_count:this.getBooleanValue(row.getCell(167).value),
                      name: "تم تشكيل مجموعات الأنشطة نعم/لا",
                    },
                    {
                      fk_activity_summer: 1,
                      activity_count:this.getBooleanValue(row.getCell(168).value),
                      name: "مجموعة الانشطة المحددة (نعم/لا)",
                    },
                  ],

                  visits: [
                    {
                      entity: 1,
                      visit_count: row.getCell(169).value,
                    },
                    {
                      entity: 2,
                      visit_count: row.getCell(170).value,
                    },
                    {
                      entity: 3,
                      visit_count: row.getCell(171).value,
                    },
                    {
                      entity: 4,
                      visit_count: row.getCell(172).value,
                    },
                    {
                      entity: 5,
                      visit_count: row.getCell(173).value,
                    },
                    {
                      entity: 6,
                      visit_count: row.getCell(174).value,
                    },
                    {
                      entity: 10,
                      visit_count: row.getCell(175).value,
                    },
                    {
                      entity: 11,
                      visit_count: row.getCell(176).value,
                    },
                    {
                      entity: 9,
                      visit_count: row.getCell(177).value,
                    },
                  ],
                  students_dropout: [
                    {
                      reason: 1,
                      count: row.getCell(178).value,
                    },
                    {
                      reason: 2,
                      count: row.getCell(179).value,
                    },
                    {
                      reason: 3,
                      count: row.getCell(180).value,
                    },
                    {
                      reason: 4,
                      count: row.getCell(181).value,
                    },
                    {
                      reason: 5,
                      count: row.getCell(182).value,
                    },
                    {
                      reason: 6,
                      count: row.getCell(183).value,
                    },
                    {
                      reason: 7,
                      count: row.getCell(184).value,
                    },
                    {
                      reason: 8,
                      count: row.getCell(185).value,
                    },
                  ],
                });
              }
            });
          }
        } catch (error) {
        }
    },
    async selectExcelSheet() {
      this.selectSheetLoading = true
      if(this.excel_sheet_id) {
        const sheet = this.excel_sheets?.find(
          (sheet) => sheet.id == this.excel_sheet_id
        );
        if (sheet) {
          await this.fillDataTable(sheet);
        }
      }
      this.excel_sheet_dialog = false
    },
    async importExcelFile(event) {
      this.excelData = [];
      this.excel_sheet_id = null
      const file = event.target.files[0];
      const workbook = new Exceljs.Workbook();
      if (file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          this.importProgress = true;
          const buffer = reader.result;
          workbook.xlsx.load(buffer).then((WB) => {
            if (WB?.worksheets?.length > 1) {
              this.excel_sheets = WB.worksheets;
              this.excel_sheet_dialog = true;
            } else {
              this.fillDataTable(WB.worksheets[0]);
            }
            this.importProgress = false;
          });
        };
      }
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    editSchoolSummerOpen(item) {
      this.$router.push({
        name: "summer_school_open_edit",
        params: { id: item.id },
      });
    },
    reviewSchoolSummerOpen(item) {
      this.$router.push({
        name: "summer_school_open_review",
        params: { id: item.id, is_review: true },
      });
    },
    async exportToExcel() {
      this.exportProgress = true;
      const response = await this.axios(
        `${this.base_url}/api/summer/school-open`,
        {
          params: {
            txt_search: this.txt_search,
            order_by: this.order_data,
            governorate: this.filter.governorate,
            directorate: this.filter.directorate,
          },
        }
      );
      this.exportProgress = false;
      const headers = this.headers
        .filter((el) => !["actions"].includes(el.key))
        .map((item) => {
          return { header: item.title, key: item.key };
        });
      this.$ExportToExcel(
        headers,
        response.data.results,
        this.fileName
      );
    },
    getGovernorateData() {
      this.axios(`${this.base_url}directorate/data/`).then((response) => {
        this.filter.country = response.data.country;
        this.filter.governorate = response.data.governorate;
      });
    },
    ...mapActions({
      getPlaceTypesList: "summer/getPlaceTypesList",
      getEvaluations: "summer/getEvaluations",
      getStudentTypes: "summer/getStudentTypes",
      getShiftTimes: "summer/getShiftTimes",
      getShoolTypes: "summer/getShoolTypes",
      getSupervisors: "summer/getSupervisors",
      getSupervisors: "summer/getSupervisors",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    getPlaceTypesListName(value) {
      let placeType = this.placeTypesList.find(
        (element) => element.id === value
      );
      return placeType ? placeType.name : "";
    },
    getEvaluationsName(value) {
      let evaluation = this.evaluations.find((element) => element.id === value);
      return evaluation ? evaluation.name : "";
    },
    getStudentTypesName(value) {
      let studentType = this.studentTypes.find(
        (element) => element.id === value
      );
      return studentType ? studentType.name : "";
    },
    getShiftTimesName(value) {
      let shiftTime = this.shiftTimes.find((element) => element.id === value);
      return shiftTime ? shiftTime.name : "";
    },
    getShoolTypesName(value) {
      let shoolType = this.shoolTypes.find((element) => element.id === value);
      return shoolType ? shoolType.name : "";
    },
    getSupervisorsName(value) {
      let supervisors = this.supervisors
        .filter((obj) => value.includes(obj.id))
        .map((obj) => obj.name)
        .join(", ");
      return supervisors ? supervisors : "";
    },
    async getSchoolSummerOpen() {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/school-open`, {
        params: {
          page: this.page,
          page_size: this.perPage,
          txt_search: this.txt_search,
          order_by: this.order_data,
          governorate: this.filter.governorate,
          directorate: this.filter.directorate,
        },
      }).then((response) => {
        this.schools_summer = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
        this.itemsPerPage[4].value = this.pagination?.count;
      });
    },
    editItem(summer) {
      this.$router.push({
        name: "summer-school-edit",
        params: { id: summer.id },
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.updated_id = undefined;
      this.deleteDataLoading = false;
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    showImage(value, key) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_image = value;
      else this.dialog_image = this.base_url + value;
      if (key == "license_image")
        this.dialog_title = this.$t("school.license_image");
      else this.dialog_title = this.$t("summer.summer_logo");
      this.showImg_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async deleteSchoolSummerOpen() {
      if (this.delete_id != undefined) {
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/school-open/${this.delete_id}`)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.delete"));
          });
        this.del_dialog = false;
        this.delete_id = undefined;
        this.getSchoolSummerOpen();
      }
    },
  },
  watch: {
    perPage() {
      this.getSchoolSummerOpen();
    },
    page() {
      this.getSchoolSummerOpen(this.page);
    },
    order_data() {
      this.getSchoolSummerOpen(this.page);
    },
  },
};
</script>
<style scoped>
</style>
