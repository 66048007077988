<template>
    <span class="required">
        {{required ? ' * ':''}}
    </span
    >
</template>
<script >

export default{
    props:{
      
        required:Boolean
    },
   
}
</script>
<style scoped>
.required {
    color: red;
    font-weight: 900;
}

</style>